import "./Header.css";
import { Tab, Nav, Dropdown, Offcanvas } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import UserContext from "../../../Context/UserContext";
import ClientContext from "../../../Context/ClientContext";
import logo from '../../../Images/Logo.png'
import { HiOutlineDocument, HiOutlineHome, HiOutlineTemplate, HiOutlineAdjustments, HiOutlineMail } from "react-icons/hi";
import { FaRegBell, FaCog } from "react-icons/fa";
import { BiMenu } from "react-icons/bi"
import axios from "axios";

export default function Header() {
    const Image_URL = 'https://api.medicionanalytic.com/medicionapi/storage/app/public'
    const API_URL = 'https://api.medicionanalytic.com/api'
    const newLocation = useNavigate()
    const {userData, setUser} = useContext(UserContext)
    const {logData, setLog} = useContext(ClientContext)
    const [tempFlag, setFlag] = useState()
    const [clogo, setCL] = useState({})
    const [side, setSide] = useState(false)
    
    const Exit = e => {
    e.preventDefault();
    setUser({username:"", password:"", permiso:"", token:"",})
    setLog({nombre_cliente:"",id:0})
    window.localStorage.removeItem("sessionKey");
    window.localStorage.removeItem("user");
    window.localStorage.removeItem("client");
    newLocation("/")
    } 

 useEffect(() => {
    setFlag()
    if(userData.permiso === 'admin' && logData.id !== 0 && userData.subscription === "super") {
        axios.get(API_URL + '/cliente', {params : {subscription: "super"}}).then(
            function (response) {                
            setCL(response.data.find(cl => cl.id === logData.id).logo)
        })
        setTimeout(()=>{
            setFlag(logData.id)            
        },1000)
    }
    if(userData.permiso === 'admin' && logData.id !== 0 && userData.subscription !== "super") {
        axios.get(API_URL + '/cliente', {params : {admin_id : userData.id}}).then(
            function (response) {
            setCL(response.data.find(cl => cl.id === logData.id).logo)
        })
        setTimeout(()=>{
            setFlag(logData.id)
        },1000)
    }
    if(userData.permiso !== 'admin' && userData.permiso.length > 3 && logData.id !== 0) {        
        axios.get(API_URL + '/cliente', {params : {client_id : userData.cliente_id}}).then(
            function (response) {                        
                if(response.data.length > 0){
                    setCL(response.data.find(cl => cl.id === userData.cliente_id).logo) //
                }
        })
        setTimeout(()=>{
            setFlag(userData.cliente_id)
        },1000)
    }
 },[logData, userData])

const getEvents = (user) => {
    if(user.servicio_desempeno === "activo") {return "reportes/reporte-desempeno-general"}
    if(user.servicio_encuesta === "activo") {return "reportes/reporte-encuestas"}
    else {return "reportes"}
}

 const handleSide = () => {
    if(logData.id > 0) {
        setSide(!side)
    }
 }

    return(<>    
    <div className="header-banner">
        <img src={logo} className="banner-logo p-2 pb-0 pt-0" alt="" />
        <div style={{width:'100vw'}} className="hidden-sm">
        <Tab.Container onSelect={(k) => newLocation('/app/'+k)} defaultActiveKey="inicio">
            <Nav variant="tabs" className="Tabs">
              <Nav.Item><Nav.Link className="fix" eventKey="inicio"><HiOutlineHome />inicio</Nav.Link></Nav.Item>
              {userData.permiso === 'admin' || userData.permiso === "gerente" ? <Nav.Item><Nav.Link className="fix" disabled={tempFlag ? false:true} eventKey="administracion/nueva-encuesta"><HiOutlineTemplate />Encuestas</Nav.Link></Nav.Item> : null}
              {userData.permiso === 'labor' || userData.permiso === "encuestador" ? null : <Nav.Item><Nav.Link className="fix" disabled={tempFlag ? false:true} eventKey={getEvents(logData)}><HiOutlineDocument />Reportes</Nav.Link></Nav.Item>}
              {userData.permiso === 'admin' || userData.permiso === 'gerente' ? <Nav.Item><Nav.Link className="fix" disabled={tempFlag ? false:true} eventKey="control/data-cruda"><HiOutlineAdjustments />Control de encuestas</Nav.Link></Nav.Item> : null}
              {/*userData.subscription === "super" ? <Nav.Item><Nav.Link className="fix" disabled={tempFlag ? false:true} eventKey="correos/contactos"><HiOutlineMail />Correos</Nav.Link></Nav.Item> : null/*actualmente solo Superadmin puede verlo*/ }
              {userData.permiso === "labor" ? <Nav.Item><Nav.Link className="fix" eventKey="evaluacion"><HiOutlineDocument />Evaluar</Nav.Link></Nav.Item>:null}
            </Nav>
      </Tab.Container>
        </div >
        <div className="user-tags">
            <FaRegBell className="m-1 clickable-header" />
            <Dropdown>
            <Dropdown.Toggle className="dropdown-clickable" >
                <FaCog className="m-1 clickable-header" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Item onClick={Exit} >Salir</Dropdown.Item>
                {userData.permiso === 'admin' || userData.permiso === 'gerente' ? <Dropdown.Item onClick={() => newLocation('/app/user-control')}>Usuarios</Dropdown.Item>: null }
            </Dropdown.Menu>
            </Dropdown>
            <div className="avatar m-1 clickable-header">
                {clogo ? <div className="header-logo" style={{backgroundImage:'url("'+Image_URL+clogo+'")'}} />: <div className="header-logo" style={{backgroundImage:'url("")'}} /> }
            </div>
        </div>
    </div>
    <div className="hidden-lg tap" onClick={handleSide}><BiMenu /></div>
    <Offcanvas show={side} onHide={handleSide} placement="end">
        <Offcanvas.Body>
        <Tab.Container onSelect={(k) => {newLocation('/app/'+k); setLog(logData); handleSide()}}>
            <Nav variant="pills" className="flex-column">
              <Nav.Item><Nav.Link className="fix" eventKey="inicio"><HiOutlineHome />Inicio</Nav.Link></Nav.Item>
              {userData.permiso === 'admin' ? <Nav.Item><Nav.Link className="fix" disabled={tempFlag ? false:true} eventKey="administracion/nueva-encuesta"><HiOutlineTemplate />Encuestas</Nav.Link></Nav.Item> : null}
              {userData.permiso === 'labor' || userData.permiso === "encuestador" ? null : <Nav.Item><Nav.Link className="fix" disabled={tempFlag ? false:true} eventKey={getEvents(logData)}><HiOutlineDocument />Reportes</Nav.Link></Nav.Item>}
              {userData.permiso === 'admin' || userData.permiso === 'gerente' ? <Nav.Item><Nav.Link className="fix" disabled={tempFlag ? false:true} eventKey="control/data-cruda"><HiOutlineAdjustments />Control de encuestas</Nav.Link></Nav.Item> : null}
              {/*userData.subscription === "super" ? <Nav.Item><Nav.Link className="fix" disabled={tempFlag ? false:true} eventKey="correos/contactos"><HiOutlineMail />Correos</Nav.Link></Nav.Item> : null */}
              {userData.permiso === "labor" ? <Nav.Item><Nav.Link className="fix" eventKey="evaluacion"><HiOutlineDocument />Evaluar</Nav.Link></Nav.Item>:null}
            </Nav>
        </Tab.Container>
        </Offcanvas.Body>
      </Offcanvas>
    </>)
}