import { useContext, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Form, Button, Row, Col } from "react-bootstrap";
import ParametrosContext from "../../../Context/ParametrosContext"
import { EChart } from '@kbox-labs/react-echarts'
import ClientContext from '../../../Context/ClientContext'
import { BarLoader } from "react-spinners"
import axios from "axios";
import './Parametrizar.css'

export default function Parametrizar() {
    const API_URL = 'https://api.medicionanalytic.com/api';
    const { logData } = useContext(ClientContext);
    const {paramData, setParams} = useContext(ParametrosContext)
    const [loading, setLoading] = useState(false)
    const [toast, setToast] = useState({ show: false, variant: "", message: "" });
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
      } = useForm({ mode: "onBlur" });
    
    const updateParametros = (data,e) => {
        e.preventDefault()
        let headers = setHeaders()
        let temp = {
            graph:[0,(data.rojo*10), (data.amarillo*10), 1000],
            values: {rojo:data.rojo, amarillo:data.amarillo, verde:data.verde}
        }
        setParams(temp)
        setLoading(true)
        axios.post(API_URL + '/parametro', data, {headers}).then((result) => {
            setLoading(false)
        }).catch((err) => {
            setLoading(false)
            Toaster("danger", "Algo salio mal")
        });
    }

    const setHeaders = () => {
        let token = window.localStorage.getItem("sessionKey");
        const headers = {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
        };
        return headers;
        }

    function Toaster(variant, message) {setToast({ show: true, variant: variant, message: message });}

    return(<>
    <div className="content-container">
        <div className="slight-border p-2">
            <h4>Parametrizar Colores</h4>
            <Form onSubmit={handleSubmit(updateParametros)}>
                <Form.Control type="hidden" value={logData.id} {...register("client_id")} />
                <Row>
                    <Form.Group as={Col} className="mb-2">
                        <Form.Label>Rojo</Form.Label>
                        <Form.Control defaultValue={paramData.values.rojo} className="parametro-R" {...register("rojo")} />
                    </Form.Group>
                    <Form.Group as={Col} className="mb-2">
                        <Form.Label>Amarillo</Form.Label>
                        <Form.Control defaultValue={paramData.values.amarillo} className="parametro-A" {...register("amarillo")} />
                    </Form.Group>
                    <Form.Group as={Col} className="mb-2">
                        <Form.Label>Verde</Form.Label>
                        <Form.Control defaultValue={paramData.values.verde} className="parametro-V" {...register("verde")} />
                    </Form.Group>
                </Row>
                <Row className="mt-3 mb-4">
                    <div className="centered mb-5">
                    <EChart className="Echart" renderer={'svg'} tooltip={{trigger:"item"}}                        
                        series={[
                            {
                              type: 'gauge',
                              startAngle: 180,
                              endAngle: 0,
                              center: ['50%', '70%'],
                              radius: '100%',
                              min: 0,
                              max: 1,
                              splitNumber: 8,
                              axisLine: {
                                lineStyle: {
                                  width: 6,
                                  color: [
                                    [0.35, 'red'],
                                    [0.7, 'yellow'],
                                    [1, 'green'],                                    
                                  ]
                                }
                              },
                              pointer: {
                                icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
                                length: '12%',
                                width: 20,
                                offsetCenter: [0, '-60%'],
                                itemStyle: {
                                  color: 'auto'
                                }
                              },
                              axisTick: {
                                length: 12,
                                lineStyle: {
                                  color: 'auto',
                                  width: 2
                                }
                              },
                              splitLine: {
                                length: 20,
                                lineStyle: {
                                  color: 'auto',
                                  width: 6
                                }
                              },
                              axisLabel: {
                                color: '#464646',
                                fontSize: 14,
                                distance: -60,
                                rotate: 'tangential',
                                formatter: function (value) {
                                  if (value === 0.750) {
                                    return 'Promotor';
                                  } else if (value === 0.500) {
                                    return 'Neutro';
                                  } else if (value === 0.250) {
                                    return 'Detractor';
                                  }
                                  return '';
                                }
                              },
                              title: {
                                offsetCenter: [0, '20%'],
                                fontSize: 15
                              },
                              detail: {
                                fontSize: 20,
                                offsetCenter: [0, '-5%'],
                                valueAnimation: true,                                
                                color: 'inherit'
                              },
                              data: [
                                {
                                  value: 100,
                                  name: 'Grade Rating'
                                }
                              ]
                            }
                          ]}
                          />
                    </div>
                <Row>
                    <h6>*Tenga en cuenta que los limites aqui establecidos son el limite inferior de la medición. </h6>
                    <h6>Por defecto todos los valores inferiores al limite Neutro, seran Detractores.</h6>
                </Row>
                </Row>
                {loading ? <BarLoader className="m-3" speedMultiplier={0.5} color="#4538d1" /> :
                <Button type="submit" className="App-button">
                    Actualizar
                </Button>
                } 
            </Form>
        </div>
    </div>
        </>)
}