import { useState } from "react";
import { Button, Form, Row, Col, Table, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { BiEditAlt } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

export default function AyudasDigitales() {
    const [encuestas, setEncuestas] = useState([])
    const [selectedEncuesta, setSE] = useState({id:1, secciones:[]}) //inicializar en 0
    const [selectedSeccion, setSS] = useState({id:1}) //inicializar en 0
    const [ayudas, setAyudas] = useState([])
    const [subirView, setSubirView] = useState(false)
    const [ayudaView, setAyudaView] = useState(false)
    const newLocation = useNavigate()

    const {
        register,
        handleSubmit,
        reset,
        getValues,
        formState: { errors },
      } = useForm({ mode: "onBlur" });

    const showSubir = () => setSubirView(true)
    const hideSubir = () => setSubirView(false)
    const showAyuda = () => setAyudaView(true)
    const hideAyuda = () => setAyudaView(false)
    
    const handleChange = e => {
        e.preventDefault()
    }
    const handleSeccionChange = e => {
        e.preventDefault()
    }

    const SubirAyuda = (data,e) => {
        e.preventDefault()
    }
    return (<>
    {/*seleccionar encuesta y seccion, mostrar lista de ayudas. subir ayudas y crear nuevas ayudas. */}
    <Modal show={ayudaView} onHide={hideAyuda}>
    <Modal.Header closeButton>
          <Modal.Title>Crear Ayuda Digital</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(SubirAyuda)}>
            <Modal.Body>
                <Form.Group className="mb-2">
                    <Form.Label>Nombre de la encuesta</Form.Label>
                    <Form.Select {...register("encuesta")}>
                        <option>{selectedEncuesta.nombre}</option>
                        {encuestas.map((encuesta) => <option value={encuesta.id}>{encuesta.nombre}</option>)}
                    </Form.Select>
                    <Form.Text className="text-muted"></Form.Text>
                </Form.Group>
                <Form.Group className="mb-2">
                    <Form.Label>Nombre de la seccion</Form.Label>
                    <Form.Select {...register("seccion")}>
                        <option>{selectedSeccion.nombre}</option>
                        {selectedEncuesta.secciones.map((seccion)=><option value={seccion.id}>{seccion.nombre}</option>)}
                    </Form.Select>
                    <Form.Text className="text-muted"></Form.Text>
                </Form.Group>
                <Form.Group className="mb-2">
                    <Form.Label>Nombre</Form.Label>
                    <Form.Control {...register("peso")}/>
                    <Form.Text className="text-muted"></Form.Text>
                </Form.Group>
                <Form.Group className="mb-2">
                    <Form.Label>Descripción</Form.Label>
                    <Form.Control size="sm" as="textarea" rows={3} placeholder="..." {...register("descripcion")}/>
                    <Form.Text className="text-muted"></Form.Text>
                </Form.Group>
                <Form.Group className="mb-2">
                    <Form.Label>Link</Form.Label>
                    <Form.Control {...register("link")} />
                </Form.Group>
                <Form.Group className="mb-2">
                    <Form.Label>Estado</Form.Label>                
                    <Form.Select {...register("estado")}>
                        <option value="">Activo</option>
                        <option value="">Inactivo</option>
                    </Form.Select>
                    <Form.Text className="text-muted"></Form.Text>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
            <Button className="App-button-alt" onClick={hideAyuda}>
                Cerrar
            </Button>
            <Button type="submit" className="App-button" onClick={hideAyuda}>
                Crear
            </Button>
            </Modal.Footer>
        </Form>
    </Modal>
    <Modal show={subirView} onHide={hideSubir}>
    <Modal.Header closeButton>
          <Modal.Title>Crear Ayuda Digital</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(SubirAyuda)}>
            <Modal.Body>
                <Form.Group className="mb-2">
                    <Button className="App-button" >Formato estandar</Button>
                </Form.Group>
                <Form.Group className="mb-2">
                    <Form.Label>Seleccione el archivo</Form.Label>
                    <Form.Control type="file" {...register("file")} />
                </Form.Group>
                <Form.Group className="mb-2">
                    <Form.Text className="text-muted">Verifique que el formato de archivo a subir esté guardado como versión Excel 97-2003 (con extensión .xls) o con formato .csv</Form.Text>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
            <Button className="App-button-alt" onClick={hideSubir}>
                Cerrar
            </Button>
            <Button type="submit" className="App-button" onClick={hideSubir}>
                Crear
            </Button>
            </Modal.Footer>
        </Form>
    </Modal>
        <div className="content-container">
            <Form>
                <Row>
                    <Form.Group as={Col}>
                        <Form.Select className="mb-2" onChange={handleChange}>
                            <option>Seleccionar encuesta...</option>
                            {encuestas.map((encuesta) => <option value={encuesta.id}>{encuesta.nombre}</option>)}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Button className="App-button" onClick={() => newLocation("/app/administracion/encuestas")}>nueva encuesta</Button>
                    </Form.Group>
                </Row>
        {selectedEncuesta ?
            <>
            <Row>
                <Form.Group as={Col}>
                    <Form.Select className="mb-2" onChange={handleSeccionChange}>
                        <option>Seleccionar Sección</option>
                        {selectedEncuesta.secciones.map((seccion) => <option value={seccion.id}>{seccion.nombre}</option>)}
                    </Form.Select>
                </Form.Group>
                <Form.Group as={Col}>
                    <Button className="App-button" onClick={() => newLocation("/app/administracion/secciones")}>nueva seccion</Button>
                </Form.Group> 
            </Row>
            {selectedSeccion ? 
            <div className="content-body-alt">
            <Row>
                <Col>
                    <Button className="m-2 App-button" onClick={showSubir}>subir ayudas</Button>
                    <Button className="App-button" onClick={showAyuda}>nueva ayuda</Button>
                </Col>
            </Row>
            <Table className="App-Table" striped responsive="md" hover size="sm">
                        <thead>
                            <tr>
                                <th></th>
                                <th className="table-th">Seccion</th>
                                <th className="table-th">Nombre de ayuda</th>                        
                                <th className="table-th">Descripcion</th>
                                <th className="table-th">Estado</th>
                                <th className="table-th">Link</th>
                                <th></th>                        
                            </tr>
                        </thead>
                        <tbody>
                                {ayudas.map((ayuda) => 
                                <tr>
                                        <td>{ayuda.id}</td>
                                        <td>{ayuda.seccion}</td>
                                        <td>{ayuda.nombre}</td>
                                        <td>{ayuda.descripcion}</td>
                                        <td>{ayuda.estado}</td>
                                        <td>{ayuda.link}</td>
                                        <td className="clickable-end" onClick={() => {}}><BiEditAlt /></td>
                                    </tr>
                                )}
                        </tbody>
                    </Table>
            </div> :null} 
        </> :null}
        </Form>
    </div>
    </>)
}