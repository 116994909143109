import ClientContext from "../../../Context/ClientContext";
import { useState, useEffect, useContext } from "react";
import { Form, Button, Table, Row, Col, Modal, Toast, ToastContainer, InputGroup } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { BiEditAlt, BiDownload, BiTrash } from "react-icons/bi";
import { BarLoader } from "react-spinners";
import * as FileSaver from 'file-saver';
import axios from "axios";


export default function EstablecerPlan() {
    const API_URL = 'https://api.medicionanalytic.com/api'
    const Image_URL = 'https://api.medicionanalytic.com/medicionapi/storage/app/public'
    const re = /(?:\.([^.]+))?$/;
    const {logData} = useContext(ClientContext)
    const [pa, setPA] = useState([])
    const [toast, setToast] = useState({ show: false, variant: "", message: "" });
    
    const [showNuevo, setSNP] = useState(false)
    const [edit, setEditShow] = useState(false)
    const [viewEliminar, setViewEliminar] = useState(false)
    const [loadBuffer, setBuffer] = useState(false)

    const [periodos, setPeriodos] = useState([])
    const [puntos, setPuntos] = useState([])
    const [secciones, setSecciones] = useState([])
    const [encuestas, setEncuestas] = useState([])

    const {
        register,
        handleSubmit,
        reset,
        getValues,
        formState: { errors, isValid },
      } = useForm({ mode: "onBlur" });

      useEffect(()=>{
        axios.get(API_URL + '/periodo', { params: { client_id:logData.id } }).then(
            function(response) {
                response.data.forEach(p => {
                    p.label = p.nombre_periodo;
                    p.value = p.id;
                })
                setPeriodos(response.data)
            })
        axios.get(API_URL + '/sucursal', { params: { cliente_id:logData.id } }).then(
            (res) => {
                res.data.forEach(p => {
                    p.label = p.nombre_sucursal;
                    p.value = p.id;
                })
                setPuntos(res.data)})
        axios.get(API_URL + '/seccion/report', { params: { client_id:logData.id } }).then(
            function (response) {
                response.data.forEach(p => {
                    p.label = p.nombre_seccion;
                    p.value = p.id;
                })
                setSecciones(response.data)})
        axios.get(API_URL + '/encuesta/all', { params: { client_id:logData.id } }).then(
            function(response) {
            let n = response.data.filter(encuesta => encuesta.punto_encuesta !== null);
            setEncuestas(n)})
    },[])

      useEffect(() => {
        axios.get(API_URL + '/plan', { params: { client_id:logData.id } }).then(
            function(response) {
                response.data.sort(function(a,b){
                    return new Date(a.plan_inicio) - new Date(b.plan_inicio); //ordenar por fecha.
                  });                
                setPA(response.data)
            })
        },[toast])

    const showNP = () => {setSNP(true)}
    const hideNP = () => {setSNP(false);reset()}

    const editClose = () => {setEditShow(false);reset();}
    const editShow = (plan) => {
        reset({
            id: plan.id,
            punto_id: plan.punto_id,
            descripcion: plan.descripcion,
            nombre_plan: plan.nombre_plan,
            plan_inicio:plan.plan_inicio,
            plan_fin:plan.plan_fin,
        })
        setEditShow(true)
    }
    const hideEliminar = () => {setViewEliminar(false);reset();}
    const showEliminar = (plan) => {
        reset({id: plan.id})
        setViewEliminar(true)
    }


    function Toaster(variant, message) {setToast({ show: true, variant: variant, message: message });}

    const setHeaders = () => {
        let token = window.localStorage.getItem("sessionKey");
        const headers = {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
        };
        return headers;
    }

    const CallModal = (data,e) => {
        setBuffer(true)
        e.preventDefault();
        let formData = new FormData();
        formData.append("punto_id", data.punto_id)
        formData.append("nombre_plan",data.nombre_plan);
        formData.append("descripcion", data.descripcion)
        formData.append("plan_inicio",data.plan_inicio);
        formData.append("plan_fin",data.plan_fin);
        formData.append("client_id", data.client_id)
        if(data.ayudas) {formData.append("ayudas", data.ayudas[0])}

        axios.post(API_URL + '/plan', formData).then(function (response) {
            Toaster("success", response.data.message)
            setBuffer(false)
            hideNP()
    })}

    const EditModal = (data,e) => {
        setBuffer(true)
        e.preventDefault();
        let headers = setHeaders()
        let formData = new FormData();
        formData.append("punto_id", data.punto_id)
        if(data.descripcion){formData.append("descripcion", data.descripcion)}
        else {formData.append("descripcion", "")}
        formData.append("nombre_plan",data.nombre_plan);
        formData.append("plan_inicio",data.plan_inicio);
        formData.append("plan_fin",data.plan_fin);
        formData.append("client_id", data.client_id);
        formData.append("id", data.id);
        if(data.ayudas) {formData.append("ayudas", data.ayudas[0])}
        axios.post(API_URL + '/plan/update', formData, {headers}).then(function (response) {
            Toaster("success", response.data.message)
            setBuffer(false)
            editClose()
            }
        )
    }

    const RemovePlans = () => {
        let data = getValues()
        setBuffer(true)
        let headers = setHeaders()
        axios.delete(API_URL + `/plan/${data.id}`, {headers}).then(res => {
            Toaster("success", res.data.message)
            hideEliminar()
            setBuffer(false)
        })
    }

    return (<>
       <ToastContainer style={{position:"fixed",top:"80vh", right:"0vw"}} className="p-3">
        <Toast
          bg={toast.variant}
          onClose={() => setToast({ show: false, variant: "", message: "" })}
          show={toast.show}
          delay={2000}
          autohide>
          <Toast.Header>
            <strong className="me-auto">Medicion</strong>
            <small>just now</small>
          </Toast.Header>
          <Toast.Body className="text-white">{toast.message}</Toast.Body>
        </Toast>
    </ToastContainer>
    <Modal show={viewEliminar} onHide={hideEliminar}>
    <Modal.Header closeButton>
          <Modal.Title>Eliminar Plan</Modal.Title>
        </Modal.Header>
        <Form>
        <Form.Control type="hidden" value={logData.id} {...register("client_id")}/>
            <Modal.Body>
                <h4>Esta seguro ?</h4>
                <h5>esta acción no puede deshacerse</h5>
            </Modal.Body>
            <Modal.Footer>
                <Button className="App-button-alt" onClick={hideEliminar}>Cerrar</Button>
            {!loadBuffer ? <Button type="submit" onClick={() => RemovePlans()} className="App-button" >
                Eliminar
            </Button> : <BarLoader className="m-2" color="#4538D1" width={50} />}
            </Modal.Footer>
        </Form>
    </Modal>
    <Modal show={showNuevo} onHide={hideNP}>
        <Modal.Header closeButton>
          <Modal.Title>Nuevo Plan de Accion</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(CallModal)}>
            <Form.Control type="hidden" value={logData.id} {...register("client_id")}/>
            <Modal.Body className="m-2">
                <Row>
                    <Form.Label>Nombre del plan</Form.Label>
                    <Form.Control {...register("nombre_plan", { required: true})}/>
                </Row>
                <Row>
                    <Form.Label>Descripción</Form.Label>
                    <Form.Control {...register("descripcion")}/>
                </Row>
                <Row>
                    <Form.Group as={Col} className="mb-2">
                        <Form.Label>Fecha de inicio</Form.Label>
                        <Form.Control size="sm" type="date" {...register("plan_inicio", {
                            required: "La fecha de inicio es obligatoria.",
                            validate: {
                            matchesPreviousPassword: (value) => {
                                const fin = new Date(getValues("plan_fin")).getTime()
                                const inicio = new Date(value).getTime();
                                return fin-inicio > 0 || "No puede seleccionar una fecha anterior.";
                            }}})} />
                            {errors.periodo_fin && (<p>{errors.periodo_fin.message}</p>)}                        
                    </Form.Group>
                <Form.Group as={Col} className="mb-2">
                        <Form.Label>Fecha de término</Form.Label>
                        <Form.Control size="sm" type="date" {...register("plan_fin",{
                            required: "La fecha final es obligatoria.",
                            validate: {
                                matchesPreviousPassword: (value) => {
                                    const fin = new Date(value).getTime()
                                    const inicio = new Date (getValues("plan_inicio")).getTime();
                                    return fin-inicio > 0 || "No puede seleccionar una fecha anterior.";
                                }}
                            })} />
                    </Form.Group> 
                </Row>
                {logData.servicio_climaLaboral === "inactivo" ? <Row>
                    <Form.Group>
                        <Form.Label>Sucursal</Form.Label>
                        <Form.Select {...register("punto_id")}>
                            <option value={""}>General</option>
                            {puntos.map(s => { return <option value={s.id}>{s.nombre_sucursal}</option>})}
                        </Form.Select>
                    </Form.Group>
                </Row> : null }
                {logData.servicio_climaLaboral === "activo"? <Row>
                    <Form.Group>
                        <Form.Label>Gerencia</Form.Label>
                        <Form.Select {...register("seccion_id")}>
                            <option value={""}>Sin Seleccionar</option>
                            {puntos.map(s => { return <option value={s.id}>{s.nombre_sucursal}</option>})}
                        </Form.Select>
                    </Form.Group>
                </Row>: null}
                <Row>
                    <Form.Label>Ayuda Digital</Form.Label>
                    <Form.Control type="file" {...register("ayudas")} />
                </Row>
            </Modal.Body>
            <Modal.Footer>
            <Button className="App-button-alt" onClick={hideNP}>
                Cerrar
            </Button>
            {!loadBuffer ? <Button type="submit" className="App-button" disabled={!isValid} >
                Crear
            </Button> : <BarLoader className="m-2" color="#4538D1" width={50} />}
            </Modal.Footer>
        </Form>
      </Modal>
      <Modal show={edit} onHide={editClose}>
        <Modal.Header closeButton>
          <Modal.Title>Plan de Accion</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(EditModal)}>
            <Form.Control type="hidden" value={logData.id} {...register("client_id")}/>
            <Modal.Body className="m-2">
                <Row>
                    <Form.Label>Nombre del plan</Form.Label>
                    <Form.Control {...register("nombre_plan", { required: true})}/>
                </Row>
                <Row>
                    <Form.Label>Descripción</Form.Label>
                    <Form.Control {...register("descripcion")}/>
                </Row>
                <Row>
                    <Form.Group as={Col} className="mb-2">
                        <Form.Label>Fecha de inicio</Form.Label>
                        <Form.Control size="sm" type="date" {...register("plan_inicio", {
                            required: "La fecha de inicio es obligatoria.",
                            validate: {
                            matchesPreviousPassword: (value) => {
                                const fin = new Date(getValues("plan_fin")).getTime()
                                const inicio = new Date(value).getTime();
                                return fin-inicio > 0 || "No puede seleccionar una fecha anterior.";
                            }}})} />
                            {errors.periodo_fin && (<p>{errors.periodo_fin.message}</p>)}                        
                    </Form.Group>
                <Form.Group as={Col} className="mb-2">
                        <Form.Label>Fecha de término</Form.Label>
                        <Form.Control size="sm" type="date" {...register("plan_fin",{
                            required: "La fecha final es obligatoria.",
                            validate: {
                                matchesPreviousPassword: (value) => {
                                    const fin = new Date(value).getTime()
                                    const inicio = new Date (getValues("plan_inicio")).getTime();
                                    return fin-inicio > 0 || "No puede seleccionar una fecha anterior.";
                                }}
                            })} />
                    </Form.Group> 
                </Row>
                <Row>
                    <Form.Group>
                        <Form.Label>Sucursal</Form.Label>
                        <Form.Select {...register("punto_id")}>
                            <option value={""}>General</option>
                            {puntos.map(s => { return <option value={s.id}>{s.nombre_sucursal}</option>})}
                        </Form.Select>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Label>Ayuda Digital</Form.Label>
                    <Form.Control type="file" {...register("ayudas")} />
                </Row>
            </Modal.Body>
            <Modal.Footer>
            <Button className="App-button-alt" onClick={editClose}>
                Cerrar
            </Button>
            {!loadBuffer ? <Button type="submit" className="App-button" disabled={!isValid} >
                Actualizar
            </Button> : <BarLoader className="m-2" color="#4538D1" width={50} />}
            </Modal.Footer>
        </Form>
      </Modal>
    <div className="content-container">
            <Row>
                <Form.Group as={Col}>
                    <Button className="App-button" onClick={() => {showNP()}}>nuevo Plan</Button>
                </Form.Group>
            </Row>
        <div className="content-body-alt mb-4">
            <h3 className="p-2">Planes de accion</h3>
            {pa.length > 0 ? <Table className="App-Table" striped responsive="md" hover size="sm">
                    <thead>
                        <tr>
                            <th className="table-th">Plan de accion</th>
                            <th className="table-th">Sucursal</th>
                            <th className="table-th">Descripción</th>
                            <th className="table-th">Fecha inicio</th>                        
                            <th className="table-th">Fecha final</th>
                            <th className="table-th">Ayudas digitales</th>                
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                            {pa.map((pa) => 
                               <tr>
                                    <td>{pa.nombre_plan}</td>
                                    <td>{pa.punto_id ? puntos.filter(p => p.id === pa.punto_id)[0].nombre_sucursal : "General"}</td>
                                    <td>{pa.descripcion}</td>                                
                                    <td>{pa.plan_inicio}</td>
                                    <td>{pa.plan_fin}</td>
                                    <td className="clickable-table" onClick={() => {FileSaver.saveAs(Image_URL+pa.ayudas, "ayuda."+re.exec(pa.ayudas)[0]);}}><BiDownload /></td>                                    
                                    <td className="clickable-end">
                                        <td className="clickable" onClick={() => {editShow(pa)}} ><BiEditAlt /></td>
                                        <td className="clickable"  onClick={() => showEliminar(pa)} ><BiTrash /></td>
                                    </td>
                                </tr>
                            )}
                    </tbody>
                </Table> :
            <div className="p-2 m-2"><h5>No hay Planes en curso.</h5></div>
                }
        </div>
    </div>
    </>)
}