import {useState, useEffect, useContext} from 'react'
import { Form, Button, Table, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import UserContext from '../../../Context/UserContext';
import axios from 'axios';

export default function Tracking() {
    const {userData} = useContext(UserContext)
    
    useEffect(() => {
        //get Data from Client.
        //filter by date.
    },[])

    return <div className="content-container">
        <div className="Banner-alt mb-3">
            <p> user Log </p>
        </div>
    </div>
}