import { useState, useContext, useEffect } from "react";
import UserContext from "../../../Context/UserContext";
import ParametroContext from "../../../Context/ParametrosContext";
import ClientContext from "../../../Context/ClientContext";
import { Form, Button, Row, Col} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { MultiSelect } from "react-multi-select-component";
import { BiSearchAlt } from 'react-icons/bi'
import { ClipLoader} from "react-spinners";
import { EChart } from '@kbox-labs/react-echarts'
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'
import axios from "axios";
import { BarLoader } from "react-spinners"


export default function TableroPrincipal() {
    const API_URL = 'https://api.medicionanalytic.com/api'        
    const {logData} = useContext(ClientContext)
    const {userData} = useContext(UserContext)
    const {paramData} = useContext(ParametroContext);

    let Zeries = []
    let Puntoz = []   

    const [DataNPS,setDNPS] = useState([])
    const [MensualNPS, setMNPS] = useState([])    
    const [AttrMens, setAtrr] = useState([])
    const [EvoPnts, setEPT] = useState([])
    const [reportes, setRep] = useState([])
    const [enc, setE] = useState()
    
    const [sPeriods, setSP] = useState([])
    const [sPuntos, setSPtos] = useState([])
    const [segmentos, setSegmentos] = useState([])
    const [cities, setCities] = useState([])
    const [joints, setJoints] = useState([])
    
    const [periodos, setPeriodos] = useState([])
    const [encuestas, setEncuestas] = useState([])    
    const [Basencuestas, setBaseEncuestas] = useState([])    
    const [puntos, setPuntos] = useState([])    
    const [Sucursales, setSuc] = useState([]) //filtro de sucursales.
    
    const [preguntas, setPreg] = useState([])
    const [respuestas, setR] = useState([])    
    const [PS, setPS] = useState([])    
    const [Reps, setReps] = useState([])
    const [Ptz, setPoints] = useState([])

    const [loader, setLoad] = useState(false)

    //Graph config      
     const config = {
        rotate: 0,
        align: 'center',
        verticalAlign: 'middle',
        position: 'insideBottom',
        distance: 15,
        onChange: function () {
          const labelOption = {
            rotate: config.rotate,
            align: config.align,
            verticalAlign: config.verticalAlign,
            position: config.position,
            distance: config.distance
          };          
        }
      };
      const labelOption = {
        show: true,
        position: config.position,
        distance: config.distance,
        align: config.align,
        verticalAlign: config.verticalAlign,
        rotate: config.rotate,
        formatter: '{c}',
        fontSize: 16,        
      };
      const labelOption2 = {
        show: true,
        position: config.position,
        distance: config.distance,
        align: config.align,
        verticalAlign: config.verticalAlign,
        rotate: config.rotate,
        formatter: '{c}' +'%',
        fontSize: 16,        
      };

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
      } = useForm({ mode: "onBlur" });      

    useEffect(()=>{         
      axios.get(API_URL + '/joint', { params: { client_id:Number(logData.id) } }).then(res => { setJoints(res.data) })             
      axios.get(API_URL+'/segmento', { params: { client_id:logData.id, tipo:"pregunta" } }).then((response) =>{
        let temp = response.data
        temp.forEach(el => {el.label = el.nombre_segmento; el.value = el.id;})
        setSegmentos(temp)
    })
        axios.get(API_URL+'/respuesta', {params: { client_id:logData.id}}).then(res=>{setR(res.data)})
        axios.get(API_URL + '/periodo', { params: { client_id:logData.id } }).then((response) => {
                response.data.forEach(p => { p.label = p.nombre_periodo; p.value = p.id; })
                response.data.sort(function(a,b){ return new Date(a.periodo_inicio) - new Date(b.periodo_inicio);});
                setPeriodos(response.data)
            })
        axios.get(API_URL + '/ciudad',{ params:{ client_id:logData.id }}).then((response) => { response.data.forEach(p => { p.label = p.nombre_ciudad; p.value = p.id; }); setCities(response.data); });
        axios.get(API_URL + '/sucursal', { params: { cliente_id:logData.id } }).then((res) => {
              res.data.forEach(p => { p.label = p.nombre_sucursal; p.value = p.id; })                
              if(logData.id === 49) {setPuntos(res.data)}
              else {setSuc(res.data)}                
          })

        axios.get(API_URL + '/encuesta/all', { params: { client_id:logData.id } }).then((response) => {
          let n;
          let temp;
          if(typeof response.data === 'object') {temp = Object.keys(response.data).map((key) => response.data[key]);}
          else { temp = response.data }
          if(userData.permiso === "admin") { n = temp.filter(encuesta => encuesta.punto_encuesta !== null && encuesta.tipo_encuesta === "encuesta");}
          else {let vat = temp.filter(el=>{ return userData.encuestas.split(",").indexOf(el.id.toString()) > -1 }); n = vat.filter(encuesta => encuesta.punto_encuesta !== null && encuesta.tipo_encuesta === "encuesta");}
            setBaseEncuestas(n)
        })
        axios.get(API_URL + '/pregunta/all', {params: {client_id: logData.id}}).then((res)=>{setPreg(res.data)})

    },[])

    //format for excel Download
    const fileType = 'aplication/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToExcel = async () => {
        let Document = []
        Document.forEach((d) => {
            delete d.tipo_pregunta;
            delete d.punto_id;
            delete d.periodo_id;
        })
        const ws = XLSX.utils.json_to_sheet(Document) //pass jason data
        const wb ={ Sheets: {'data': ws}, SheetNames:['data'] }
        const excelBuffer = XLSX.write(wb,{ bookType:'xlsx', type: 'array' });
        const data = new Blob([excelBuffer],{ type:fileType });
        FileSaver.saveAs(data, 'Excel Export' + fileExtension)
    }

    function getOccurrence(array, value) {return respuestas.filter((v) => (v.tipo_pregunta === value)).length;}    

    function Tabulada(res){
        let data;         
        let sum = []                
        res.forEach((r)=>{             
          if(r.tipo_pregunta === 'escala-tri' && r.respuesta !== "na") {
            if(r.respuesta === 'malo') {
              data = {
              Respuesta_id: r.id,
              Value :0,
              Reporte_id: r.reporte_id, 
              punto_id: r.punto_id,
              periodo_id: r.periodo_id,
              encuesta_id: r.encuesta_id, 
              Seccion_id: r.seccion_id,
              Segmento: preguntas.find(p => p.id === r.pregunta_id).segmento,
              Pregunta: r.pregunta,
              pregunta_id : r.pregunta_id,
              tipo_pregunta: r.tipo_pregunta, }                
            sum.push(data)                  
            }
            if(r.respuesta === 'regular') {
              data = {
                  Respuesta_id: r.id,
                  Value :50,
                  Reporte_id: r.reporte_id, 
                  punto_id: r.punto_id,
                  periodo_id: r.periodo_id,
                  encuesta_id: r.encuesta_id, 
                  Seccion_id: r.seccion_id,
                  Segmento: preguntas.find(p => p.id === r.pregunta_id).segmento,
                  Pregunta: r.pregunta,
                  pregunta_id : r.pregunta_id,
                  tipo_pregunta: r.tipo_pregunta, }                
                sum.push(data)                  
            }
            if(r.respuesta === 'excelente') {
              data = {
                  Respuesta_id: r.id,
                  Value :100,
                  Reporte_id: r.reporte_id, 
                  punto_id: r.punto_id,
                  periodo_id: r.periodo_id,
                  encuesta_id: r.encuesta_id, 
                  Seccion_id: r.seccion_id,
                  Segmento: preguntas.find(p => p.id === r.pregunta_id).segmento,
                  Pregunta: r.pregunta,
                  pregunta_id : r.pregunta_id,
                  tipo_pregunta: r.tipo_pregunta, }                
                sum.push(data)                  
            }
          }             
          if(r.tipo_pregunta === "nps" || (r.tipo_pregunta === "npsna" && r.respuesta !== "na") ){
            data = {
              Respuesta_id: r.id,
              Value :(r.respuesta*100)/10, 
              Reporte_id: r.reporte_id, 
              punto_id: r.punto_id,
              periodo_id: r.periodo_id,
              encuesta_id: r.encuesta_id, 
              Seccion_id: r.seccion_id,
              Segmento: preguntas.find(p => p.id === r.pregunta_id).segmento,
              Pregunta: r.pregunta,
              pregunta_id : r.pregunta_id,
              tipo_pregunta: r.tipo_pregunta, }                
            sum.push(data)                  
          }
          if(r.tipo_pregunta === "escala" && r.respuesta !== "na") {
            data = {
              Respuesta_id: r.id,
              Value :(r.respuesta*100)/r.valor_max,  
              Reporte_id: r.reporte_id, 
              punto_id: r.punto_id,
              periodo_id: r.periodo_id,
              encuesta_id: r.encuesta_id, 
              Seccion_id: r.seccion_id,
              Segmento: preguntas.find(p => p.id === r.pregunta_id).segmento,
              Pregunta: r.pregunta,
              pregunta_id : r.pregunta_id,
              tipo_pregunta: r.tipo_pregunta, }                
            sum.push(data)                  
          }                  
          if(r.tipo_pregunta === "smiles"){
            data = {            
                Respuesta_id: r.id,
                Value :(r.respuesta*100)/5, 
                Reporte_id: r.reporte_id, 
                punto_id: r.punto_id,
                periodo_id: r.periodo_id,
                encuesta_id: r.encuesta_id, 
                Seccion_id: r.seccion_id,
                Segmento: preguntas.find(p => p.id === r.pregunta_id).segmento,
                Pregunta: r.pregunta,
                pregunta_id : r.pregunta_id,
                tipo_pregunta: r.tipo_pregunta, }                
              sum.push(data)                  
          }
          if(r.tipo_pregunta === "thumbs") {
            if(r.respuesta === "si") {
                data = {
                    Respuesta_id: r.id,
                    Value :100,
                    Reporte_id: r.reporte_id, 
                    punto_id: r.punto_id,
                    periodo_id: r.periodo_id,
                    encuesta_id: r.encuesta_id, 
                    Seccion_id: r.seccion_id,
                    Segmento: preguntas.find(p => p.id === r.pregunta_id) ? preguntas.find(p => p.id === r.pregunta_id).segmento: "",
                    Pregunta: r.pregunta,
                    pregunta_id : r.pregunta_id,
                    tipo_pregunta: r.tipo_pregunta, }                
                  sum.push(data)                  
            }
            else if(r.respuesta === "no"){
                data = {
                    Respuesta_id: r.id,
                    Value :0,
                    Reporte_id: r.reporte_id, 
                    punto_id: r.punto_id,
                    periodo_id: r.periodo_id,
                    encuesta_id: r.encuesta_id, 
                    Seccion_id: r.seccion_id,
                    Segmento: preguntas.find(p => p.id === r.pregunta_id) ? preguntas.find(p => p.id === r.pregunta_id).segmento: "",
                    Pregunta: r.pregunta,
                    pregunta_id : r.pregunta_id,
                    tipo_pregunta: r.tipo_pregunta, }                
                  sum.push(data)                  
            }
            else {
              data = {
                  Respuesta_id: r.id,
                  Value :"na",
                  Reporte_id: r.reporte_id, 
                  punto_id: r.punto_id,
                  periodo_id: r.periodo_id,
                  encuesta_id: r.encuesta_id, 
                  Seccion_id: r.seccion_id,
                  Segmento: preguntas.find(p => p.id === r.pregunta_id) ? preguntas.find(p => p.id === r.pregunta_id).segmento : "",
                  Pregunta: r.pregunta,
                  pregunta_id : r.pregunta_id,
                  tipo_pregunta: r.tipo_pregunta, }                
                sum.push(data)                  
          }
          }
          if(r.tipo_pregunta === "stars"){
            data = {            
                Respuesta_id: r.id,
                Value :(r.respuesta*100)/5, 
                Reporte_id: r.reporte_id, 
                punto_id: r.punto_id,
                periodo_id: r.periodo_id,
                encuesta_id: r.encuesta_id, 
                Seccion_id: r.seccion_id,
                Segmento: preguntas.find(p => p.id === r.pregunta_id).segmento,
                Pregunta: r.pregunta,
                pregunta_id : r.pregunta_id,
                tipo_pregunta: r.tipo_pregunta, }                
              sum.push(data)                  
          }
          if(r.tipo_pregunta === "audit"){
            if(r.respuesta === "si") {
              data = {
                  Respuesta_id: r.id,
                  Value :100,
                  Reporte_id: r.reporte_id, 
                  punto_id: r.punto_id,
                  periodo_id: r.periodo_id,
                  encuesta_id: r.encuesta_id, 
                  Seccion_id: r.seccion_id,
                  Segmento: preguntas.find(p => p.id === r.pregunta_id).segmento,
                  Pregunta: r.pregunta,
                  pregunta_id : r.pregunta_id,
                  tipo_pregunta: r.tipo_pregunta, }                
                sum.push(data)                  
          }
          else if(r.respuesta === "no"){
              data = {
                  Respuesta_id: r.id,
                  Value :0,
                  Reporte_id: r.reporte_id, 
                  punto_id: r.punto_id,
                  periodo_id: r.periodo_id,
                  encuesta_id: r.encuesta_id, 
                  Seccion_id: r.seccion_id,
                  Segmento: preguntas.find(p => p.id === r.pregunta_id).segmento,
                  Pregunta: r.pregunta,
                  pregunta_id : r.pregunta_id,
                  tipo_pregunta: r.tipo_pregunta, }                
                sum.push(data)                  
          }
          }
        })              
        return sum
      }

    function showData(temp) {
        let filtered = Tabulada(temp)
        //en filtered está el texto
        let helper = []                           
        let x = filtered.filter(t => (t.Value >= 90 || t.Value <= 60) && t.tipo_pregunta === "nps")
        let y = filtered.filter(t => (t.Value < 90 && t.Value > 60 ) && t.tipo_pregunta === "nps")
        let totalength
        //calculo de NPS total        
        x.forEach(v => {            
          if(v.tipo_pregunta !== "texto") {            
            const bat = getOccurrence(filtered, v.tipo_pregunta)
            if (bat > 1) {
            const indet = helper.findIndex(o => o.tipo_pregunta === v.tipo_pregunta) // quitar pregunta_id para totales
            if(indet === -1) {
                let temp = filtered.filter((q) => ( q.tipo_pregunta === v.tipo_pregunta))
                var result = {
                    Reporte_id: v.Reporte_id,
                    Nombre_sucursal: Sucursales.find(p => p.id === v.punto_id).nombre_sucursal,
                    encuesta_id: v.encuesta_id,
                    punto_id : v.punto_id,
                    periodo_id: v.periodo_id,
                    pregunta_id : v.pregunta_id,
                    Segmento : v.Segmento,
                    tipo_pregunta : v.tipo_pregunta,
                    Seccion_id : v.Seccion_id,
                    Pregunta : v.Pregunta,
                    Video_id: v.Video_id,
                    Value : (temp.reduce(function (acc, obj) { return acc + obj.Value; }, 0)/temp.length).toFixed(2) }
                helper.push(result)
             }
           }
           else { 
            var result = {
            Reporte_id: v.Reporte_id,
            Nombre_sucursal: Sucursales.find(p => p.id === v.punto_id).nombre_sucursal,
            Encuesta_id: v.Encuesta_id,
            encuesta_id: v.encuesta_id,
            punto_id : v.punto_id,
            periodo_id: v.periodo_id,
            pregunta_id : v.pregunta_id,
            Seccion_id : v.Seccion_id,
            Pregunta : v.Pregunta,
            tipo_pregunta : v.tipo_pregunta,
            Video_id: v.Video_id,
            Value : v.Value }

            helper.push(result)}
        } else {
          helper.push(v)
        }
        
      }
    )        
    let MNPS = []
    let DNPS = []
    let AM = []    
    let PTZ = []
    PS.forEach(Per => {
      totalength = x.filter(p => (p.periodo_id === Per.id)).length + y.filter(p => (p.periodo_id === Per.id)).length
      MNPS.push({
        y: (((x.filter(p => (p.periodo_id === Per.id && p.Value >= 90)).length*100)/totalength) - ((x.filter(p => p.periodo_id === Per.id && p.Value <= 60).length*100)/totalength)).toFixed(0),
        x: Per.nombre_corto
      })
      DNPS.push({
        y:[ "0",
            (x.filter(p => (p.periodo_id === Per.id && p.Value >= 90)).length*100)/totalength,
            (y.filter(p => p.periodo_id === Per.id).length*100)/totalength,
            (x.filter(p => p.periodo_id === Per.id && p.Value <= 60).length*100)/totalength,
          ],
        x:Per.nombre_corto
      })                        
      segmentos.filter(s => s.encuesta_id === enc.id).forEach(s => {        
        let tot = filtered.filter(x => (Number(x.Segmento) === Number(s.id) && x.periodo_id === Per.id && x.tipo_pregunta !== "texto"))        
        let xS = tot.filter(t => (t.Value >= 90)).length                     
        let Val = (xS*100/tot.length).toFixed(0)                
        AM.push({y:Val, Segmento:s.nombre_segmento, segmento_id:s.id, periodo_id: Per.id, x:Per.nombre_corto})
      })
      Ptz.forEach(p => {        
        let tot = filtered.filter(x => (x.punto_id === p.id && x.periodo_id === Per.id && x.tipo_pregunta === "nps"))        
        let xS = tot.filter(t => (t.Value >= 90)).length
        let xs = tot.filter(t => (t.Value <= 60)).length                     
        let Val = ((xS*100/tot.length) - (xs*100/tot.length)).toFixed(0)
        PTZ.push({ y:Val, punto:p.nombr1e_sucursal, punto_id: p.id, periodo_id: Per.id, x:Per.nombre_corto})
      })
    })                  
    setMNPS(MNPS)                                
    setDNPS(DNPS)    
    setAtrr(AM)        
    setEPT(PTZ)  
    shown()    
    }
    
    const shown = () => {setLoad(false)}

    const SetFilters = (data,e) => {
        setLoad(true)
        sPeriods.sort(function(a,b){ return new Date(a.periodo_inicio) - new Date(b.periodo_inicio);});
        e.preventDefault();
        let tab = respuestas      
        if(!enc) {return;}
        let temp = []                                
        temp =  tab.filter(el => {
            return sPuntos.some(p => {                
                return sPeriods.some(f => {
                    return p.id === el.punto_id && f.id === el.periodo_id && el.encuesta_id === enc.id
            })            
        })
    })        
      let reps = reportes.filter(el => {
      return sPuntos.some(p => {                
          return sPeriods.some(f => {
              return p.id === el.sucursal && f.id === el.periodo_id                 
      })            
  })
})      
    setReps(reps.filter(d => respuestas.find(r => r.reporte_id === d.id)))
    setPS(sPeriods);    
    setPoints(sPuntos);
    showData(temp)        
    }    
    
    const handleEncuesta = (e) => {
        e.preventDefault()           
        let enc = encuestas.find(el => el.id === Number(e.target.value))        
        if(enc) {            
            axios.get(API_URL+'/reporte', {params:{encuesta:Number(e.target.value)}}).then(res =>{setRep(res.data)})
            setE(enc)            
        }
        else {
          setJoints([])
        }
    }    
    const getColors = (val) => {
      let x = Number(val)        
      if(x < paramData.values.amarillo) {return "lightcoral"}
      if(x < paramData.values.verde) {return "yellow"}
      if(x >= paramData.values.verde) {return "yellowgreen"}
  }
    if(enc) {      
      Zeries = segmentos.filter(s => {return AttrMens.some(a => {return s.encuesta_id === enc.id && s.id === a.segmento_id && Number(a.y) > 0 })})
      .map(s => {
        return {
        data: AttrMens.filter(a => a.segmento_id === s.id).map(d => {return (d.y)}),      
        type: 'bar',barGap: 0, name: s.nombre_segmento, label: labelOption,                
      }})
      Puntoz = PS.map(p => {
        return {
          data: EvoPnts.filter(a => a.periodo_id === p.id).sort((a,b) => Number(a.y) - Number(b.y)).map(d => {return {value:Number(d.y), itemStyle: { color: getColors(d.y)}}}),      
          type: 'bar',barGap: 0, name: p.nombre_corto, label: labelOption,                                  
        } 
      })
    }
    const handlePerdiod = (e) => {    
      setEncuestas([])    
      if(sPeriods.length > 0) {        
      let temp = joints.filter(j => { return sPeriods.some(p => { return Number(p.id) === Number(j.periodo_id) })})
      let temp2 = Basencuestas.filter(e => { return temp.some(j => {return Number(j.encuesta_id) === Number(e.id) }) })      
      setEncuestas(temp2)
      }
    }  
  const handleCity = (e) => {  
      e.preventDefault()       
      setSPtos([]) 
      let sucursales = puntos.filter(s => {return Number(s.ciudad_id) === Number(e.target.value)})        
      setSuc(sucursales)
  }

return (<>
    { loader ? <div className="loader">
        <ClipLoader className="m-2" color="#4538D1" width={800} />
    </div> : null}
    <div className="content-container">
    <Form onSubmit={handleSubmit(SetFilters)}>
                        <Row className="mb-1">                            
                            <Col>
                                <MultiSelect className="multi-selecto"
                                overrideStrings={{
                                    "allItemsAreSelected": "Todos los Periodos Seleccionados.",
                                    "clearSearch": "Limpiar busqueda",
                                    "clearSelected": "Limpiar Seleccion",
                                    "noOptions": "Sin opciones",
                                    "search": "Buscar",
                                    "selectAll": "Todos",
                                    "selectAllFiltered": "Todos (Filtrado)",
                                    "selectSomeItems": "Seleccionar Periodo",
                                    "create": "Create",}
                                  }
                                 options={enc && joints.length > 0 ? periodos.filter(p => { return joints.some(j => { return Number(p.id) === Number(j)})}) : periodos}
                                 value={sPeriods}
                                 onChange={setSP}
                                 onMenuToggle={handlePerdiod}
                                 labelledBy="Select"
                                />
                            </Col>
                            <Col>
                                <Form.Select onChange={handleEncuesta} className="multi-selecto" >
                                    <option value={null}>Sin Encuesta Seleccionada</option>
                                    {encuestas.length > 0 ? encuestas.map(enc => {return <option value={enc.id}>{enc.nombre_encuesta}</option>}):null}
                                </Form.Select>
                            </Col>                                                    
                            {logData.id === 49 ? <Col>
                              <Form.Select onChange={handleCity} >
                                  <option value={null}>Sin Ciudad Seleccionada</option>
                                  {cities.length > 0 ? cities.map(enc => {return <option value={enc.id}>{enc.nombre_ciudad}</option>}):null}
                              </Form.Select> 
                            </Col>
                            : null}
                            <Col>
                                <MultiSelect className="multi-selecto"
                                 overrideStrings={{
                                    "allItemsAreSelected": "Todos los Puntos Seleccionados.",
                                    "clearSearch": "Limpiar busqueda",
                                    "clearSelected": "Limpiar Seleccion",
                                    "noOptions": "Sin opciones",
                                    "search": "Buscar",
                                    "selectAll": "Todos",
                                    "selectAllFiltered": "Todos (Filtrado)",
                                    "selectSomeItems": "Seleccionar Puntos",
                                    "create": "Create",}
                                  }
                                 options={userData.permiso === "admin" ?
                                 Sucursales   :
                                 Sucursales.filter(el => {return  userData.puntos.split(",").indexOf(el.id.toString()) > -1})}
                                 value={sPuntos}
                                 onChange={setSPtos}
                                 labelledBy="Select"
                                />
                            </Col>
                        </Row>
                        <Row className="m-1 mt-3">                        
                          <Button type="submit" className="App-button m-2 mt-0" disabled = {loader}>
                            {loader ? <BarLoader className="m-2" color="#4538D1" width={100} />:"Aplicar filtros"}<BiSearchAlt/>
                          </Button>
                        </Row>
    </Form>
    {//consulta ?
    <div className="mb-4">
        <Row className="row-excel-button">
            <h3 className="p-2" style={{width:'50vw'}}>Tablero Principal</h3>                        
        </Row>                
        <Row>
            <Col className="collumn-alt bkg-white m-1">
                <h4 className="m-2">Evolucion NPS</h4>                          
                <EChart className="Echart-2" renderer={'canvas'} tooltip={{trigger:"item"}} legend={{bottom:'15%',left:'center'}}                                    
                        xAxis={{
                            type: 'category',
                            data: MensualNPS.map(m => {return m.x})
                          }}
                          yAxis={{type: 'value'}}
                          series={ [
                            {
                              data: MensualNPS.map(m => {return m.y}),
                              type: 'line',
                              smooth: true,
                              label: labelOption2,                                  
                            }
                          ]}/>
            </Col>   
            <Col className="collumn-alt bkg-white m-1">
                <h4 className="m-2">Detalle NPS</h4>                          
                <EChart className="Echart" renderer={'SVG'} tooltip={{trigger:"item"}} legend={{bottom:'0%',left:'center'}}                                    
                            xAxis= {{type: 'category',data: DataNPS.map(d => {return d.x}), axisLabel: { interval: 0} }}
                            yAxis={{type: 'value'}}
                            series={[{
                                data: DataNPS.map(d => {return d.y[0]}),
                                type: 'bar',
                                stack: 'a',                                                               
                              },
                              {
                                data: DataNPS.map(d => {return (d.y[1]).toFixed(0)}),
                                type: 'bar',
                                stack: 'a',                                
                                name: 'promotor',
                                label: labelOption2,                                  
                              },
                              {
                                data: DataNPS.map(d => {return (d.y[2]).toFixed(0)}),
                                type: 'bar',
                                stack: 'a',                                
                                name: 'neutro',
                                label: labelOption2,                                  
                              },
                              {
                                data: DataNPS.map(d => {return (d.y[3]).toFixed(0)}),
                                type: 'bar',
                                stack: 'a',
                                name: 'detractor',                                
                                label: labelOption2,                                  
                              }] 
                  }/>                
            </Col>           
        </Row>
        <Row> 
        <Col className="collumn-alt bkg-white m-1">
                <h4 className="m-2">Nro de encuestas</h4>                          
                <EChart className="Echart" renderer={'canvas'} tooltip={{trigger:"item"}}                            
                            xAxis= {{
                                type: 'category',
                                axisTick: { show: false },
                                data: PS.map(p => p.nombre_corto),
                                axisLabel: { interval: 0 }
                              }}
                            yAxis={{type: 'value'}}
                              series= {[
                                { data: PS.map(p => {return Reps.filter(r => r.periodo_id === p.id).length}),
                                  type: 'bar',                                       
                                  label: labelOption,                                                               
                                }
                              ]}/>
            </Col>  
            <Col className="collumn-alt bkg-white m-1">
                <h4 className="m-2">Detalle de atributos</h4>                          
                <EChart className={segmentos.filter(s => {return AttrMens.some(a => {return s.encuesta_id === enc.id && s.id === a.segmento_id && Number(a.y) > 0 })}).length > 10 ? "Echart-wide":"Echart"} renderer={'canvas'} tooltip={{trigger:"item"}} 
                            legend={{bottom:'0%',left:'center', itemGap: 5}}                            
                            xAxis= {{type: 'category',data: PS.map(d => {return d.nombre_corto}), axisLabel: { interval: 0 } }}
                            yAxis={{type: 'value'}}
                            series= {Zeries}                            
                            />
            </Col>         
        </Row>
        <Row>       
        <Col className="collumn-alt bkg-white m-1">
                <h4 className="m-2">Evolucion de Sucursales</h4>                          
                <EChart className="Echart-wide" renderer={'canvas'} tooltip={{trigger:"item"}} legend={{bottom:'0%',left:'center'}}
                    xAxis= {{type: 'category',data: Ptz.sort((a,b) => Number(a.y) - Number(b.y)).map(d => {return d.nombre_sucursal}), axisLabel: { interval: 0 } }}
                    yAxis={{type: 'value'}}
                    series= {Puntoz}/>
            </Col>       
        </Row>
        </div> /*: null*/}
    </div>
    </>)
}