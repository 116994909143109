import React, { useState } from "react";

const UserContext = React.createContext({});

export function UserContextProvider({ children }) {
  const [userData, setUser] = useState({
    username:"",
    password:"",
    permiso:"",
    token:"",
    cliente_id:"",
  });
  return (
    <UserContext.Provider value={{ userData, setUser }}>
      {children}
    </UserContext.Provider>
  );
}
export default UserContext;
