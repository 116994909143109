import { Row, Col, Nav, Tab, Accordion } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useEffect, useContext } from "react";
import {
  HiOutlineDocument,
  HiOutlineCalendar,
  HiOutlineViewList,
  HiOutlineQuestionMarkCircle,
  HiOutlineTag,
  HiOutlineVideoCamera,
  HiOutlineEye,
  HiOutlineClipboardList,  
  HiOutlineDocumentReport,  
  HiOutlineBookOpen,
  HiOutlineUserGroup,
} from "react-icons/hi";
import { RiHomeLine } from 'react-icons/ri'
import { BsGeoAlt, BsImage, BsPalette, BsController } from 'react-icons/bs'
import ClientContext from "../../../Context/ClientContext";
import UserContext from "../../../Context/UserContext";
import { FaUnlock } from "react-icons/fa";

export default function Sidebar({ bit, mobile }) {
  const newLocation = useNavigate()
  const { logData, setLog } = useContext(ClientContext)
  const { userData, setUser } = useContext(UserContext)

  useEffect(() => {
    let check
    if(userData.permiso === "" && window.localStorage.getItem("client") && window.localStorage.getItem("user")) {
      setUser(JSON.parse(window.localStorage.getItem("user")))
      setLog(JSON.parse(window.localStorage.getItem("client")))                  
    }
    else {
      if (userData.permiso !== 'admin') { check = userData.cliente_id }
      else { check = logData.id }    
      if (!check) {
        newLocation('/app/inicio')
      }
    }
  }, [])

  function Menu() {
    switch (bit.type) {
      case 'administracion':
        return <Tab.Container onSelect={(k) => newLocation('/app/administracion/' + k)} id="left-tabs-example" defaultActiveKey="nueva-encuesta">
          <Row><Col>
            <Nav variant="pills" className="flex-column">
              <Nav.Item><Nav.Link eventKey="nueva-encuesta"><HiOutlineCalendar /> Nueva encuesta </Nav.Link></Nav.Item>
              <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Administracion</Accordion.Header>
                <Accordion.Body>
                    <Nav.Item><Nav.Link eventKey="periodos"><HiOutlineCalendar /> Periodos</Nav.Link></Nav.Item>
                    <Nav.Item><Nav.Link eventKey="encuestas"><HiOutlineDocument /> Encuestas</Nav.Link></Nav.Item>
                    <Nav.Item><Nav.Link eventKey="secciones"><HiOutlineViewList /> Secciones</Nav.Link></Nav.Item>
                    <Nav.Item><Nav.Link eventKey="segmento-pregunta"><HiOutlineTag /> Segmento de pregunta</Nav.Link></Nav.Item>
                    <Nav.Item><Nav.Link eventKey="preguntas"><HiOutlineQuestionMarkCircle /> Preguntas</Nav.Link></Nav.Item>
                    <Nav.Item><Nav.Link eventKey="zonas"><BsImage /> Zonas</Nav.Link></Nav.Item>
                    <Nav.Item><Nav.Link eventKey="ciudades"><BsGeoAlt /> Ciudades</Nav.Link></Nav.Item>
                    <Nav.Item><Nav.Link eventKey="puntos"><RiHomeLine /> Sucursales</Nav.Link></Nav.Item>
                    <Nav.Item><Nav.Link eventKey="segmentacion-puntos"><HiOutlineTag /> Segmentacion de puntos</Nav.Link></Nav.Item>
                    {logData && logData.servicio_mystery === "activo" ? <Nav.Item><Nav.Link eventKey="videos"><HiOutlineVideoCamera /> Videos</Nav.Link></Nav.Item> : null}
                    {logData && logData.servicio_shop === "activo" ? <Nav.Item><Nav.Link eventKey="lista-precio"><HiOutlineQuestionMarkCircle /> Lista de Precios</Nav.Link></Nav.Item> : null}
                </Accordion.Body>
                </Accordion.Item>
              </Accordion>              
              <Nav.Item><Nav.Link eventKey="objeto-estudio"><HiOutlineEye /> Objetivo de estudio</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="metodologia"><HiOutlineClipboardList /> Metodologia</Nav.Link></Nav.Item>
              {/*<Nav.Item><Nav.Link eventKey="reporte-cliente"><HiOutlineDocumentReport /> Hoja de Reporte</Nav.Link></Nav.Item>*/}
              <Nav.Item><Nav.Link eventKey="parametrizar"><BsPalette /> Parametrizar Colores</Nav.Link></Nav.Item>
              {logData && logData.servicio_encuesta === "activo" ? <Nav.Item><Nav.Link eventKey="plan-accion"><BsController /> Plan de Acción</Nav.Link></Nav.Item>:null}
              {logData && logData.servicio_encuesta === "activo" ? <Nav.Item><Nav.Link eventKey="plan-accion/evaluacion"><BsController /> Evaluar Plan de acción</Nav.Link></Nav.Item>:null}
            </Nav>
          </Col></Row>
        </Tab.Container>
      case 'reportes':
        return <Tab.Container onSelect={(k) => newLocation('/app/reportes/' + k)} id="left-tabs-example" defaultActiveKey="reporte-encuestas">
          <Row><Col>         
            <Nav variant="pills" className="flex-column">
            {logData.servicio_encuesta === "activo" ? <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>NPS</Accordion.Header>
                <Accordion.Body>
                    <Nav.Item><Nav.Link eventKey="tablero-principal" ><HiOutlineDocumentReport /> Tablero Principal </Nav.Link></Nav.Item>
                    <Nav.Item><Nav.Link eventKey="gestion-diaria" ><HiOutlineDocumentReport /> Gestion Diaria</Nav.Link></Nav.Item>
                    <Nav.Item><Nav.Link eventKey="gestion-detractor" ><HiOutlineDocumentReport /> Gestionar Detractores </Nav.Link></Nav.Item>
                    <Nav.Item><Nav.Link eventKey="reporte-nps" ><HiOutlineDocumentReport /> Reporte NPS</Nav.Link></Nav.Item>
                   {logData && Number(logData.id) !== 25 ?<Nav.Item className="nowrap"><Nav.Link eventKey="reporte-evaluacion"><HiOutlineDocumentReport /> Reporte por evaluacion </Nav.Link></Nav.Item> : null}
                   {logData && Number(logData.id) !== 25 ? <Nav.Item><Nav.Link eventKey="reporte-encuestas" ><HiOutlineDocumentReport /> Reporte por encuesta</Nav.Link></Nav.Item> : null}
                   {logData && Number(logData.id) !== 25 ? <Nav.Item><Nav.Link eventKey="reporte-secciones"><HiOutlineDocumentReport /> Reporte por secciones</Nav.Link></Nav.Item> : null}
                   <Nav.Item><Nav.Link eventKey="reporte-preguntas"><HiOutlineDocumentReport /> Reporte por Pregunta</Nav.Link></Nav.Item>
                   {logData && Number(logData.id) !== 25 ? <Nav.Item className="nowrap"><Nav.Link eventKey="segmento-preguntas" disabled={userData.subscription === "trial"}><HiOutlineTag /> Segmento pregunta {userData.subscription === "trial" ? <FaUnlock className="m-1" />:null}</Nav.Link></Nav.Item> :null}
                   <Nav.Item className="nowrap"><Nav.Link eventKey="reporte-puntos" disabled={userData.subscription === "trial"}><HiOutlineDocumentReport /> Reporte por Sucursal {userData.subscription === "trial" ? <FaUnlock className="m-1" />:null}</Nav.Link></Nav.Item>
                   {/*<Nav.Item className="nowrap"><Nav.Link eventKey="reporte-preguntas-abiertas"  ><HiOutlineDocumentReport /> Reporte de preguntas abiertas</Nav.Link></Nav.Item> {/* className="disabled-option" eventKey="reporte-preguntas-abiertas"  */}
                   {logData && Number(logData.id) !== 25 ? <Nav.Item className="nowrap"><Nav.Link eventKey="reporte-preguntas-acum" disabled={userData.subscription === "trial"}><HiOutlineDocumentReport /> Reporte por Preguntas Acumulado {userData.subscription === "trial" ? <FaUnlock className="m-1" />:null}</Nav.Link></Nav.Item> : null}
                   {logData && Number(logData.id) !== 25 ? <Nav.Item className="nowrap"><Nav.Link eventKey="reporte-puntos-acum" disabled={userData.subscription === "trial"}><HiOutlineDocumentReport /> Reporte por Sucursal Acumulado {userData.subscription === "trial" ? <FaUnlock className="m-1" />:null}</Nav.Link></Nav.Item> : null}
                   {/*<Nav.Item className="nowrap"><Nav.Link eventKey="segmento-punto" disabled={userData.subscription === "trial"}><HiOutlineTag /> Segmento Sucursal {userData.subscription === "trial" ? <FaUnlock className="m-1" />:null}</Nav.Link></Nav.Item>*/}
                   {logData && Number(logData.id) !== 25 ? <Nav.Item className="nowrap"><Nav.Link eventKey="reporte-encuesta-acum" disabled={userData.subscription === "trial"}><HiOutlineDocumentReport /> Reporte por Encuesta Acumulado {userData.subscription === "trial" ? <FaUnlock className="m-1" />:null}</Nav.Link></Nav.Item> : null}
                   {logData && Number(logData.id) !== 25 ? <Nav.Item className="nowrap"><Nav.Link eventKey="reporte-seccion-acum" disabled={userData.subscription === "trial"}><HiOutlineDocumentReport /> Reporte por Secciones Acumulado {userData.subscription === "trial" ? <FaUnlock className="m-1" />:null}</Nav.Link></Nav.Item>:null}
                </Accordion.Body>
                </Accordion.Item>
              </Accordion>:null}
            {logData.servicio_mystery === "activo" ? <Accordion>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Mystery</Accordion.Header>         
                <Accordion.Body>
                  <Nav.Item><Nav.Link eventKey="listar-videos"><HiOutlineVideoCamera /> Listar videos</Nav.Link></Nav.Item> 
                  <Nav.Item className="nowrap"><Nav.Link eventKey="reporte-evaluacion"><HiOutlineDocumentReport /> Reporte por evaluacion </Nav.Link></Nav.Item>
                  <Nav.Item><Nav.Link eventKey="reporte-preguntas"><HiOutlineDocumentReport /> Reporte por Pregunta</Nav.Link></Nav.Item>
                  <Nav.Item className="nowrap"><Nav.Link eventKey="segmento-preguntas" disabled={userData.subscription === "trial"}><HiOutlineTag /> Segmento pregunta {userData.subscription === "trial" ? <FaUnlock className="m-1" />:null}</Nav.Link></Nav.Item> 
                  {<Nav.Item className="nowrap"><Nav.Link eventKey="reporte-puntos" disabled={userData.subscription === "trial"}><HiOutlineDocumentReport /> Reporte por Sucursal {userData.subscription === "trial" ? <FaUnlock className="m-1" />:null}</Nav.Link></Nav.Item>}
                  {logData && (Number(logData.id) === 59 || Number(logData.id) === 49) ? <Nav.Item><Nav.Link eventKey="reporte-secciones"><HiOutlineDocumentReport /> Reporte por secciones</Nav.Link></Nav.Item> : null}
                  <Nav.Item className="nowrap"><Nav.Link eventKey="reporte-preguntas-acum" disabled={userData.subscription === "trial"}><HiOutlineDocumentReport /> Reporte por Preguntas Acumulado {userData.subscription === "trial" ? <FaUnlock className="m-1" />:null}</Nav.Link></Nav.Item>
                  {logData && Number(logData.id) !== 25 ? <Nav.Item className="nowrap"><Nav.Link eventKey="reporte-puntos-acum" disabled={userData.subscription === "trial"}><HiOutlineDocumentReport /> Reporte por Sucursal Acumulado {userData.subscription === "trial" ? <FaUnlock className="m-1" />:null}</Nav.Link></Nav.Item> : null}
                  {logData && Number(logData.id) !== 25 ? <Nav.Item className="nowrap"><Nav.Link eventKey="reporte-encuesta-acum" disabled={userData.subscription === "trial"}><HiOutlineDocumentReport /> Reporte por Encuesta Acumulado {userData.subscription === "trial" ? <FaUnlock className="m-1" />:null}</Nav.Link></Nav.Item> : null}
                  {logData && Number(logData.id) !== 25 ? <Nav.Item className="nowrap"><Nav.Link eventKey="reporte-seccion-acum" disabled={userData.subscription === "trial"}><HiOutlineDocumentReport /> Reporte por Secciones Acumulado {userData.subscription === "trial" ? <FaUnlock className="m-1" />:null}</Nav.Link></Nav.Item> : null}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>: null}
            {logData.servicio_desempeno === "activo" ? <Accordion>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Desempeño</Accordion.Header>         
                <Accordion.Body>
                  <Nav.Item><Nav.Link eventKey="reporte-desempeno-general"><HiOutlineDocumentReport /> Reporte Resultados generales</Nav.Link></Nav.Item>
                  <Nav.Item><Nav.Link eventKey="reporte-desempeno-participacion"><HiOutlineDocumentReport /> Reporte de Participacion</Nav.Link></Nav.Item>
                  <Nav.Item><Nav.Link eventKey="reporte-desempeno"><HiOutlineDocumentReport /> Reporte de Competencias generales</Nav.Link></Nav.Item>
                  <Nav.Item><Nav.Link eventKey="reporte-desempeno-rol"><HiOutlineDocumentReport /> Reporte de Competencias por rol</Nav.Link></Nav.Item>
                  <Nav.Item><Nav.Link eventKey="reporte-desempeno-nivel"><HiOutlineDocumentReport /> Reporte de Competencias por nivel</Nav.Link></Nav.Item>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion> :null}
            {logData.servicio_climaLaboral === "activo" ? <Accordion>
              <Accordion.Item eventKey="3">
                <Accordion.Header>Clima</Accordion.Header>         
                <Accordion.Body>
                <Nav.Item><Nav.Link eventKey="reporte-secciones-clima"><HiOutlineDocumentReport /> Reporte por secciones</Nav.Link></Nav.Item>
                <Nav.Item><Nav.Link eventKey="reporte-preguntas-clima"><HiOutlineDocumentReport /> Reporte por Pregunta</Nav.Link></Nav.Item>
                <Nav.Item><Nav.Link eventKey="reporte-preguntas-clima-acum"><HiOutlineDocumentReport /> Reporte por Preguntas Acumulado</Nav.Link></Nav.Item>
                <Nav.Item><Nav.Link eventKey="reporte-seccion-clima-acum"><HiOutlineDocumentReport /> Reporte por Secciones Acumulado</Nav.Link></Nav.Item>
                {/*logData.servicio_climaLaboral === "activo" ? <Nav.Item><Nav.Link eventKey="reporte-futuros"><HiOutlineTag /> Reporte opinion a Futuro</Nav.Link></Nav.Item>:null*/}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion> :null}
            {logData.servicio_shop === "activo" ? <Accordion>
              <Accordion.Item eventKey="3">
                <Accordion.Header>Precios</Accordion.Header>         
                <Accordion.Body>
                  <Nav.Item><Nav.Link eventKey="reporte-precios"><HiOutlineDocumentReport /> Reporte Precios</Nav.Link></Nav.Item>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion> :null}
            {logData.servicio_audit === "activo" ? <Accordion>
              <Accordion.Item eventKey="3">
                <Accordion.Header>Auditorias</Accordion.Header>         
                <Accordion.Body>
                  {/*<Nav.Item><Nav.Link eventKey="reporte-audit"><HiOutlineDocumentReport /> Reporte Auditoria</Nav.Link></Nav.Item>*/}
                  {/*<Nav.Item><Nav.Link eventKey="reporte-audit-det"><HiOutlineDocumentReport /> Reporte Auditoria Detallado</Nav.Link></Nav.Item>*/}
                  {<Nav.Item className="nowrap"><Nav.Link eventKey="reporte-puntos" disabled={userData.subscription === "trial"}><HiOutlineDocumentReport /> Reporte por Sucursal {userData.subscription === "trial" ? <FaUnlock className="m-1" />:null}</Nav.Link></Nav.Item>}
                  <Nav.Item><Nav.Link eventKey="reporte-secciones"><HiOutlineDocumentReport /> Reporte por secciones</Nav.Link></Nav.Item>
                  <Nav.Item><Nav.Link eventKey="reporte-preguntas"><HiOutlineDocumentReport /> Reporte por Pregunta</Nav.Link></Nav.Item>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion> :null}
              {/*<Nav.Item><Nav.Link eventKey="reporte-PB"><HiOutlineDocumentReport /> Reportes Dinamicos PB</Nav.Link></Nav.Item>*/}                                                        
              <Nav.Item><Nav.Link eventKey="objeto-estudio-view"><HiOutlineEye /> Objetivo de estudio</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="metodologia-view"><HiOutlineClipboardList /> Metodologia</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="plan-accion"><BsController /> Plan de Acción</Nav.Link></Nav.Item>
            </Nav>
          </Col></Row>
        </Tab.Container>
      case 'control':
        return <Tab.Container onSelect={(k) => newLocation('/app/control/' + k)} id="left-tabs-example" defaultActiveKey="data-cruda">
        <Row><Col>
          <Nav variant="pills" className="flex-column">
            <Nav.Item><Nav.Link eventKey="data-cruda"><HiOutlineBookOpen /> Data cruda</Nav.Link></Nav.Item>
            {/*<Nav.Item><Nav.Link eventKey="tracking"><HiOutlineFingerPrint /> Tracking de acceso</Nav.Link></Nav.Item>*/}
            {/*<Nav.Item><Nav.Link eventKey="user-control"><HiOutlineUserGroup /> Control de usuarios</Nav.Link></Nav.Item>*/}
            <Nav.Item><Nav.Link eventKey="statistics"><HiOutlineClipboardList /> Estadisticas </Nav.Link></Nav.Item>
            <Nav.Item><Nav.Link eventKey="objeto-estudio"><HiOutlineEye /> Objetivo de estudio</Nav.Link></Nav.Item>
            <Nav.Item><Nav.Link eventKey="metodologia"><HiOutlineClipboardList /> Metodologia</Nav.Link></Nav.Item>
            {logData && logData.servicio_encuesta === "activo" ? <Nav.Item><Nav.Link eventKey="plan-accion/evaluacion"><BsController /> Evaluar Plan de acción</Nav.Link></Nav.Item>:null}
            {logData && logData.servicio_encuesta === "activo" ? <Nav.Item><Nav.Link eventKey="reporte-plan"><BsController /> Reporte planes de Acción </Nav.Link></Nav.Item>:null}              
          </Nav>
        </Col></Row>
      </Tab.Container>
      case 'correos' :
        return <Tab.Container onSelect={(k) => newLocation('/app/correos/' + k)} id="left-tabs-example" defaultActiveKey="contactos">
          <Row><Col>
            <Nav variant="pills" className="flex-column">                                                      
              <Nav.Item><Nav.Link eventKey="contactos"><BsController /> Contactos </Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="bandeja"><BsController /> Enviar Correo </Nav.Link></Nav.Item>              
            </Nav>
          </Col></Row>
        </Tab.Container>
      default: return <></>
    }
  }
  function MenuMobile() {
    switch (bit.type) {
      case 'administracion':
        return <Tab.Container onSelect={(k) => newLocation('/app/administracion/' + k)} id="left-tabs-example" defaultActiveKey="nueva-encuesta">          
        <Row><Col>
            <Nav variant="pills" className="flex-column">
              <Nav.Item><Nav.Link eventKey="nueva-encuesta"><HiOutlineCalendar /> Nueva encuesta </Nav.Link></Nav.Item>
              <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Administracion</Accordion.Header>
                <Accordion.Body>
                    <Nav.Item><Nav.Link eventKey="periodos"><HiOutlineCalendar /> Periodos</Nav.Link></Nav.Item>
                    <Nav.Item><Nav.Link eventKey="encuestas"><HiOutlineDocument /> Encuestas</Nav.Link></Nav.Item>
                    <Nav.Item><Nav.Link eventKey="secciones"><HiOutlineViewList /> Secciones</Nav.Link></Nav.Item>
                    <Nav.Item><Nav.Link eventKey="segmento-pregunta"><HiOutlineTag /> Segmento de pregunta</Nav.Link></Nav.Item>
                    <Nav.Item><Nav.Link eventKey="preguntas"><HiOutlineQuestionMarkCircle /> Preguntas</Nav.Link></Nav.Item>
                    <Nav.Item><Nav.Link eventKey="zonas"><BsImage /> Zonas</Nav.Link></Nav.Item>
                    <Nav.Item><Nav.Link eventKey="ciudades"><BsGeoAlt /> Ciudades</Nav.Link></Nav.Item>
                    <Nav.Item><Nav.Link eventKey="puntos"><RiHomeLine /> Sucursales</Nav.Link></Nav.Item>
                    <Nav.Item><Nav.Link eventKey="segmentacion-puntos"><HiOutlineTag /> Segmentacion de puntos</Nav.Link></Nav.Item>
                    {logData && logData.servicio_mystery === "activo" ? <Nav.Item><Nav.Link eventKey="videos"><HiOutlineVideoCamera /> Videos</Nav.Link></Nav.Item> : null}
                    {logData && logData.servicio_shop === "activo" ? <Nav.Item><Nav.Link eventKey="lista-precio"><HiOutlineQuestionMarkCircle /> Lista de Precios</Nav.Link></Nav.Item> : null}
                </Accordion.Body>
                </Accordion.Item>
              </Accordion>              
              <Nav.Item><Nav.Link eventKey="objeto-estudio"><HiOutlineEye /> Objetivo de estudio</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="metodologia"><HiOutlineClipboardList /> Metodologia</Nav.Link></Nav.Item>
              {/*<Nav.Item><Nav.Link eventKey="reporte-cliente"><HiOutlineDocumentReport /> Hoja de Reporte</Nav.Link></Nav.Item>*/}
              <Nav.Item><Nav.Link eventKey="parametrizar"><BsPalette /> Parametrizar Colores</Nav.Link></Nav.Item>
              {logData && logData.servicio_encuesta === "activo" ? <Nav.Item><Nav.Link eventKey="plan-accion"><BsController /> Plan de Acción</Nav.Link></Nav.Item>:null}
              {logData && logData.servicio_encuesta === "activo" ? <Nav.Item><Nav.Link eventKey="plan-accion/evaluacion"><BsController /> Evaluar Plan de acción</Nav.Link></Nav.Item>:null}
            </Nav>
          </Col></Row>
        </Tab.Container>
      case 'reportes':
        return <Tab.Container onSelect={(k) => newLocation('/app/reportes/' + k)} id="left-tabs-example" defaultActiveKey="reporte-encuestas">      
          <Row><Col>         
            <Nav variant="pills" className="flex-column">
            {logData.servicio_encuesta === "activo" ? <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>NPS</Accordion.Header>
                <Accordion.Body>
                    <Nav.Item><Nav.Link eventKey="tablero-principal" ><HiOutlineDocumentReport /> Tablero Principal </Nav.Link></Nav.Item>
                    <Nav.Item><Nav.Link eventKey="gestion-diaria" ><HiOutlineDocumentReport /> Gestion Diaria</Nav.Link></Nav.Item>
                    <Nav.Item><Nav.Link eventKey="gestion-detractor" ><HiOutlineDocumentReport /> Gestionar Detractores </Nav.Link></Nav.Item>
                    <Nav.Item><Nav.Link eventKey="reporte-nps" ><HiOutlineDocumentReport /> Reporte NPS</Nav.Link></Nav.Item>
                    {logData && Number(logData.id) !== 25 ? <Nav.Item className="nowrap"><Nav.Link eventKey="reporte-evaluacion"><HiOutlineDocumentReport /> Reporte por evaluacion </Nav.Link></Nav.Item> : null }
                    {logData && Number(logData.id) !== 25 ? <Nav.Item><Nav.Link eventKey="reporte-encuestas" ><HiOutlineDocumentReport /> Reporte por encuesta</Nav.Link></Nav.Item> : null }
                    {logData && Number(logData.id) !== 25 ? <Nav.Item><Nav.Link eventKey="reporte-secciones"><HiOutlineDocumentReport /> Reporte por secciones</Nav.Link></Nav.Item> : null }
                   <Nav.Item><Nav.Link eventKey="reporte-preguntas"><HiOutlineDocumentReport /> Reporte por Pregunta</Nav.Link></Nav.Item>
                   {logData && Number(logData.id) !== 25 ? <Nav.Item className="nowrap"><Nav.Link eventKey="segmento-preguntas" disabled={userData.subscription === "trial"}><HiOutlineTag /> Segmento pregunta {userData.subscription === "trial" ? <FaUnlock className="m-1" />:null}</Nav.Link></Nav.Item> : null }
                   <Nav.Item className="nowrap"><Nav.Link eventKey="reporte-puntos" disabled={userData.subscription === "trial"}><HiOutlineDocumentReport /> Reporte por Sucursal {userData.subscription === "trial" ? <FaUnlock className="m-1" />:null}</Nav.Link></Nav.Item>
                   {/*<Nav.Item className="nowrap"><Nav.Link eventKey="reporte-preguntas-abiertas"  ><HiOutlineDocumentReport /> Reporte de preguntas abiertas</Nav.Link></Nav.Item> {/* className="disabled-option" eventKey="reporte-preguntas-abiertas"  */}
                  {logData && Number(logData.id) !== 25 ? <Nav.Item className="nowrap"><Nav.Link eventKey="reporte-preguntas-acum" disabled={userData.subscription === "trial"}><HiOutlineDocumentReport /> Reporte por Preguntas Acumulado {userData.subscription === "trial" ? <FaUnlock className="m-1" />:null}</Nav.Link></Nav.Item> : null }
                  {logData && Number(logData.id) !== 25 ? <Nav.Item className="nowrap"><Nav.Link eventKey="reporte-puntos-acum" disabled={userData.subscription === "trial"}><HiOutlineDocumentReport /> Reporte por Sucursal Acumulado {userData.subscription === "trial" ? <FaUnlock className="m-1" />:null}</Nav.Link></Nav.Item> : null }
                   {/*<Nav.Item className="nowrap"><Nav.Link eventKey="segmento-punto" disabled={userData.subscription === "trial"}><HiOutlineTag /> Segmento Sucursal {userData.subscription === "trial" ? <FaUnlock className="m-1" />:null}</Nav.Link></Nav.Item>*/}
                   {logData && Number(logData.id) !== 25 ? <Nav.Item className="nowrap"><Nav.Link eventKey="reporte-encuesta-acum" disabled={userData.subscription === "trial"}><HiOutlineDocumentReport /> Reporte por Encuesta Acumulado {userData.subscription === "trial" ? <FaUnlock className="m-1" />:null}</Nav.Link></Nav.Item> : null }
                   {logData && Number(logData.id) !== 25 ? <Nav.Item className="nowrap"><Nav.Link eventKey="reporte-seccion-acum" disabled={userData.subscription === "trial"}><HiOutlineDocumentReport /> Reporte por Secciones Acumulado {userData.subscription === "trial" ? <FaUnlock className="m-1" />:null}</Nav.Link></Nav.Item> : null }
                </Accordion.Body>
                </Accordion.Item>
              </Accordion>:null}
            {logData.servicio_mystery === "activo" ? <Accordion>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Mystery</Accordion.Header>         
                <Accordion.Body>
                  <Nav.Item><Nav.Link eventKey="listar-videos"><HiOutlineVideoCamera /> Listar videos</Nav.Link></Nav.Item> 
                  <Nav.Item className="nowrap"><Nav.Link eventKey="reporte-evaluacion"><HiOutlineDocumentReport /> Reporte por evaluacion </Nav.Link></Nav.Item>
                  <Nav.Item><Nav.Link eventKey="reporte-preguntas"><HiOutlineDocumentReport /> Reporte por Pregunta</Nav.Link></Nav.Item>
                  <Nav.Item className="nowrap"><Nav.Link eventKey="segmento-preguntas" disabled={userData.subscription === "trial"}><HiOutlineTag /> Segmento pregunta {userData.subscription === "trial" ? <FaUnlock className="m-1" />:null}</Nav.Link></Nav.Item> 
                  {logData && Number(logData.id) !== 25 ? <Nav.Item className="nowrap"><Nav.Link eventKey="reporte-puntos" disabled={userData.subscription === "trial"}><HiOutlineDocumentReport /> Reporte por Sucursal {userData.subscription === "trial" ? <FaUnlock className="m-1" />:null}</Nav.Link></Nav.Item>: null}
                  {logData && (Number(logData.id) === 59 || Number(logData.id) === 49) ? <Nav.Item><Nav.Link eventKey="reporte-secciones"><HiOutlineDocumentReport /> Reporte por secciones</Nav.Link></Nav.Item> : null}
                  <Nav.Item className="nowrap"><Nav.Link eventKey="reporte-preguntas-acum" disabled={userData.subscription === "trial"}><HiOutlineDocumentReport /> Reporte por Preguntas Acumulado {userData.subscription === "trial" ? <FaUnlock className="m-1" />:null}</Nav.Link></Nav.Item>
                  {logData && Number(logData.id) !== 25 ? <Nav.Item className="nowrap"><Nav.Link eventKey="reporte-puntos-acum" disabled={userData.subscription === "trial"}><HiOutlineDocumentReport /> Reporte por Sucursal Acumulado {userData.subscription === "trial" ? <FaUnlock className="m-1" />:null}</Nav.Link></Nav.Item> : null}
                  {logData && Number(logData.id) !== 25 ? <Nav.Item className="nowrap"><Nav.Link eventKey="reporte-encuesta-acum" disabled={userData.subscription === "trial"}><HiOutlineDocumentReport /> Reporte por Encuesta Acumulado {userData.subscription === "trial" ? <FaUnlock className="m-1" />:null}</Nav.Link></Nav.Item> : null}
                  {logData && Number(logData.id) !== 25 ? <Nav.Item className="nowrap"><Nav.Link eventKey="reporte-seccion-acum" disabled={userData.subscription === "trial"}><HiOutlineDocumentReport /> Reporte por Secciones Acumulado {userData.subscription === "trial" ? <FaUnlock className="m-1" />:null}</Nav.Link></Nav.Item> : null}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>: null}
            {logData.servicio_desempeno === "activo" ? <Accordion>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Desempeño</Accordion.Header>         
                <Accordion.Body>
                  <Nav.Item><Nav.Link eventKey="reporte-desempeno-general"><HiOutlineDocumentReport /> Reporte Resultados generales</Nav.Link></Nav.Item>
                  <Nav.Item><Nav.Link eventKey="reporte-desempeno-participacion"><HiOutlineDocumentReport /> Reporte de Participacion</Nav.Link></Nav.Item>
                  <Nav.Item><Nav.Link eventKey="reporte-desempeno"><HiOutlineDocumentReport /> Reporte de Competencias generales</Nav.Link></Nav.Item>
                  <Nav.Item><Nav.Link eventKey="reporte-desempeno-rol"><HiOutlineDocumentReport /> Reporte de Competencias por rol</Nav.Link></Nav.Item>
                  <Nav.Item><Nav.Link eventKey="reporte-desempeno-nivel"><HiOutlineDocumentReport /> Reporte de Competencias por nivel</Nav.Link></Nav.Item>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion> :null}
            {logData.servicio_climaLaboral === "activo" ? <Accordion>
              <Accordion.Item eventKey="3">
                <Accordion.Header>Clima</Accordion.Header>         
                <Accordion.Body>
                <Nav.Item><Nav.Link eventKey="reporte-secciones-clima"><HiOutlineDocumentReport /> Reporte por secciones</Nav.Link></Nav.Item>
                <Nav.Item><Nav.Link eventKey="reporte-preguntas-clima"><HiOutlineDocumentReport /> Reporte por Pregunta</Nav.Link></Nav.Item>
                <Nav.Item><Nav.Link eventKey="reporte-preguntas-clima-acum"><HiOutlineDocumentReport /> Reporte por Preguntas Acumulado</Nav.Link></Nav.Item>
                <Nav.Item><Nav.Link eventKey="reporte-seccion-clima-acum"><HiOutlineDocumentReport /> Reporte por Secciones Acumulado</Nav.Link></Nav.Item>
                {/*logData.servicio_climaLaboral === "activo" ? <Nav.Item><Nav.Link eventKey="reporte-futuros"><HiOutlineTag /> Reporte opinion a Futuro</Nav.Link></Nav.Item>:null*/}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion> :null}
            {logData.servicio_shop === "activo" ? <Accordion>
              <Accordion.Item eventKey="3">
                <Accordion.Header>Precios</Accordion.Header>         
                <Accordion.Body>
                  <Nav.Item><Nav.Link eventKey="reporte-precios"><HiOutlineDocumentReport /> Reporte Precios</Nav.Link></Nav.Item>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion> :null}
            {logData.servicio_audit === "activo" ? <Accordion>
              <Accordion.Item eventKey="3">
                <Accordion.Header>Auditorias</Accordion.Header>         
                <Accordion.Body>
                  <Nav.Item><Nav.Link eventKey="reporte-audit"><HiOutlineDocumentReport /> Reporte Auditoria</Nav.Link></Nav.Item>
                  <Nav.Item><Nav.Link eventKey="reporte-audit-det"><HiOutlineDocumentReport /> Reporte Auditoria Detallado</Nav.Link></Nav.Item>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion> :null}
              {/*<Nav.Item><Nav.Link eventKey="reporte-PB"><HiOutlineDocumentReport /> Reportes Dinamicos PB</Nav.Link></Nav.Item>*/}                                                        
              <Nav.Item><Nav.Link eventKey="objeto-estudio-view"><HiOutlineEye /> Objetivo de estudio</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="metodologia-view"><HiOutlineClipboardList /> Metodologia</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="plan-accion"><BsController /> Plan de Acción</Nav.Link></Nav.Item>
            </Nav>
          </Col></Row> 
        </Tab.Container>
      case 'control':
        return <Tab.Container onSelect={(k) => newLocation('/app/control/' + k)} id="left-tabs-example" defaultActiveKey="data-cruda">
          <Row><Col>
          <Nav variant="pills" className="flex-column">
            <Nav.Item><Nav.Link eventKey="data-cruda"><HiOutlineBookOpen /> Data cruda</Nav.Link></Nav.Item>
            {/*<Nav.Item><Nav.Link eventKey="tracking"><HiOutlineFingerPrint /> Tracking de acceso</Nav.Link></Nav.Item>*/}
            {/*<Nav.Item><Nav.Link eventKey="user-control"><HiOutlineUserGroup /> Control de usuarios</Nav.Link></Nav.Item>*/}
            <Nav.Item><Nav.Link eventKey="statistics"><HiOutlineClipboardList /> Estadisticas </Nav.Link></Nav.Item>
            <Nav.Item><Nav.Link eventKey="objeto-estudio"><HiOutlineEye /> Objetivo de estudio</Nav.Link></Nav.Item>
            <Nav.Item><Nav.Link eventKey="metodologia"><HiOutlineClipboardList /> Metodologia</Nav.Link></Nav.Item>
            {logData && logData.servicio_encuesta === "activo" ? <Nav.Item><Nav.Link eventKey="plan-accion/evaluacion"><BsController /> Evaluar Plan de acción</Nav.Link></Nav.Item>:null}
            {logData && logData.servicio_encuesta === "activo" ? <Nav.Item><Nav.Link eventKey="reporte-plan"><BsController /> Reporte planes de Acción </Nav.Link></Nav.Item>:null}              
          </Nav>
        </Col></Row>
        </Tab.Container>
      case 'correos' :
        return <Tab.Container onSelect={(k) => newLocation('/app/correos/' + k)} id="left-tabs-example" defaultActiveKey="contactos">
          <Row><Col>
            <Nav variant="pills" className="flex-column">                                                      
              <Nav.Item><Nav.Link eventKey="contactos"><BsController /> Contactos </Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="bandeja"><BsController /> Enviar Correo </Nav.Link></Nav.Item>              
            </Nav>
          </Col></Row>
        </Tab.Container>
      default: return <></>
    }
  }

  if(mobile === "no") {return Menu()}
  if(mobile === "yes") {return MenuMobile()}
}