import { useState, useContext, useEffect } from "react";
import axios from "axios"
import ClientContext from "../../../Context/ClientContext";
import UserContext from "../../../Context/UserContext";
import parse from 'html-react-parser';

export default function MetodologiaView() {
    const API_URL = 'https://api.medicionanalytic.com/api'
    const {logData} = useContext(ClientContext);
    const {userData} = useContext(UserContext);
    const [metodologia, setMet] = useState("")

useEffect(() => {
    if(userData.permiso === 'admin')
    { 
        axios.get(API_URL + '/metodologia', {params: {client_id:logData.id}}).then(res => {
            if(res.data.length > 0) {setMet(res.data[0].metodologia)}
        })
    }
    else {
        axios.get(API_URL + '/metodologia', {params: {client_id:userData.cliente_id}}).then(res => {
            if(res.data.length > 0) {setMet(res.data[0].metodologia)}
        })
    }
}, [])

return(<>
<div className="content-container">
    <div className="content-body-alt p-2">
        <h5>Metodologia</h5>
        <div className="Banner mb-3">
            {parse(metodologia)}
        </div>
    </div>
</div>
    </>)
}