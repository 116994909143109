import { useState} from "react";
import { BarLoader } from "react-spinners"
import { Outlet} from "react-router-dom";
import Sidebar from "../../MainFrame/Sidebar/Sidebar";
import { Tab, Nav, Offcanvas } from "react-bootstrap";
import { BiMenu } from "react-icons/bi"
import "./Dashboard.css";

export default function AdminDashboard() {
    const [loading, setLoading] = useState(false)
    const [side, setSide] = useState(false)
    
    const handleSide = () => {
            setSide(!side)
     }
    return (<>
        <div className="hidden-lg tap-2" onClick={handleSide}> <BiMenu /></div>
        <div className="over-container">
            <div className="side-container hidden-sm">
             <Sidebar bit={{type:"administracion"}} mobile={"no"} />
             <Offcanvas show={side} onHide={handleSide} placement="start">
                <Offcanvas.Body>
                <Tab.Container>
                    <Nav variant="pills" className="flex-column">
                    <Sidebar bit={{type:"administracion"}} mobile={"yes"} />
                    </Nav>
                </Tab.Container>
                </Offcanvas.Body>
            </Offcanvas>
            </div>
            <div className="main-container">
                {loading ? <BarLoader className="m-5" speedMultiplier={0.5} color="#36d7b7" width={"60vw"} /> : <Outlet />}
            </div>
        </div>
    </>)
}