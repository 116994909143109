import { useState, useContext, useEffect } from "react";
import axios from "axios"
import ClientContext from "../../../Context/ClientContext";
import UserContext from "../../../Context/UserContext";
import parse from 'html-react-parser';


export default function ObjetoEstudioView() {
    const API_URL = 'https://api.medicionanalytic.com/api'
    const {logData} = useContext(ClientContext)
    const {userData} = useContext(UserContext)
    const [objetivo, setObj] = useState("")

useEffect(() => {
    if(userData.permiso === 'admin')
    { 
        axios.get(API_URL + '/objetivo', {params: {client_id:logData.id}}).then(res => {
            if(res.data.length > 0) {setObj(res.data[0].objetivo)}
        })
    }
    else {
        axios.get(API_URL + '/objetivo', {params: {client_id:userData.cliente_id}}).then(res => {
            if(res.data.length > 0) {setObj(res.data[0].objetivo)}
        })
    }
}, [])

return(<>
<div className="content-container">
    <div className="content-body-alt p-2">
        <h5>Objeto de Estudio</h5>
        <div className="Banner mb-3">
            {parse(objetivo)}   
        </div>
    </div>
</div>
    </>)
}