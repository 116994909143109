import axios from "axios"
import {  Button, Form, Row, Modal, InputGroup, FormGroup, Col } from "react-bootstrap"
import { useState, useEffect, useRef} from "react"
import { useParams, useNavigate } from "react-router-dom"
import { useForm } from "react-hook-form"
import useStateWithCallback from 'use-state-with-callback';
import Footer from "../MainFrame/Footer/Footer"
import {format } from 'date-fns';
import { ClipLoader } from "react-spinners"
import { FaRegThumbsUp, FaStar, FaRegThumbsDown} from "react-icons/fa";
import { FaRegFaceAngry, FaRegFaceFrown,FaRegFaceMeh, FaRegFaceSmile, FaRegFaceSmileBeam } from "react-icons/fa6"
import { read, utils} from 'xlsx';
import './Active.css'
import parse from 'html-react-parser';

export default function ClientEncuesta() {
    let tipo_pregunta = ""    
    const Image_URL = 'https://api.medicionanalytic.com/medicionapi/storage/app/public'
    const API_URL = 'https://api.medicionanalytic.com/api'    
    const newLocation = useNavigate();
    const inputRef = useRef(null);    
    const [reporte, setReporte] = useState(null)
    const [sucursales, setSucursal] = useState([])
    const [cliente, setCL] = useState({})
    const [SS, setSS] = useState([])
    const [enc, setEnc] = useState({})
    const [secciones, setSecciones] = useState([])
    const [options, setOptions] = useState([])
    const [finish, setFin] = useState(false)
    const [End, showEnd] = useState(false)
    const [go, setGo] = useState(false)

    const [dataloading, setLoad] = useState(false)
    const [conteoPreguntas, setCount] = useState([])
    const [conteo, setConteo] = useState(0)
    const [precios, setPrecios] = useState([])
    const [row, setRow] = useState([])

    const [optionList, setOL] = useState([])
    const [userList, setList] = useState([])
    const [preguntas, setPreguntas] = useState([])
    const [respuestas, setRespuestas] = useStateWithCallback([], () => {})
    
    let { sp, id, sec, suc} = useParams();
    const [viewUp, setUp] = useState(false)
    const [periodo, setPeriodo] = useState([])    

    const showUp = () => {setUp(true)}
    const hideUp = () => {setUp(false)}

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        watch,
        getValues,
        formState: { errors },
    } = useForm({ mode: "onBlur" });

    useEffect(() => {
        let encu = {}        
        axios.get(API_URL+`/encuesta/${id}`).then(res => {            
            setEnc(res.data)            
            encu = res.data
        })
        axios.post(API_URL + '/pregunta/active', { encuesta_id: id }).then((res) => {
            let client_id = res.data.client[0].client_id
            setPreguntas(res.data.preguntas)            
            let x = groupBy(res.data.preguntas, "seccion_id")            
            if(Object.keys(x).length <= 0){} 
            else {setCount(x)}
            setSecciones(res.data.secciones)
            axios.get(API_URL + '/usuario', {params: {cliente_id:client_id}}).then(res => {setList(res.data)})
            axios.get(API_URL + '/sucursal', { params: { cliente_id: client_id } }).then((res) => {setSucursal(res.data)})
            axios.get(API_URL + '/cliente', {params : {subscription : "super"}}).then( function (response) { setCL(response.data.find(cl => cl.id === client_id)) })
            if(encu.tipo_encuesta === "precios") {
                axios.get(API_URL+'/precios', {params: {client_id:client_id}}).then(res => {
                    let dat = res.data.filter(d => d.encuesta_id === encu.id)
                    setPrecios(dat)
                })
            }            
            axios.get(API_URL + '/periodo', { params: { client_id:client_id } }).then(
                function(response) {                    
                    response.data.sort(function(a,b){return new Date(a.periodo_inicio) - new Date(b.periodo_inicio) })                    
                        let periods = response.data                        
                        axios.get(API_URL + '/joint', { params: { encuesta_id: id} }).then((response) => {                
                            let arr = response.data.sort(function(a,b){return b.periodo_id - a.periodo_id;})                
                            newLocation("../encuesta/"+arr[0].periodo_id+"/"+id+"/"+sec+"/"+suc)                                                                
                            setPeriodo(periods.find(p => {return Number(p.id) === Number(arr[0].periodo_id)}))
                    })                                                 
                })
        })                  
    }, [])
    useEffect(() => { if(finish) { showEnd(true) }}, [finish])    
    useEffect(() => { CreateReport() },[])
    
    const handleList = (item, preg) => {
        let list = optionList;    
        
        let place = optionList.findIndex(i => i === item)
        if( place > -1) 
        { list.splice(place, 1)}
        else if (list.length < preg.valor_max)
        {list.push(item);}

        setValue("respuesta", JSON.stringify(list))
        setOL(list)

        if(list.length > preg.valor_max || list.length < preg.valor_min) {setGo(false)} //toast error
        else{setGo(true)}
    }
    function isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    const getPregs = (seccion_id) => {
        let temp = []    
        temp = conteoPreguntas[seccion_id]
        return temp;
    }
    const handleObjects = (e,item,step) => {
        if(isJson(getValues('respuesta'))) {        
            let items = JSON.parse(getValues('respuesta'))
            if(items.findIndex(i => {return i === item}) > -1){
                e.target.parentNode.children[step].classList.add("form-option-grid-active")
                e.target.parentNode.children[step].classList.remove("form-option-grid")
            }
            else {
                e.target.parentNode.children[step].classList.add("form-option-grid")
                e.target.parentNode.children[step].classList.remove("form-option-grid-active")
            }
        }    
    }

    function renderSwitch() {          
        if(Object.keys(conteoPreguntas).length < 1) {return "no hay preguntas disponibles"}
        else {            
            if (secciones.length > 0 && conteo < getPregs(sec).length) {
                let secc = sec
                let numbered = getPregs(secc).filter(p => Number(p.orden) > 0)
                let unnumbered = getPregs(secc).filter(p => Number(p.orden <= 0))
                numbered = numbered.sort((a, b) => Number(a.orden) > Number(b.orden) ? 1 : -1)
                let preg = [...numbered,...unnumbered]                
                let temp = preg[conteo]                
                tipo_pregunta = temp.tipo_pregunta
                if(options.length < 1 && (temp.tipo_pregunta === "select" || temp.tipo_pregunta === "selectm")) {                
                    setOptions(JSON.parse(temp.options))
                }
                if(temp.pausa === 0) {
                    switch (temp.tipo_pregunta) {
                        case 'escala-tri':
                            return <>
                                    <Form.Control type="hidden" value={temp.tipo_pregunta} {...register("tipo_pregunta")} />
                                    <Form.Control type="hidden" value={temp.titulo} {...register("pregunta")} />
                                    <Form.Control type="hidden" value={temp.id} {...register("pregunta_id")}/>
                                    <Form.Label className="m-2 mt-0"><h5>{(conteo+1)+"-"+temp.titulo}</h5></Form.Label>
                                    <InputGroup className="mt-2">
                                        <Button variant="outline-danger" type="submit" onClick={() => setValue("respuesta", "malo")}>malo</Button>
                                        <Button variant="outline-warning" type="submit" onClick={() => setValue("respuesta", "regular")}>regular</Button>
                                        <Button variant="outline-success" type="submit" onClick={() => setValue("respuesta", "excelente")}>excelente</Button>
                                    </InputGroup>
                            </>
                        case 'escala': let escala = [...Array(temp.valor_max).keys()]
                            return <>
                                <Form.Control type="hidden" value={temp.tipo_pregunta} {...register("tipo_pregunta")} />
                                <Form.Control type="hidden" value={temp.titulo} {...register("pregunta")} />
                                <Form.Control type="hidden" value={temp.id} {...register("pregunta_id")}/>
                                <Form.Control type="hidden" value={temp.valor_max} {...register("valor_max")} />
                                <Form.Label className="m-2 mt-0"><h5>{(conteo+1)+"-"+temp.titulo}</h5></Form.Label>
                                <InputGroup>
                                    {escala.map((number) => <Button type="submit" className="form-option-slim m-1" onClick={() => setValue('respuesta', number + 1)} {...register("respuesta")}>{number + 1}</Button>)}
                                </InputGroup>
                            </>
                        case "nps": let nps = [...Array(11).keys()]
                        return <>
                            <Form.Control type="hidden" value={temp.tipo_pregunta} {...register("tipo_pregunta")} />
                            <Form.Control type="hidden" value={temp.titulo} {...register("pregunta")} />
                            <Form.Control type="hidden" value={temp.id} {...register("pregunta_id")}/>
                            <Form.Control type="hidden" value={10} {...register("valor_max")} />
                            <Form.Label className="m-2 mt-0"><h5>{(conteo+1)+"-"+temp.titulo}</h5></Form.Label>
                            <InputGroup>
                                <FaRegFaceFrown className="red  m-1 mt-2" size={35}/>
                                {nps.map((number) => <Button type="submit" className={"form-option-slim-"+number+" m-1"} onClick={() => setValue('respuesta', number)} {...register("respuesta")}>{number}</Button>)}
                                <FaRegFaceSmile className="deepgreen m-1 mt-2" size={35}/>
                            </InputGroup>
                        </>
                        case "npsna": let npsna = [...Array(11).keys()]
                        return <>
                            <Form.Control type="hidden" value={temp.tipo_pregunta} {...register("tipo_pregunta")} />
                            <Form.Control type="hidden" value={temp.titulo} {...register("pregunta")} />
                            <Form.Control type="hidden" value={temp.id} {...register("pregunta_id")}/>
                            <Form.Control type="hidden" value={10} {...register("valor_max")} />                        
                            <Form.Label className="m-2 mt-0"><h5>{(conteo+1)+"-"+temp.titulo} ?</h5></Form.Label>
                            <InputGroup>
                                {npsna.map((number) => <Button type="submit" className={"form-option-slim-"+number+" m-1"} onClick={() => setValue('respuesta', number)} {...register("respuesta")}>{number}</Button>)}
                                <Button type="submit" className="form-option-slim m-1" onClick={() => setValue('respuesta', 'na')} {...register("respuesta")} >N/A</Button>
                            </InputGroup>
                        </>
                        case "stars": let escala2 = [...Array(5).keys()]
                            return <FormGroup>
                                    <Form.Control type="hidden" value={temp.tipo_pregunta} {...register("tipo_pregunta")} />
                                    <Form.Control type="hidden" value={temp.titulo} {...register("pregunta")} />
                                    <Form.Control type="hidden" value={temp.id} {...register("pregunta_id")}/>
                                    <Form.Control type="hidden" value={temp.valor_max} {...register("valor_max")} />
                                    <Form.Label className="m-2 mt-0"><h5>{(conteo+1)+"-"+temp.titulo}</h5></Form.Label>
                                    <p className="flex">{escala2.map((number) => <Button type="submit" className="form-option-static m-1"                             
                                        onMouseEnter={(e) => {escala2.forEach((el, index) => {if(index <= number && e.target.children.length > 0 ) {e.target.parentNode.children[el].children[0].classList.add("gold")}    }) } }
                                        onMouseLeave={(e) => {escala2.forEach(el => { if(e.target && e.target.children.length > 0) 
                                            {if (e.target)
                                            {e.target.parentNode.children[el].children[0].classList.remove("gold")}} }) } }
                                        onClick={() => setValue('respuesta', number + 1)} {...register("respuesta")} >                                    
                                        <FaStar className="gray"/>
                                    </Button>)}</p>
                            </FormGroup>
                        case "smiles" : return <FormGroup>
                                    <Form.Control type="hidden" value={temp.tipo_pregunta} {...register("tipo_pregunta")} />
                                    <Form.Control type="hidden" value={temp.titulo} {...register("pregunta")} />
                                    <Form.Control type="hidden" value={temp.id} {...register("pregunta_id")}/>
                                    <Form.Label className="m-2 mt-0"><h5>{(conteo+1)+"-"+temp.titulo}</h5></Form.Label>
                                    <p className="flex">
                                        <Button type="submit" className="form-option m-1" onClick={() => setValue('respuesta', 1)} {...register("respuesta")}><FaRegFaceAngry className="red"/></Button>
                                        <Button type="submit" className="form-option m-1" onClick={() => setValue('respuesta', 2)} {...register("respuesta")}><FaRegFaceFrown className="orange"/></Button>
                                        <Button type="submit" className="form-option m-1" onClick={() => setValue('respuesta', 3)} {...register("respuesta")}><FaRegFaceMeh className="yellow"/></Button>
                                        <Button type="submit" className="form-option m-1" onClick={() => setValue('respuesta', 4)} {...register("respuesta")}><FaRegFaceSmile className="green"/></Button>
                                        <Button type="submit" className="form-option m-1" onClick={() => setValue('respuesta', 5)} {...register("respuesta")}><FaRegFaceSmileBeam className="deepgreen"/></Button>
                                    </p>
                            </FormGroup>
                        case 'thumbs':
                            return <>
                                <Form.Control type="hidden" value={temp.tipo_pregunta} {...register("tipo_pregunta")} />
                                <Form.Control type="hidden" value={temp.titulo} {...register("pregunta")} />
                                <Form.Control type="hidden" value={temp.id} {...register("pregunta_id")}/>
                                <Form.Label className="m-3 mt-0 mb-1"><h5>{(conteo+1)+"-"+temp.titulo}</h5></Form.Label>
                                <InputGroup className="m-2 mt-0 mb-0">
                                    <Button type="submit" className="form-option m-1" onClick={() => setValue("respuesta", "si")}><FaRegThumbsUp /></Button>
                                    <Button type="submit" className="form-option m-1" onClick={() => setValue("respuesta", "no")}><FaRegThumbsDown /></Button>
                                </InputGroup>
                            </>
                        case 'audit':
                            return <>
                                <Form.Control type="hidden" value={temp.tipo_pregunta} {...register("tipo_pregunta")} />
                                <Form.Control type="hidden" value={temp.titulo} {...register("pregunta")} />
                                <Form.Control type="hidden" value={temp.id} {...register("pregunta_id")}/>
                                <Form.Label className="m-3 mt-0 mb-1"><h5>{(conteo+1)+"-"+temp.titulo}</h5></Form.Label>
                                <InputGroup className="m-2 mt-0 mb-0">
                                    <Button type="submit" className="form-option m-1" onClick={() => setValue("respuesta", "si")}>Cumple</Button>
                                    <Button type="submit" className="form-option m-1" onClick={() => setValue("respuesta", "no")}>No cumple</Button>
                                    <Button type="submit" className="form-option m-1" onClick={() => setValue("respuesta", "na")}>N/A</Button>
                                </InputGroup>
                            </>
                        case 'select':
                                return <>
                                    <Form.Group>
                                        <Form.Control type="hidden" value={temp.tipo_pregunta} {...register("tipo_pregunta")} />
                                        <Form.Control type="hidden" value={temp.titulo} {...register("pregunta")} />    
                                        <Form.Control type="hidden" value={temp.id} {...register("pregunta_id")}/>
                                        <Form.Label className="m-2 mt-0"><h5>{(conteo+1)+"-"+temp.titulo}</h5></Form.Label>
                                        <InputGroup className="m-2">         
                                        <Form.Select onChange={(e) => {setValue("respuesta", e.target.value);setGo(true)}}>
                                            <option value={null}>Selecione una opción</option>
                                        {options ? options.map((opt) => {return <option className="m-1 mt-2 select-option" value={opt.opt}>{opt.opt}</option> }):null}                                                           
                                        </Form.Select>            
                                        </InputGroup>                                    
                                    </Form.Group>
                                </>
                        case 'selectm':                            
                                return <>
                                    <Form.Group>
                                        <Form.Control type="hidden" value={temp.tipo_pregunta} {...register("tipo_pregunta")} />
                                        <Form.Control type="hidden" value={temp.titulo} {...register("pregunta")} />    
                                        <Form.Control type="hidden" value={temp.id} {...register("pregunta_id")}/>
                                        <Form.Label className="m-2 mt-0"><h5>{(conteo+1)+"-"+temp.titulo}</h5></Form.Label>
                                        <Row>
                                            <Col>
                                                <h6>Seleccione un minimo de : {temp.valor_min} y un maximo de : {temp.valor_max} respuestas.</h6>
                                            </Col>
                                        </Row>
                                        <InputGroup className="m-2">
                                        <Row className="select-height">
                                            {options.map((opt, index) => { 
                                                return <Button className="m-1 mt-2 form-option-grid " onMouseLeave={(e) => {handleObjects(e,opt.opt, index)}}  size="sm" onClick={() => {handleList(opt.opt, temp)}}>
                                                    {opt.opt}
                                                </Button>
                                                })}
                                        </Row>                                                           
                                        </InputGroup>                                    
                                    </Form.Group>
                                </>
                        case "comentario": return <FormGroup>
                                <Form.Control type="hidden" value={temp.tipo_pregunta} {...register("tipo_pregunta")} />
                                <Form.Control type="hidden" value={temp.titulo} {...register("pregunta")} />    
                                <Form.Control type="hidden" value={temp.id} {...register("pregunta_id")}/>
                                <Form.Label className="m-2 mt-0"><h5>{(conteo+1)+"-"+temp.titulo}</h5></Form.Label>
                                <textarea className="form-control" placeholder="Texto del comentario" {...register("respuesta",{validate: (val) => {if (val.length >0) {setGo(true)} else{setGo(false)}}} )}  rows={4}/>
                            </FormGroup>
                        case "fecha": return <FormGroup>
                            <Form.Control type="hidden" value={temp.tipo_pregunta} {...register("tipo_pregunta")} />
                            <Form.Control type="hidden" value={temp.titulo} {...register("pregunta")} />  
                            <Form.Control type="hidden" value={temp.id} {...register("pregunta_id")}/>  
                            <Form.Label className="m-2 mt-0"><h5>{(conteo+1)+"-"+temp.titulo}</h5></Form.Label>
                            <p className="flex"><Form.Control className="form-option" type="date" {...register("respuesta",{validate: (val) => {if (val.length >0) {setGo(true)} else{setGo(false)}}} )}  /></p>
                            </FormGroup>
                        case "info": return <FormGroup>
                            <Form.Control type="hidden" value={temp.tipo_pregunta} {...register("tipo_pregunta")} />
                            <Form.Control type="hidden" value={temp.titulo} {...register("pregunta")} />    
                            <Form.Control type="hidden" value={temp.id} {...register("pregunta_id")}/>
                            <Form.Label className="m-2 mt-0"><h5>{(conteo+1)+"-"+temp.titulo}</h5></Form.Label>
                                <Form.Control className="mb-1" placeholder="Nombre" {...register("nombrep")}/>                                
                                <Form.Control className="mb-1" placeholder="Teléfono" {...register("telefonop")}/>                                
                      </FormGroup>
                      case "load": return <FormGroup>
                                <Form.Control type="hidden" value={temp.tipo_pregunta} {...register("tipo_pregunta")} />
                                <Form.Control type="hidden" value={temp.titulo} {...register("pregunta")} />    
                                <Form.Control type="hidden" value={temp.id} {...register("pregunta_id")}/>
                                <Form.Label className="m-2 mt-0">{(conteo+1)+"-"+temp.titulo}</Form.Label>
                                <Form.Control type="file" {...register("imagen")}/>
                            </FormGroup>   
                        default:
                            return <Form.Group>
                                <Form.Control type="hidden" value={temp.tipo_pregunta} {...register("tipo_pregunta")} />
                                <Form.Control type="hidden" value={temp.titulo} {...register("pregunta")} />
                                <Form.Control type="hidden" value={temp.id} {...register("pregunta_id")}/>
                                <Form.Label className="m-2 mt-0"><h5>{(conteo+1)+"-"+temp.titulo}</h5></Form.Label>
                                <Form.Control type="text" name="respuesta" as={temp.as} rows={temp.rows} {...register("respuesta",{validate: (val) => {if (val.length >0) {setGo(true)} else{setGo(false)}}} )} />
                            </Form.Group>
                    }
                }
            }        
        }
    } 
    function renderPrecios(precio) {
        return <Form.Group>
                    <Form.Label className="m-2 mt-0">{precio.titulo}</Form.Label>
                    <InputGroup>
                        <Form.Control onChange={(e) => {
                            for(let i = 0; i < e.target.parentNode.children.length; i++) {
                                e.target.parentNode.children[i].classList.remove("btn-active")
                            }
                        }} type="text" name="respuesta" ref={inputRef} />
                        <Button onClick={(e) => {
                            e.target.classList.add('btn-active');
                            setValue("respuesta", inputRef.current.value);
                            setValue("tipo_pregunta", precio.tipo_pregunta);
                            setValue("pregunta", precio.titulo);
                            //addValue(precio)
                            }}>fijar</Button>
                    </InputGroup>
                </Form.Group>
    }
    //pendiente
    const groupBy = (array, key) => {
        return array.reduce((result, currentValue) => {
            (result[currentValue[key]] = result[currentValue[key]] || []).push(
                currentValue
            );
            return result;
        }, {});
    };
    
    const resetQuestionaire = () => {        
        CreateReport()
        setOptions([])
        showEnd(true)        
        setConteo(0)
        setRespuestas([])
        setOL([])
        setFin(false)
    }
    //ver como shufflear sin afectar x elementos iniciales
    //const shuffle = (array) => { return array.sort(() => Math.random() - 0.5); }; 
    function finalGreeting() {
        if(End === false) {
            if(enc.habilitar_mensaje > 0){
                return <div className="End">{dataloading ? <ClipLoader size={100} color="#4538D1"/>: <div>
                    <p>{parse(enc.mensaje_final)}</p>                                            
                    </div>}
                </div>}
        setTimeout(()=>{ if(enc.redireccion > 0) {window.location.replace("https://"+enc.link)}},3000)            
        }
        else {
            return <Row className="End">
                <Col>
                    {/*<Button variant="danger" onClick={() => {Backstep();}}>Atrás</Button> apagado por fallos en guardado de datos*/}
                    <h6>Presione "enviar" para culminar su encuesta.</h6>
                    <Button className="m-2" variant="primary" onClick={() => sendRequest()} > Enviar </Button>
                </Col>
            </Row>            
        }
    }
    function buttonControl(x) {
        let preg
        let temp
        if(secciones.length > 0 && getPregs(sec).length > 0){
            let numbered = getPregs(sec).filter(p => Number(p.orden) > 0)
            let unnumbered = getPregs(sec).filter(p => Number(p.orden <= 0))
            numbered = numbered.sort((a, b) => Number(a.orden) > Number(b.orden) ? 1 : -1)
            preg = [...numbered,...unnumbered]                            
            temp = preg[conteo]
            let a = temp.tipo_pregunta;            
            if (a === 'texto' || a === 'fecha' || a === 'comentario'|| a === 'info'|| a === 'load' || a === 'select' || a === 'selectm') {
                if (conteo + 1 < preg.length) 
                { return <Button className="m-2" disabled={a === 'info' ? false : !go} type="submit"> Continuar </Button> }
                else {return <Button className="m-2" disabled={a === 'info' ? false : !go} type="submit" variant="primary" > Finalizar </Button>}
            }
            else { return null }
        }
        else { return null }
    }
    const postRespuestas = (data, e) => {
        e.preventDefault();
        setGo(false);
        if(data.imagen) {data.imagen = data.imagen[0]}
        if(data.tipo_pregunta === "info") {data.respuesta = data.nombrep+","+data.telefonop}
        data.tipo_encuesta = "encuesta"
        let pnt = sucursales.find(p => p.id === Number(SS))        
        if(enc.chat_id !== undefined) {
            data.chat_id = enc.chat_id;
        }
        data.sucursal = pnt.nombre_sucursal
        data.encuesta_id = Number(id)
        data.periodo_id = Number(sp)
        data.punto_id = Number(SS)
        data.reporte_id = reporte.id
        data.encuestado = reporte.encuestado
        data.seccion_id = sec
        data.date = getDateshort(reporte.fecha)
        data.enc = enc.nombre_encuesta
        data.sec = secciones.find(s => {return Number(s.id) === Number(sec)}).nombre_seccion
        data.pnt = sucursales.find(p => p.id === Number(SS)).nombre_sucursal        
        if (data.aplica === null) {data.aplica = 0;} else { data.aplica = 1; }
        if (data.respuesta !== undefined) {
            setRespuestas(respuesta => [...respuesta, data])
            setOptions([])
            reset();            
            if (conteo + 1 < getPregs(sec).length) {
                setConteo(conteo + 1)
            }
            else { /*setConteo(0);setSA(seccionActual + 1)*/
                setFin(true)               
            }
        }
    }
    const getDateshort = (report) => {                
        const formattedDate = format(report, 'dd/MM/H');            
        return formattedDate        
    }
    function sendRequest() {
        let headers = setHeaders()
        showEnd(false)
        setLoad(true)
        //respuestas.map((r, index) => {const dat = objectToFormData(r)})
        axios.post(API_URL+"/respuesta", respuestas, { headers }).then(res => {
            setLoad(false)
        }).catch(err => {            
            setLoad(false)
        })
    }
    const setHeaders = () => {
        const headers = {
            Accept: "application/json",
            ContentType: "multipart/form-data; charset=utf-8;",
            processData: false,
        };
        return headers;
        }
    const CreateReport = () => {
        let data = {}        
        data.encuesta_id = id
        data.periodo_id = sp
        data.encuestado = "Link a cliente"
        data.sucursal = suc
        data.user_id = null
        setSS(data.sucursal)
        axios.post(API_URL + "/reporte", data).then((res) => {            
            setReporte(res.data)
            reset()
        })
    }
    function EncuestaCheck() {                
        if(Number(sp) === 0) { return }
        if(enc.tipo_encuesta === "precios") {
            return <>
                <div className="top-bubble"></div>
                    {precios.map(p => {return <Row className="m-2">{renderPrecios(p)}</Row>
                    })
                }
                <div className="bottom-bubble"></div>
            </>
        }
        else {
            return <>
            <Row className="m-2 mb-0">
                <h2>Sección: {secciones.length > 0 ? secciones.find(s => {return Number(s.id) === Number(sec)}).nombre_seccion : ""}</h2>
            </Row>
            <Form className="m-2" id="formElem" onSubmit={handleSubmit(postRespuestas)}>
                <Row className="m-2">
                    {renderSwitch()}
                </Row>
                {/*<Button variant="danger" onClick={() => {Backstep();}}>Atrás</Button>*/}
                {buttonControl()}
            </Form>
            </>
        }
    }
    //funciones para importacion de data. verificar excels antes de subir.    
    const handleImport = ($event) => {
        const files = $event.target.files;
        if (files.length) {
            const file = files[0];
            const reader = new FileReader();
            reader.onload = (event) => {
                const wb = read(event.target.result);
                const sheets = wb.SheetNames;

                if (sheets.length) {
                    const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
                    //console.log(rows)
                    setRow(rows)    
                }
            }
            reader.readAsArrayBuffer(file);
        }
    }
    const LoadData = (data,e) => {
        let reporte
        let Answers = []
        row.forEach(r => {
            let tempR = {}
            for (const [key, value] of Object.entries(r)) {                
                if(String(key) === "gerencia") {tempR.gerencia = value}
                if(String(key) === "genero") {tempR.genero = value}
                if(String(key) === "cargo") {tempR.cargo = value}                        
            }
            for (const [key, value] of Object.entries(r)) {
                let temp = {}                            
                preguntas //filtrar por seccion, para que no repita preguntas una y otra vez.
                .forEach(p => {                    
                    if(Number(key) === Number(p.id)) {
                        //console.log(p.titulo+":"+value)
                        temp.pregunta = p.titulo
                        temp.respuesta = value
                        temp.tipo_encuesta = "encuesta"
                        temp.tipo_pregunta = p.tipo_pregunta
                        temp.valor_max = p.valor_max
                        temp.encuesta_id = Number(id)
                        temp.periodo_id = Number(sp)
                        temp.punto_id = null // habia un 19. esto de load data tiene que ver con el sistema de clima laboral
                        temp.reporte_id = reporte
                        temp.seccion_id = secciones.find(s => s.nombre_seccion.toLowerCase() === tempR.gerencia.toLowerCase()).id
                        temp.aplica = 1;                                    
                        temp.gerencia = tempR.gerencia
                        temp.genero = tempR.genero
                        temp.cargo = tempR.cargo
                    }                                
                })                     
                if(temp.pregunta !== undefined)
                {Answers.push(temp)}
            }
            reporte++ 

        })
        setTimeout(()=>{
            setLoad(true)
            let headers = setHeaders() 
            axios.post(API_URL+"/respuesta", Answers, { headers }).then(res => {            
                setLoad(false)    
            }) 
            hideUp()             
            },1000)
    }
    const Backstep = () => {    
       //return to Encuesta Selector.. aka app
       newLocation("../app/inicio")     
    }
    const ValidationCheck = () => {
        let today = new Date()                                
        let theEnd = new Date(periodo.periodo_fin.replace(/-/g, '\/').replace(/T.+/, ''))
        if ((theEnd.getTime() - today.getTime() < 0 && periodo.validez === "cerrado")|| enc.estado == "inactivo") {
            return <Row>
                <h2 className="m-3 center-text">Esta encuesta ya ha finalizado.</h2>
            </Row>
        }        
    }
return (<>  
  <Modal show={viewUp} onHide={hideUp}>
        <Modal.Header closeButton>
            <Modal.Title>Importar Data de reportes</Modal.Title>
        </Modal.Header>
            <Form onSubmit={handleSubmit(LoadData)}>
                <Modal.Body>
                    <Form.Label>Cargue el archivo xlsx con la data pertinente. </Form.Label>
                    <Form.Control type="file" required onChange={handleImport} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="App-button-alt" onClick={hideUp}>Cerrar</Button>
                    <Button type="submit" disabled={dataloading} className="App-button">Importar</Button>
                </Modal.Footer>
            </Form>
    </Modal>
        <div className="out-box">            
        {cliente ? <div className="image-active m-2" style={{backgroundImage:'url("'+Image_URL+cliente.logo+'")'}} /> : null}
            {!finish ?
                <>{reporte ? EncuestaCheck(): ValidationCheck() }</> : finalGreeting()}
        </div>
        <Row className="footer active-footer">
            <Footer />
        </Row>
    </>)
}