import { useState, useEffect} from "react";
import { BarLoader } from "react-spinners"
import { Outlet, useNavigate } from "react-router-dom";
import Sidebar from "../../MainFrame/Sidebar/Sidebar";
import { Tab, Tabs, Nav, Offcanvas } from "react-bootstrap";
import { BiMenu } from "react-icons/bi"
import {HiOutlineHome} from "react-icons/hi";

export default function CorreoContainer() {
    const newLocation = useNavigate();
    const [loading, setLoading] = useState(false)
    const activeSession = window.localStorage.getItem("sessionKey");
    const [side, setSide] = useState(false)
    
    const handleSide = () => {
            setSide(!side)
     }

    useEffect(() => {
        if(!activeSession) {newLocation("/")}
    }, [])

    return(<>
        <div className="hidden-lg tap-2" onClick={handleSide}> <BiMenu /></div>
        <div className="over-container" style={{display:"flex"}}>
            <div className="side-container hidden-sm">
             <Sidebar bit={{type:"correos"}} mobile={"no"} />
             <Offcanvas show={side} onHide={handleSide} onClick={() => handleSide()} placement="start">
                <Offcanvas.Body>
                <Tab.Container>
                    <Nav variant="pills" className="flex-column">
                    <Sidebar bit={{type:"correos"}} mobile={"yes"}/>
                    </Nav>
                </Tab.Container>
                </Offcanvas.Body>
            </Offcanvas>
            </div>
            <div className="main-container">
                {loading ? <BarLoader className="m-5" speedMultiplier={0.5} color="#4538d1" width={"60vw"} /> : <Outlet />}
            </div>
        </div>
    </>)
}