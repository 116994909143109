import { useForm } from "react-hook-form";
import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom"
import { Form, Button, Row } from "react-bootstrap";
import PasswordStrengthBar from "react-password-strength-bar"
import "./Forgot.css"

export default function ForgotPassword() {
    const [password, setPassword] = useState("")
    const [err, setERR] = useState(false)
    const confirmError = 'Ambas contraseñas deben ser iguales'
    const {
        register,
        handleSubmit,
        reset,
        watch,
        formState: { errors },
      } = useForm({ mode: "onBlur" });

      const NewPassword = (data,e) => {
        e.preventDefault()
      }

    return <div className="outer-body">
        <div className="inner-body">       
            <Row className="justify-center mb-3 ">
                <h2>Restablecimiento de Contraseña</h2>
            </Row> 
            <Form onSubmit={handleSubmit(NewPassword)}>
            <Form.Group>
                <Form.Label >Nueva Contraseña</Form.Label>
                <Form.Control className="form-field mb-2" size="sm" type="password" aria-describedby="passwordHelpBlock" {...register("password", {
                    onChange: (e) => {setPassword(e.target.value)},
                    required: "Se requiere una contraseña",
                    minLength: {
                    value: 8,
                    message: "La contraseña debe tener almenos 8 caracteres"
                }
                })} />                    
                <Row><p className="red">{errors.password?.message}</p></Row>
            </Form.Group>
            <Form.Label >Confirmar Contraseña</Form.Label>
            <Form.Control className="form-field mb-2" size="sm" type="password" {...register("confirmPass", {          
                onBlur: (e) => {                                        
                    if (watch('password') != e.target.value) {setERR(true)} else { setERR(false)}
                },        
                })}/> 
                { err ? <Row>
                    <p className="red">{confirmError}</p>
                </Row> : null}
            <Row className="mt-3 justify-center"><Button className="App-button" type="submit">Reestablecer</Button></Row>
            </Form>
        </div>
    </div>
}