import { Form, Button, Modal, Toast, ToastContainer, Row, Col, FormGroup, Offcanvas} from "react-bootstrap";
import ClientContext from "../../../Context/ClientContext"
import UserContext from "../../../Context/UserContext";
import { useContext, useState, useEffect, useReducer} from "react"
import { BarLoader } from "react-spinners";
import { useForm } from "react-hook-form";
import axios from "axios"
import { FaRunning, FaPeopleCarry, FaHandsHelping, FaUnlock, FaRegCheckSquare, FaRegStar, FaRegThumbsUp, FaRegThumbsDown, FaCloudUploadAlt, FaPlus, FaStar, FaTrash, FaRegCalendarAlt, FaEdit, FaCogs, FaShoppingBag, FaGlobeAmericas, FaAddressBook, FaGraduationCap, FaThumbsUp, FaLock  } from "react-icons/fa";
import { FaPeopleGroup, FaRegFaceAngry, FaRegFaceFrown,FaRegFaceMeh, FaRegFaceSmile, FaRegFaceSmileBeam, FaWandMagic } from "react-icons/fa6"
import { FiType, FiAtSign, FiBook } from "react-icons/fi";
import { SiSpeedtest } from "react-icons/si";
import { BiSelectMultiple } from "react-icons/bi";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './Survey.css'

export default function EncuestaSurvey() {

    const Image_URL = 'https://api.medicionanalytic.com/medicionapi/storage/app/public'
    const API_URL = 'https://api.medicionanalytic.com/api'
    const [loading, setLoad] = useState(false)    
    const [_, forceUpdate] = useReducer(x => x + 1, 0);

    const [start, setStart] = useState(false)
    const [showPreg, setShowPreg] = useState(false)
    const [useST, setStructure] = useState(false)
    const [NP, setNP] = useState(false)

    const [currEnc, setCurEnc] = useState({id:0})
    const [currSec, setCurSec] = useState([{nombre_seccion:""}])
    const [currPreg, setCurPre] = useState([])
    const [endText, setET] = useState(" Gracias por participar en la encuesta !")

    const { logData } = useContext(ClientContext);
    const { userData } = useContext(UserContext);
    const [paso, setPaso] = useState(0)     
    const [editMessage, setM] = useState(false)

    const [periodos, setPeriodos] = useState([])    
    const [Addsec, setAddsec] = useState(1)

    const [toast, setToast] = useState({ show: false, variant: "", message: "" });

    const {
        register,
        handleSubmit,
        reset,
        getValues,
        setValue,        
        formState: { isDirty, isValid },
    } = useForm({ mode: "onBlur" });

    const ClosePreg = () => {setShowPreg(false);reset()}
    const ShowPreg = () => {setShowPreg(true);} 
    const handleNP = () => {
        setNP(true);
        reset({
            nombre_periodo : "",
            nombre_corto : "",
            periodo_inicio : null,
            periodo_fin : null,
            validez : "cerrado",
        });
    }
    const closeNP = () => {
        setNP(false);        
    }

    useEffect(()=>{
        axios.get(API_URL + '/periodo', { params: { client_id:logData.id } }).then(
            function(response) {
                response.data.forEach(p => {
                    p.label = p.nombre_periodo;
                    p.value = p.id;
                })
                setPeriodos(response.data)
            })       
    },[toast])    

    function Toaster(variant, message) {setToast({ show: true, variant: variant, message: message });}
    
    const setHeaders = () => {
        let token = window.localStorage.getItem("sessionKey");
        const headers = {
                Authorization: `Bearer ${token}`,
                Accept: "application/json",
            };
        return headers;}

    const addSection = () => {
        if(userData.subscription !== "trial") {            
            let arrai = currSec        
            arrai.push({number:currSec.length+1, nombre_seccion:""})
            setCurSec(arrai)
            forceUpdate()
        }
        else return
    }
    const finalData = () => {
        let headers = setHeaders()
        let valyes = getValues()
        valyes.client_id = logData.id
        valyes.subscription = userData.subscription
        if(currSec.length <= 1) {currSec[0].nombre_seccion = valyes.nombre_encuesta}
        valyes.estado = "activo"                        
        setLoad(true)
        if(valyes.periodo_encuesta !== null && valyes.periodo_encuesta !== undefined ) {CreateEncuesta(valyes).then(res => {forceUpdate()})}
        else { axios.post(API_URL + '/periodo-q', valyes, {headers}).then(response => {                
                valyes.periodo_encuesta = response.data;                
                CreateEncuesta(valyes).then(res => {forceUpdate()})
            })}
    }

    const CreateEncuesta = async (data) => {                
        let jointContent = {}
        let headers = setHeaders()
        jointContent.periodo_id = data.periodo_encuesta   
        data.mensaje_final = endText;     
        axios.post(API_URL + '/encuesta', data).then(response => {
                jointContent.encuesta_id = response.data.ID
                jointContent.client_id = logData.id                
                setCurEnc({id:response.data.ID, periodo_encuesta: data.periodo_encuesta})
                axios.post(API_URL + '/joint', jointContent).then(restt => {                        
                        currSec.forEach((sec,indec) => {
                            sec.encuesta_id = response.data.ID
                            axios.post(API_URL+'/seccion-q', sec, {headers}).then(res => {
                                currPreg.filter(p => p.seccion_number == indec).forEach(p =>{
                                    p.options = JSON.stringify(p.options)
                                    p.seccion_id = res.data
                                    p.encuesta_id = response.data.ID
                                    axios.post(API_URL + '/pregunta', p).then(res => {
                                        setM(true)
                                        setTimeout(()=>{setLoad(false)},3000)
                                        Toaster("success", "Encuesta Creada con exito, puede empezar a llenarla compartiendo el link de arriba. ")
                                    })
                                })
                            })
                        })
                    }).catch(err => {Toaster("danger", "Suscribete para más")})
            }).catch(err => {Toaster("danger", "Suscribete para más"); setLoad(false)})
        
    }

    function StepDisplay(paso) {
        switch(paso) {
            case 0: return<>
            <Row className="mb-5 hidden-sm">
                <Col>
                    <p className="form-number">
                        <h5 className="m-2 active-step" onClick={() => setPaso(0)}><FaEdit className="m-2"/>Editar encuesta</h5>
                        <h5 className="rest-step m-2" onClick={() => {return getValues("nombre_encuesta").length > 0 && currPreg.length > 0 ? setPaso(1): null}}><FaWandMagic className="m-2" />Opciones de finalización</h5>
                        <h5 className="rest-step m-2" onClick={() => {return getValues("nombre_encuesta").length > 0 && currPreg.length > 0 ? setPaso(2): null}}><FaCogs className="m-2"/>Configuraciones</h5>
                    </p>
                </Col>                  
                <Col className="flex-end">
                    <Form.Control size="sm" className={currEnc.id ? "active-step-enc": ""} readOnly value={currEnc.id ? 'https://app.Medicionanalytic.com/encuesta/'+currEnc.periodo_encuesta+"/"+currEnc.id : ""}/>
                </Col>
            </Row>
            <div className="control-div">
                <Row className="mb-3 collumn">
                    <div className="header-logo m-2 square h-1 v-2" style={{backgroundImage:'url("'+Image_URL+logData.logo+'")'}} />                    
                    {/*<Button className="App-button-alt mb-3 control-flex">Añadir logotipo</Button>*/}
                </Row>
                <Row className="mb-4 form-width">                                        
                    <Form.Control className="style-form-field" size="lg" placeholder="Nombre de la encuesta" {...register("nombre_encuesta",{ required: true })}/>                                                            
                </Row>
                <Row className="mb-4">
                    {currPreg.length <= 0 || currSec.length > 1 ? <h5>Inicia tu encuesta añadiendo preguntas</h5> : null}
                </Row>            
                <Row className="form-width collumn">
                    {currSec.map((sec, indec) => {return <>
                        {currSec.length <= 1 ? null:
                        <p className="form-number"><b>{indec+1+"."}</b>
                        <Form.Control className="mb-4 style-form-field" placeholder="Nombre de la Seccion" onChange={(e) => currSec[indec].nombre_seccion = e.target.value} onBlur={() => setCurSec(currSec)}
                        /><FaTrash className="pointer" onClick={() => removeSecc(indec)}/></p>} 
                        {currPreg.filter(p => {return p.seccion_number === indec}).map((preg, index) => {return <Row className="mb-4"> {preguntaSwitch(preg, index, indec)} </Row> })}
                        {currPreg.length > 0 ?<span className="plus-button mb-2" onClick={() => {setAddsec(indec);ShowPreg()}}><FaPlus /></span>
                    :<Button className="mb-2 min-App-button" onClick={() => {setAddsec(indec);ShowPreg()}}>Añadir pregunta</Button>}
                    </>                        
                    })}
                    {/*userData.subscription === "trial" ? <Button className="mb-2 min-App-button-dis" onClick={() => addSection()}>Añadir Sección <FaUnlock /></Button> : <Button className="mb-2 min-App-button" onClick={() => addSection()}>Añadir Sección</Button> */}
                </Row>

                <Row className="form-footer mt-4">                                        
                    <Button className="mt-0 m-2 min-App-button" disabled={ currPreg.length > 0 ? false : true} onClick={() => setPaso(paso+1)}>Siguiente</Button>
                    <Button className="mb-2 min-App-button" disabled={ currPreg.length > 0 ? false : true} onClick={() => setPaso(paso+2)}>Finalizar</Button>
                </Row>
                <Row><h6 className="clickable">Contacta con Medicion Analytic</h6></Row>                
            </div>  
            </>
            case 1:return<> 
            <Row className="mb-5 hidden-sm">
                <Col>
                    <p className="form-number">
                        <h5 className="rest-step m-2 " onClick={() => setPaso(0)}><FaEdit className="m-2"/>Editar encuesta</h5>
                        <h5 className="active-step m-2" onClick={() => {return currPreg.length > 0 ? setPaso(1): null}}><FaWandMagic className="m-2" />Opciones de finalización</h5>
                        <h5 className="rest-step m-2" onClick={() => {return currPreg.length > 0 ? setPaso(2): null}}><FaCogs className="m-2"/>Configuraciones</h5>
                    </p>
                </Col>                  
                <Col className="flex-end">
                    <Form.Control size="sm" readOnly className={currEnc.id ? "active-step-enc": ""} value={currEnc.id ? 'https://app.Medicionanalytic.com/encuesta/'+currEnc.periodo_encuesta+"/"+currEnc.id : ""}/>
                </Col>
            </Row>
            <div className="control-div">
                 <p className="form-number">
                    <h4 className="text-left m-2 mt-0 mb-4">Mensaje de agradecimiento final</h4>
                    <Form.Check defaultChecked={true} {...register("habilitar_mensaje")}/>
                </p>
                <Row className="form-width">                    
                        <ReactQuill  className="sub-banner mb-10" theme="snow" value={endText} onChange={setET} />                                             
                        <p className="form-number">
                            <Form.Label className="text-left m-2 mt-0 mb-0">URL de redireccion</Form.Label>
                            <Form.Check {...register("redireccion")}/>
                        </p>
                        <Form.Control className="style-form-field mb-10 short" placeholder="" {...register("link")}/>                    
                </Row>
                <Row className="form-footer mt-4">                    
                    <Button className="mt-0 m-2 left min-App-button" onClick={() => setPaso(paso-1)}>Atras</Button>
                    <Button className="mt-0 m-2 min-App-button" onClick={() => setPaso(paso+1)}>Siguiente</Button>
                    <Button className="mb-2 min-App-button" onClick={() => setPaso(paso+1)}>Finalizar</Button>
                </Row>
                <Row><h6 className="clickable">Contacta con Medicion Analytic</h6></Row>
            </div>  
            </>
            case 2:return<> 
            <Row className="mb-5 hidden-sm">
                <Col>
                    <p className="form-number">
                        <h5 className="rest-step m-2 " onClick={() => setPaso(0)}><FaEdit className="m-2"/>Editar encuesta</h5>
                        <h5 className="rest-step m-2" onClick={() => {return currPreg.length > 0 ? setPaso(1): null}}><FaWandMagic className="m-2" />Opciones de finalización</h5>
                        <h5 className="active-step m-2" onClick={() => {return currPreg.length > 0 ? setPaso(2): null}}><FaCogs className="m-2" />Configuraciones</h5>
                    </p>
                </Col>                  
                <Col className="flex-end">
                    <Form.Control size="sm" readOnly className={currEnc.id ? "active-step-enc": ""} value={currEnc.id ? 'https://app.Medicionanalytic.com/encuesta/'+currEnc.periodo_encuesta+"/"+currEnc.id : ""}/>
                </Col>
            </Row>            
            <div className="control-div"> 
                <Row className="form-width mb-4">
                </Row>
                <h4 className="form-width text-left">Periodo</h4>
                {NP ? <Row className="form-width mb-4">         {/*nuevo periodo*/}   
                        <FormGroup>
                            <p className="form-number mb-2">
                            <Form.Control className="style-form-field" size="lg" placeholder="Nombre del periodo" {...register("nombre_periodo")}/>                                                            
                            </p>
                            <h6 className="text-left mt-3">Nombre Corto</h6>
                            <Form.Control className="style-form-field short" size="sm" placeholder="Nmbr" {...register("nombre_corto")} />                        
                            <Row className="mt-3">
                                <Col>
                                    <h6 className="text-left">Fecha de inicio</h6>
                                    <Form.Control className="form-option" type="date" {...register("periodo_inicio")} />
                                </Col>
                                <Col>                            
                                <h6 className="text-left">Fecha final</h6>
                                <Form.Control className="form-option" type="date" {...register("periodo_fin")} />
                                </Col>
                            </Row>
                            <h6 className="text-left mt-3">Rango</h6>
                            <Form.Select {...register("validez")} className="form-option-slim">
                                <option value="cerrado">Rango Cerrado</option>
                                <option value="flexible">Rango Flexible</option>
                            </Form.Select>
                        </FormGroup>
                        <Button className="App-button m-4" onClick={closeNP}>Seleccionar periodo existente</Button>
                    </Row> :
                <Row className="form-width mb-4"> 
                    <FormGroup>
                        <h6 className="text-left">Seleccionar periodo o crear uno nuevo</h6>
                        <Form.Select onChange={(e) => {setValue("periodo_encuesta", e.target.value)}}>
                            <option value={null}>...</option>
                            {periodos.map(p => {return <option value={p.id}>{p.nombre_periodo}</option>})}
                        </Form.Select>
                    </FormGroup>
                    <Row>
                        <Button className="App-button m-3" onClick={handleNP}>Nuevo Periodo</Button>
                    </Row>
                </Row>}
                <h4 className="form-width text-left">Encuesta</h4>
                    <Row className="form-width mb-4"> 
                        <Form.Group>
                            <h6 className="text-left">tipo de encuesta</h6>
                            <Form.Select defaultValue={"encuesta"} {...register("tipo_encuesta")}>                                
                                {logData && logData.servicio_encuesta === "activo" ? <option value="encuesta">encuesta</option>:null}
                                {logData && logData.servicio_audit === "activo" ? <option value="audit">auditoria</option>:null}
                                {logData && logData.servicio_mystery === "activo" ? <option value="mystery">mystery</option>:null }
                                {logData && logData.servicio_shop === "activo"? <option value="precios">precios</option>:null}
                                {logData && logData.servicio_desempeno === "activo"? <option value="desempeno">Desempeño Laboral</option>:null}
                                {logData && logData.servicio_climaLaboral === "activo"? <option value="clima">Clima Laboral</option>:null}
                            </Form.Select>
                        </Form.Group>
                    </Row>
                <Row className="form-footer mt-4">                    
                    <Button className="mt-0 m-2 left min-App-button" onClick={() => setPaso(paso-1)}>Atras</Button>                                        
                    {!loading ? <Button className="mb-2 min-App-button" disabled={!isValid} onClick={() => finalData()}>Guardar</Button> : <BarLoader className="m-2" color="#4538D1" width={50} />}
                </Row>                                
                <Row><h6 className="clickable">Contacta con Medicion Analytic</h6></Row>
                {editMessage ? <h6 className="p-3" style={{color:"green"}}>Puede empezar a editar o modificar las encuestas en el menú de la izquierda</h6>:null}
            </div>  
            </> 
            }        
        }

    function preguntaSwitch(preg, index, sec) {        
        switch(preg.tipo_pregunta) {
            case "texto": return <FormGroup>
                    <p className="form-number">{index+1+"."}
                    <Form.Control className="style-form-field mb-2" defaultValue={""} placeholder="Escribe aqui la pregunta..." onChange={(e) => {currPreg.filter(cp => cp.seccion_number === sec)[index].titulo = e.target.value}} onBlur={() => {setCurPre(currPreg)}}/>
                    <FaTrash className="pointer" onClick={() => removePreg(preg.id)}/>
                    </p>
                    <Form.Control readOnly placeholder="texto de la respuesta" />
                </FormGroup>
            case "comentario": return <FormGroup>
                    <p className="form-number">{index+1+"."}
                    <Form.Control className="style-form-field mb-2" defaultValue={""} placeholder="Escribe aqui la pregunta..." onChange={(e) => {currPreg.filter(cp => cp.seccion_number === sec)[index].titulo = e.target.value}} onBlur={() => setCurPre(currPreg)}/>                                                                       
                    <FaTrash className="pointer" onClick={() => removePreg(preg.id)}/>
                    </p>
                    <textarea className="form-control" placeholder="Texto del comentario" readOnly rows={4}/>
                </FormGroup>
            case "mail": return <FormGroup>
                    <p className="form-number">{index+1+"."}
                    <Form.Control className="style-form-field mb-2" defaultValue={""} placeholder="Escribe aqui la pregunta..." onChange={(e) => {currPreg.filter(cp => cp.seccion_number === sec)[index].titulo = e.target.value}} onBlur={() => setCurPre(currPreg)}/>
                    <FaTrash className="pointer" onClick={() => removePreg(preg.id)}/>
                    </p>
                    <Form.Control readOnly placeholder="Email de respuesta" />
                </FormGroup>
            case "info": return <FormGroup>
            <p className="form-number">{index+1+"."}
                    <Form.Control className="style-form-field mb-2" defaultValue={""} placeholder="Escribe aqui la pregunta..." onChange={(e) => {currPreg.filter(cp => cp.seccion_number === sec)[index].titulo = e.target.value}} onBlur={() => setCurPre(currPreg)}/>
                    <FaTrash className="pointer" onClick={() => removePreg(preg.id)}/>
                    </p>
                    <Form.Control className="mb-1" readOnly placeholder="Nombre" />                    
                    <Form.Control className="mb-1" readOnly placeholder="Telefono" />                    
                </FormGroup>
            case "stars": return <FormGroup>
                    <p className="form-number">{index+1+"."}
                    <Form.Control className="style-form-field mb-2" defaultValue={""} placeholder="Escribe aqui la pregunta..." onChange={(e) => {currPreg.filter(cp => cp.seccion_number === sec)[index].titulo = e.target.value}} onBlur={() => setCurPre(currPreg)}/>
                    <FaTrash className="pointer" onClick={() => removePreg(preg.id)}/>
                    </p>
                    <p className="flex">
                        <div className="form-option m-1"><FaStar className="gold"/></div>
                        <div className="form-option m-1"><FaStar className="gold"/></div>
                        <div className="form-option m-1"><FaStar className="gold"/></div>
                        <div className="form-option m-1"><FaStar className="gold"/></div>
                        <div className="form-option m-1"><FaStar className="gold"/></div>
                    </p>
                </FormGroup>
            case "smiles": return <FormGroup>
            <p className="form-number">{index+1+"."}
            <Form.Control className="style-form-field mb-2" defaultValue={""} placeholder="Escribe aqui la pregunta..." onChange={(e) => {currPreg.filter(cp => cp.seccion_number === sec)[index].titulo = e.target.value}} onBlur={() => setCurPre(currPreg)}/>
            <FaTrash className="pointer" onClick={() => removePreg(preg.id)}/>
            </p>
            <p className="flex">
                <div className="form-option m-1"><FaRegFaceAngry className="red"/></div>
                <div className="form-option m-1"><FaRegFaceFrown className="orange"/></div>
                <div className="form-option m-1"><FaRegFaceMeh className="yellow"/></div>
                <div className="form-option m-1"><FaRegFaceSmile className="green"/></div>
                <div className="form-option m-1"><FaRegFaceSmileBeam className="deepgreen"/></div>
            </p>
        </FormGroup>
            case "thumbs": return <FormGroup>
            <p className="form-number">{index+1+"."}
            <Form.Control className="style-form-field mb-2" defaultValue={""} placeholder="Escribe aqui la pregunta..." onChange={(e) => {currPreg.filter(cp => cp.seccion_number === sec)[index].titulo = e.target.value}} onBlur={() => setCurPre(currPreg)}/>
            <FaTrash className="pointer" onClick={() => removePreg(preg.id)}/>
            </p>
            <p className="flex">
                <div className="form-option m-1"><FaRegThumbsUp/></div>
                <div className="form-option m-1"><FaRegThumbsDown/></div>                
            </p>
        </FormGroup>
            case "load": return <FormGroup>
            <p className="form-number">{index+1+"."}
                <Form.Control className="style-form-field mb-2" defaultValue={""} placeholder="Escribe aqui la pregunta..." onChange={(e) => {currPreg.filter(cp => cp.seccion_number === sec)[index].titulo = e.target.value}} onBlur={() => setCurPre(currPreg)}/>
                <FaTrash className="pointer" onClick={() => removePreg(preg.id)}/>
                </p>
                <Form.Control readOnly type="file" accept="doc" placeholder="Email de respuesta" />
            </FormGroup>
            case "select": return <FormGroup>
                <p className="form-number">{index+1+"."}
                    <Form.Control className="style-form-field mb-2" defaultValue={""} placeholder="Escribe aqui la pregunta..." onChange={(e) => {currPreg.filter(cp => cp.seccion_number === sec)[index].titulo = e.target.value}} onBlur={() => setCurPre(currPreg)}/>
                    <FaTrash className="pointer" onClick={() => removePreg(preg.id)}/>
                    </p>
                    {preg.options.map((opt, indes) => {return <p className="flex form-number option-container">
                        <Form.Control className="m-1 mt-2 select-option" size="sm" placeholder="Opcion..." onChange={(e) => preg.options[indes].opt = e.target.value}/>                                                                       
                        <FaTrash className="pointer pt-1" onClick={() => { preg.options.splice(indes, 1);forceUpdate()}}/>
                    </p>})}
                <p>
                    <span className="plus-button-alt" onClick={() => { preg.options.push({opt: ""});forceUpdate()}}> <FaPlus /></span>
                </p>
                </FormGroup>
            case "selectm": return <FormGroup>
            <p className="form-number">{index+1+"."}
                <Form.Control className="style-form-field mb-2" defaultValue={""} placeholder="Escribe aqui la pregunta..." onChange={(e) => {currPreg.filter(cp => cp.seccion_number === sec)[index].titulo = e.target.value}} onBlur={() => setCurPre(currPreg)}/>
                <FaTrash className="pointer" onClick={() => removePreg(index)}/>
                </p>
                {preg.options.map((opt, indes) => {return <p className="flex form-number option-container">
                    <Form.Control className="m-1 mt-2 select-option" size="sm" placeholder="Opcion..." onChange={(e) => preg.options[indes].opt = e.target.value}/>                                                                       
                    <FaTrash className="pointer pt-1" onClick={() => { preg.options.splice(indes, 1);forceUpdate()}}/>
                </p>})}
            <p>
                <span className="plus-button-alt" onClick={() => { preg.options.push({opt: ""});forceUpdate()}}> <FaPlus /></span>
            </p>
            </FormGroup>
            case "nps": return <FormGroup>
                <p className="form-number">{index+1+"."}
            <Form.Control className="style-form-field mb-2" defaultValue={""} placeholder="Escribe aqui la pregunta..." onChange={(e) => {currPreg.filter(cp => cp.seccion_number === sec)[index].titulo = e.target.value}} onBlur={() => setCurPre(currPreg)}/>       
            <FaTrash className="pointer" onClick={() => removePreg(preg.id)}/>
            </p>
            <p className="flex">
                <div className="form-option m-1">1</div>
                <div className="form-option m-1">2</div>
                <div className="form-option m-1">3</div>
                <div className="form-option m-1">4</div>
                <div className="form-option m-1">5</div>
                <div className="form-option m-1">6</div>
                <div className="form-option m-1">7</div>
                <div className="form-option m-1">8</div>
                <div className="form-option m-1">9</div>
                <div className="form-option m-1">10</div>
            </p>
            </FormGroup>
            case "fecha": return <FormGroup>
                    <p className="form-number">{index+1+"."}
            <Form.Control className="style-form-field mb-2" defaultValue={currPreg[index].titulo} placeholder="Escribe aqui la pregunta..." onChange={(e) => {currPreg.filter(cp => cp.seccion_number === sec)[index].titulo = e.target.value}} onBlur={() => setCurPre(currPreg)}/>
            <FaTrash className="pointer" onClick={() => removePreg(preg.id)}/></p>
            <p className="flex">
            <Form.Control className="form-option" type="date" />
            </p>
            </FormGroup>
        }
    }

    const agregarPregunta = (type) => {                
        let data = {}
        //data.id = currPreg.length+1
        data.seccion_number = Addsec    
        data.aplica = true;
        data.peso = false;        
        data.valor_min = 1;
        data.valor_max = 5;
        data.options = []
        data.pausa = false
        data.titulo = ""
        data.segmento = ""
        data.tipo_pregunta = type
        let temp = currPreg
        temp.push(data)                      
        setCurPre(temp)        
        ClosePreg()
    }

    const agregarPreguntaPlan = (type,titulo) => {        
        let data = {}
        //data.id = currPreg.length+1
        data.seccion_number = Addsec
        data.aplica = true;
        data.peso = false;        
        data.valor_min = 1;
        data.valor_max = 5;
        data.options = []
        data.pausa = false
        data.titulo = titulo
        data.segmento = ""
        data.tipo_pregunta = type
        let temp = currPreg
        temp.push(data)              
        setCurPre(temp)
    }

    const removePreg = (id) => {        
        currPreg.splice(currPreg.findIndex(p =>p.id === id), 1);            
        forceUpdate()
    }
    const removeSecc = (index) => {
        currSec.splice(index, 1);
        forceUpdate()
    }
    const handleSide = () => {
        if(logData.id > 0) {
            setStructure(!useST)
        }
     }
     const setPlantilla = (tipo) => {        
        switch(tipo){
            case "productos": {setValue("nombre_encuesta","Evaluacion de productos");
                agregarPreguntaPlan("stars","Calidad del producto");agregarPreguntaPlan("comentario","Dejanos saber tu opinion")} 
                break
            case "satisfaccion": {setValue("nombre_encuesta","Satisfaccion del cliente");
                agregarPreguntaPlan("smiles", "Que tal le parecio el servicio?");agregarPreguntaPlan("comentario","Dejanos saber tu opinion")}
                break
            case "web": {setValue("nombre_encuesta","Opinion sobre sitios web");
                agregarPreguntaPlan("comentario", "Dejanos tus opiniones");agregarPreguntaPlan("info", "Como te podemos contactar?");}
                break
            case "contact": {setValue("nombre_encuesta", "Informacion de contacto");
                agregarPreguntaPlan("info", "Mantengamonos en contacto");}
                break
            case "comentarios": {setValue("nombre_encuesta", "Comentarios sobre reuniones");
                agregarPreguntaPlan("comentario", "Danos tu opinion");agregarPreguntaPlan("fecha", "Cuando fue la reunion?")}
                break
            case "cursos": {setValue("nombre_encuesta", "Evaluacion de cursos");
                agregarPreguntaPlan("stars", "Que le parecio el curso?");agregarPreguntaPlan("thumbs", "Recomendaria el curso a un amigo?");
                agregarPreguntaPlan("comentario", "En su opinion, como podria mejorar el curso?");}
                break
            case "recomendacion": {setValue("nombre_encuesta","Grado de recomendacion");
                agregarPreguntaPlan("nps", "Que tan probable seria que recomiende el servicio a sus amigos?");
                agregarPreguntaPlan("comentario", "En su opinion, como podriamos mejorar?");}
                break
            }

     }
    return(<>
    <ToastContainer style={{position:"fixed",top:"80vh", right:"0vw"}} className="p-3">
        <Toast
          bg={toast.variant}
          onClose={() => setToast({ show: false, variant: "", message: "" })}
          show={toast.show}
          delay={4000}
          autohide>
          <Toast.Header>
            <strong className="me-auto">Medicion</strong>
            <small>just now</small>
          </Toast.Header>
          <Toast.Body className="text-white">{toast.message}</Toast.Body>
        </Toast>
    </ToastContainer>
    <Modal show={showPreg} size="lg" onHide={ClosePreg}>
        <Modal.Header closeButton>
          <Modal.Title>Seleccione el Tipo de pregunta que desea insertar</Modal.Title>
        </Modal.Header>
        <Form>
            <Modal.Body>                
                <Row>
                    <Col>
                     <div className="control-text mb-3">
                        <h4><b>Texto</b></h4>
                        <h6 onClick={() => agregarPregunta("comentario")}><FiType /> comentario</h6>
                        <h6 onClick={() => agregarPregunta("texto")}><FiType /> texto</h6>
                        <h6 onClick={() => agregarPregunta("texto")}><FiAtSign /> email</h6>
                        <h6 onClick={() => agregarPregunta("info")}><FiBook /> información</h6>
                     </div>
                    </Col>
                    <Col>
                    <div className="control-text mb-3">
                        <h4><b>Selección</b></h4>
                        <h6 onClick={() => agregarPregunta("select")}><FaRegCheckSquare/> Seleccion simple</h6>
                        {/*<h6 onClick={() => agregarPregunta("selectm")}><BiSelectMultiple/> Seleccion multiple</h6>*/}
                        <h6 className="disabled-option"><BiSelectMultiple/> Seleccion multiple <FaLock/></h6>
                     </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                    <div className="control-text mb-3">
                        <h4><b>Calificación y satisfacción</b></h4>
                        <h6 onClick={() => agregarPregunta("stars")}><FaRegStar/> Puntuación por estrellas</h6>
                        <h6 onClick={() => agregarPregunta("smiles")}><FaRegFaceSmile/> Puntuación - felicidad</h6>
                        <h6 onClick={() => agregarPregunta("thumbs")}><FaRegThumbsUp/> Pulgar arriba/abajo</h6>                        
                        <h6 onClick={() => agregarPregunta("nps")}><SiSpeedtest/> NPS</h6>                        
                     </div>
                    </Col>
                    <Col>
                        <Row className="collumn">
                            <Col>
                            <div className="control-text mb-3">
                                <h4><b>Cargar Archivo</b></h4>
                                {/*<h6 onClick={() => agregarPregunta("load")} ><FaCloudUploadAlt/> Adjuntar/Cargar Archivo </h6>*/}
                                <h6 className="disabled-option"><FaCloudUploadAlt/> Adjuntar/Cargar Archivo <FaLock/> </h6>
                            </div>
                            </Col>
                            <Col>
                            <div className="control-text mb-3">
                                <h4><b>Otros</b></h4>
                                {/*<h6 onClick={() => agregarPregunta("fecha")}><FaRegCalendarAlt /> Fecha</h6> */ }
                                <h6 className="disabled-option"><FaRegCalendarAlt /> Fecha <FaLock /></h6>
                            </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Modal.Body>            
        </Form>
    </Modal>
    {start === false ? <div className="Overlay">
        <div className="mt-15 mb-10">
            <Row>
                <h2 style={{color:"blue"}}><b>Bienvenido a Medición Analityc</b></h2>
            </Row>
            <Row><h5>La plataforma de medición de encuestas que necesitas</h5></Row>
        </div>
        <Row className="mb-3"><p><h6><FaRunning className="big-icon m-4 mt-0 mb-0"/><b>Empezar es muy fácil.</b> Cree su primera encuesta ahora <Button className=" m-3 mt-0 mb-0" onClick={() => setStart(true)}>Crear Encuesta</Button></h6></p></Row>
        <Row className="mb-3"><p><h6><FaHandsHelping className="big-icon m-4 mt-0 mb-0"/><b>¿Necesitas ayuda para empezar? </b>Prueba una plantilla Profesional <Button className=" m-3 mt-0 mb-0" onClick={() => {setStart(true);setStructure(true)}}>Usar Plantilla</Button> </h6></p></Row>
        <Row className="mb-3"><p><h6><FaPeopleCarry className="big-icon m-4 mt-0 mb-0"/><b>¿Necesita funciones avanzadas?</b> Contacta con nosotros <Button className=" m-3 mt-0 mb-0">Contacto</Button> </h6></p></Row>
     </div>:null}
        {StepDisplay(paso)}
        <Offcanvas show={useST} onHide={handleSide} placement="end">
        <Offcanvas.Body>        
        <Row>
                    <Col>
                     <div className="control-text mb-3">
                        <h4><b>Plantillas</b></h4>
                        <h6 className="nowrap" onClick={() => {setPlantilla("productos");setStructure(false)}}><FaShoppingBag /> Evaluacion de productos</h6>
                        <h6 className="nowrap" onClick={() => {setPlantilla("satisfaccion");setStructure(false)}}><FaRegFaceSmile /> Satisfaccion del cliente</h6>
                        <h6 className="nowrap" onClick={() => {setPlantilla("web");setStructure(false)}}><FaGlobeAmericas /> Opiniones sobre sitios web</h6>
                        <h6 className="nowrap" onClick={() => {setPlantilla("contact");setStructure(false)}}><FaAddressBook /> Informacion de contacto</h6>
                        <h6 className="nowrap" onClick={() => {setPlantilla("comentarios");setStructure(false)}}><FaPeopleGroup /> Comentarios sobre reuniones</h6>
                        <h6 className="nowrap" onClick={() => {setPlantilla("cursos");setStructure(false)}}><FaGraduationCap /> Evaluacion de cursos</h6>
                        <h6 className="nowrap" onClick={() => {setPlantilla("recomendacion");setStructure(false)}}><FaThumbsUp /> Grado de recomendación</h6>
                     </div>
                    </Col>                    
                </Row>                
        </Offcanvas.Body>
      </Offcanvas>
    </>)
}