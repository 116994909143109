const templates = [
    //valores de texto.
    {tipo_pregunta:"texto", pregunta:"pregunta abierta", trial: "yes"},    
    {tipo_pregunta:"info", pregunta:"informacion", trial: "yes"},
    {tipo_pregunta:"comentario", pregunta:"comentarios", trial:"yes"},        
    //{tipo_pregunta:"load", pregunta:"carga de archivos"},
    //{tipo_pregunta:"fecha", pregunta:"fecha", trial: "yes"},
    //valores numericos abajo
    {tipo_pregunta:"thumbs", pregunta:"si/no", trial: "yes"},
    {tipo_pregunta:"audit", pregunta:"cumple/no cumple/NA", trial: "yes"},
    {tipo_pregunta:"escala", pregunta:"escala", trial: "yes"},
    {tipo_pregunta:"escala-tri", pregunta:"malo/regular/excelente", trial: "yes"},
    {tipo_pregunta: "stars", pregunta:"estrellas", trial: "yes"},
    {tipo_pregunta:"smiles", pregunta:"felicidad", trial: "yes"},
    {tipo_pregunta:"nps", pregunta:"nps"},
    {tipo_pregunta:"npsna", pregunta:"npsna"},
    // valores de seleccion ?
    {tipo_pregunta:"select", pregunta:"seleccion simple", trial: "yes",options: []},
    {tipo_pregunta:"selectm", pregunta:"seleccion multiple", trial: "yes",options: []},
]         

export default templates