import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import ClientContext from "./ClientContext";
import UserContext from "./UserContext"; 

const ParametroContext = React.createContext({});

export function ParametroContextProvider({ children }) {
  const API_URL = 'https://api.medicionanalytic.com/api'
  const [paramData, setParams] = useState({graph:[0,500,750,1000], values:{rojo:0,amarillo:50, verde:100}});
  const {logData} = useContext(ClientContext);
  const {userData} = useContext(UserContext);

  useEffect(() => {
    if(logData.id > 0)
    { 
      if(userData.permiso === 'admin') { 
      axios.get(API_URL + '/parametro', {params:{client_id:logData.id}}).then(res => {        
        if(res.data.length > 0){          
          let temp = {graph:[0,(res.data[0].rojo*10), (res.data[0].amarillo*10), 1000],
            values: {rojo:res.data[0].rojo, amarillo:res.data[0].amarillo, verde: res.data[0].verde}}            
          setParams(temp)}
        })
     } 
    else {
      axios.get(API_URL + '/parametro', {params:{client_id:userData.cliente_id}}).then(res => {                
        if(res.data.length > 0){
          let temp = {graph:[0,(res.data[0].rojo*10), (res.data[0].amarillo*10), 1000],
            values: {rojo:res.data[0].rojo, amarillo:res.data[0].amarillo, verde: res.data[0].verde}}
          setParams(temp)}
        })
    }}
  },[logData, userData])
  
  return (
    <ParametroContext.Provider value={{ paramData, setParams}}>
      {children}
    </ParametroContext.Provider>
  );
}
export default ParametroContext;