import { useState, useEffect, useContext } from "react";
import { Form, Table, Modal, Button, Toast, ToastContainer, Row, Col } from "react-bootstrap"
import { useForm } from "react-hook-form"
import { BiEditAlt, BiTrash } from "react-icons/bi";
import axios from "axios";
import { BarLoader } from "react-spinners";
import ClientContext from "../../../Context/ClientContext";
import { useNavigate } from "react-router-dom";

export default function Secciones() {
    const [loadBuffer, setBuffer] = useState(false)
    const API_URL = 'https://api.medicionanalytic.com/api'
    const newLocation = useNavigate()
    const { logData } = useContext(ClientContext)
    const [encuestas, setEncuestas] = useState([]);
    const [secciones, setSecciones] = useState([])
    const [sumPesos, setSum] = useState(0);
    const [selectedEncuesta, setSelection] = useState();
    const [show, setShow] = useState(false);
    const [viewElim, setElim] = useState(false);
    const [showEdit, setEditShow] = useState(false);
    const [toast, setToast] = useState({ show: false, variant: "", message: "" });
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({ mode: "onBlur" });

    function Toaster(variant, message) { setToast({ show: true, variant: variant, message: message }); }

    useEffect(() => {
        axios.get(API_URL + '/encuesta/all', { params: { client_id: logData.id } }).then(
            function (response) {                            
                if(typeof response.data === 'object') { let temp = Object.keys(response.data).map((key) => response.data[key]); setEncuestas(temp)    }
                else { setEncuestas(response.data) }            
            })
    }, [toast])

    const handleClose = () => { setShow(false); updateEL(); }
    const handleShow = () => setShow(true);
    const showEliminar = (seccion) => {setElim(true);reset({id:seccion.id})}
    const hideEliminar = () => {setElim(false); updateEL()}
    const handleEditClose = () => { setEditShow(false); updateEL(); }
    const handleEditShow = (seccion) => {
        reset({
            id: seccion.id,
            nombre_seccion: seccion.nombre_seccion,
            descripcion: seccion.descripcion,
            peso: seccion.peso,
            estado: seccion.estado,
        })
        setEditShow(true)
    }

    const setHeaders = () => {
        let token = window.localStorage.getItem("sessionKey");
        const headers = {
                Authorization: `Bearer ${token}`,
                Accept: "application/json",
            };
        return headers;}

    const CallModal = (datax, e) => {
        setBuffer(true)
        e.preventDefault();
        axios.post(API_URL + '/seccion', datax).then(
            function (response) {
                Toaster("success", response.data.message)
                handleClose()
                setBuffer(false)
            })
    }

    const EditModal = (datax, e) => {
        setBuffer(true)
        e.preventDefault();
        let headers = setHeaders()
        axios.post(API_URL + '/seccion/update', datax, {headers}).then(
            function (response) {
                Toaster("success", response.data.message)
                handleEditClose()
                setBuffer(false)
            })
    }

    const handleChange = e => {
        e.preventDefault();
        let temp = encuestas.find((encuesta) => encuesta.id == e.target.value)
        setSelection(temp);
        axios.get(API_URL + '/seccion', { params: { encuesta_id: e.target.value } }).then(
            function (response) {
                setSecciones(response.data)
                sumAll(response.data)
            }
        )
    }

    function updateEL() {
        axios.get(API_URL + '/seccion', { params: { encuesta_id: selectedEncuesta.id } }).then(
            function (response) {
                setSecciones(response.data)
                sumAll(response.data)
            }
        )
        reset({
            id: null,
            nombre_seccion: null,
            descripcion: null,
            peso: null,
            estado: "activo",
        })
    }
    function sumAll(secciones) {
        let sum;
        if (secciones != null) {
            let sum = 0
            secciones.forEach(element => {
                if (element.peso > 0)
                    sum += element.peso
            });
            setSum(sum);
        }
    }
    const Remove = (i,e) => {
        e.preventDefault()
        let headers = setHeaders()
        axios.delete(API_URL + `/seccion/${i.id}`, {headers}).then(res => {
            Toaster("success", res.data.message)
            hideEliminar()
        })
    }
    return (<>
        <ToastContainer style={{ position: "fixed", top: "80vh", right: "0vw" }} className="p-3">
            <Toast
                bg={toast.variant}
                onClose={() => setToast({ show: false, variant: "", message: "" })}
                show={toast.show}
                delay={2000}
                autohide>
                <Toast.Header>
                    <strong className="me-auto">Medicion</strong>
                    <small>just now</small>
                </Toast.Header>
                <Toast.Body className="text-white">{toast.message}</Toast.Body>
            </Toast>
        </ToastContainer>
        <Modal show={viewElim} onHide={hideEliminar}>
        <Modal.Header closeButton>
          <Modal.Title>Eliminar Seccion</Modal.Title>
        </Modal.Header>
            <Form onSubmit={handleSubmit(Remove)}>
                <Modal.Body>
                    <h4>Esta seguro ?</h4>
                    <h5>esta acción no puede deshacerse</h5>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="App-button-alt" onClick={hideEliminar}>Cerrar</Button>
                    <Button type="submit" className="App-button">Eliminar</Button>
                </Modal.Footer>
            </Form>
        </Modal>
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Nueva sección</Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit(CallModal)}>
                <Modal.Body>
                    <Form.Control type="hidden" value={selectedEncuesta ? selectedEncuesta.id : null} {...register("encuesta_id")} />
                    <Row>
                        <Form.Group as={Col} className="mb-2">
                            <Form.Label>Encuesta :</Form.Label>
                            <Form.Control size="sm" plaintext readOnly defaultValue={selectedEncuesta ? selectedEncuesta.nombre_encuesta : "default"} />
                        </Form.Group>
                        <Form.Group as={Col} className="mb-2">
                            <Form.Label>Nombre de la seccion</Form.Label>
                            <Form.Control size="sm" placeholder="Nombre" {...register("nombre_seccion")} />
                        </Form.Group>
                    </Row>
                    <Form.Group className="mb-2">
                        <Form.Label>Descripción</Form.Label>
                        <Form.Control size="sm" as="textarea" rows={3} placeholder="..." {...register("descripcion")} />
                    </Form.Group>
                    <Row>
                        <Form.Group as={Col} className="mb-2">
                            <Form.Label>Peso (%)</Form.Label>
                            <Form.Control {...register("peso")} />
                        </Form.Group>
                        <Form.Group as={Col} className="mb-2">
                            <Form.Label>Estado</Form.Label>
                            <Form.Select {...register("estado")}>
                                <option value="activo">Activo</option>
                                <option value="inactivo">Inactivo</option>
                            </Form.Select>
                        </Form.Group>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="App-button-alt" onClick={handleClose}>
                        Cerrar
                    </Button>
                    {!loadBuffer ? <Button type="submit" className="App-button">
                    Crear
                    </Button> : <BarLoader className="m-2" color="#4538D1" width={50} />}
                </Modal.Footer>
            </Form>
        </Modal>
        <Modal show={showEdit} onHide={handleEditClose}>
            <Modal.Header closeButton>
                <Modal.Title>Editar sección</Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit(EditModal)}>
                <Modal.Body>
                    <Form.Control type="hidden" value={selectedEncuesta ? selectedEncuesta.id : null} {...register("encuesta_id")} />
                    <Row>
                        <Form.Group as={Col} className="mb-2">
                            <Form.Label>Encuesta: </Form.Label>
                            <Form.Control size="sm" plaintext readOnly defaultValue={selectedEncuesta ? selectedEncuesta.nombre_encuesta : "default"} />                         
                        </Form.Group>
                        <Form.Group as={Col} className="mb-2">
                            <Form.Label>Nombre de la seccion</Form.Label>
                            <Form.Control size="sm" placeholder="Nombre" {...register("nombre_seccion")} />                         
                        </Form.Group>
                    </Row>
                    <Form.Group className="mb-2">
                        <Form.Label>Descripción</Form.Label>
                        <Form.Control size="sm" as="textarea" rows={3} placeholder="..." {...register("descripcion")} />                         
                    </Form.Group>
                    <Row>
                        <Form.Group as={Col} className="mb-2">
                            <Form.Label>Peso (%)</Form.Label>
                            <Form.Control {...register("peso")} />
                        </Form.Group>
                        <Form.Group as={Col} className="mb-2">
                            <Form.Label>Estado</Form.Label>
                            <Form.Select {...register("estado")}>
                                <option value="activo">Activo</option>
                                <option value="inactivo">Inactivo</option>
                            </Form.Select>                         
                        </Form.Group>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="App-button-alt" onClick={handleEditClose}>
                        Cerrar
                    </Button>
                    {!loadBuffer ? <Button type="submit" className="App-button">
                    Actualizar
                    </Button> : <BarLoader className="m-2" color="#4538D1" width={50} />}
                </Modal.Footer>
            </Form>
        </Modal>
        <div className="content-container">
        <div className="content-header"><h4>Encuestas</h4></div>        
            <Form>
                <Form.Select className="m-2 mt-0" onChange={handleChange}>
                    <option>Seleccionar encuesta...</option>
                    {encuestas.map((encuesta) => <option value={encuesta.id}>{encuesta.nombre_encuesta}</option>)}
                </Form.Select>
            </Form>
            {selectedEncuesta ?
                <div className="content-body-alt">
                    <div className="content-header"><h4>Secciones</h4></div>
                    <Button className="m-3 App-button" onClick={handleShow}> Nueva sección </Button>
                    <Table className="App-Table" striped responsive="md" hover size="sm">
                        <thead>
                            <tr>                                
                                <th className="table-th">Nombre seccion</th>
                                <th className="table-th">Nombre Encuesta</th>
                                <th className="table-th">Peso (%)</th>
                                <th className="table-th">Estado</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {secciones.map((seccion) =>
                                <tr>                                    
                                    <td>{seccion.nombre_seccion}</td>
                                    <td>{selectedEncuesta.nombre_encuesta}</td>
                                    <td>{seccion.peso}</td>
                                    <td>{seccion.estado}</td>
                                    <td className="clickable-end">
                                        <td className="clickable"><BiEditAlt onClick={() => handleEditShow(seccion)} /></td>
                                        <td className="clickable"><BiTrash onClick={() => showEliminar(seccion)} /></td>
                                    </td>
                                </tr>)}
                        </tbody>
                    </Table>
                    <div className="sumatoria">
                        <p className="p-2">La sumatoria de los pesos se las secciones en la escuesta es : {sumPesos} %</p>
                    </div>
                </div> : null}
                <div className="flex-between">
                <Button className="App-button" onClick={() => {newLocation("../encuestas")}}>Atrás</Button>
                <Button className="App-button" onClick={() => {newLocation("../segmento-pregunta")}}>Siguiente</Button>
            </div>
        </div>
    </>)
}