import { useForm } from "react-hook-form"
import { useState, useContext, useEffect } from "react";
import {Form, Button, Toast, ToastContainer} from "react-bootstrap"
import { BiEditAlt } from "react-icons/bi";
import axios from "axios"
import ClientContext from "../../../Context/ClientContext";
import UserContext from "../../../Context/UserContext";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import parse from 'html-react-parser';


export default function ObjetoEstudio() {
    const API_URL = 'https://api.medicionanalytic.com/api'
    const {logData} = useContext(ClientContext)
    const {userData} = useContext(UserContext)
    const [objetivo, setObj] = useState("")
    const [edit, setEdit] = useState(false)
    const [id, setID] = useState()
    const [Temp, setTemp] = useState()
    const [toast, setToast] = useState({ show: false, variant: "", message: "" });
    const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({ mode: "onBlur" });
 
    const updateOE = (datax,e) => {
        e.preventDefault()
        datax.id = id;
        datax.client_id = Temp;
        datax.objetivo = objetivo;
        axios.post(API_URL + '/objetivo', datax).then(
            function (response) {Toaster("success", response.data.message); setEdit(false)})
}
function Toaster(variant, message) {setToast({ show: true, variant: variant, message: message });}

useEffect(() => {
    if(userData.permiso === 'admin')
    {   setTemp(logData.id)
        axios.get(API_URL + '/objetivo', {params: {client_id:logData.id}}).then(res => {
            if(res.data.length > 0) {
                setObj(res.data[0].objetivo)
                setID(res.data[0].id)        
            }
        })
    }
    else {
        setTemp(userData.cliente_id)
        axios.get(API_URL + '/objetivo', {params: {client_id:userData.cliente_id}}).then(res => {
            if(res.data.length > 0) {
                setObj(res.data[0].objetivo)
                setID(res.data[0].id)        
            }
        })
    }
}, [toast])

return(<>
<ToastContainer style={{position:"fixed",top:"80vh", right:"0vw"}} className="p-3">
        <Toast
          bg={toast.variant}
          onClose={() => setToast({ show: false, variant: "", message: "" })}
          show={toast.show}
          delay={2000}
          autohide>
          <Toast.Header>
            <strong className="me-auto">Medicion</strong>
            <small>just now</small>
          </Toast.Header>
          <Toast.Body className="text-white">{toast.message}</Toast.Body>
        </Toast>
    </ToastContainer>
<div className="content-container">
    <div className="content-body-alt p-2">
        <h5>Objeto de Estudio</h5>
        { !edit ? <div className="Banner mb-3">
            <BiEditAlt className="floating-edit" onClick={() => setEdit(true)}/>
            {parse(objetivo)}
        </div> :
        <Form onSubmit={handleSubmit(updateOE)}>
            <Form.Group className="Banner mb-2 pt-2">
                <ReactQuill className="sub-banner mt-2" theme="snow" value={objetivo} onChange={setObj} />
            </Form.Group>
            <Button type="submit" className="App-button">
                Guardar
            </Button>
        </Form>}
    </div>
</div>
    </>) 
}