import { useState, useEffect, useContext } from "react";
import UserContext from "../../../Context/UserContext";
import { BarLoader } from "react-spinners"
import useStateWithCallback from 'use-state-with-callback';
import { useNavigate } from "react-router-dom";
import { Form, Button, Row, InputGroup } from "react-bootstrap";
import { BiMenu, BiMessageRoundedError, BiMessageRoundedCheck } from "react-icons/bi"
import { set_cptable } from "xlsx";
import { useForm } from "react-hook-form";
import axios from "axios"

export default function EvaluationContainer() {
    const newLocation = useNavigate();
    const API_URL = 'https://api.medicionanalytic.com/api'
    const [loading, setLoading] = useState(false)
    const { userData } = useContext(UserContext);
    const [escala, setScala] = useState([1,2,3,4,5])
    const [usuarios, setUsers] = useState([])
    const [colaborador, setColab] = useState()
 
    const [secciones, setSecciones] = useState([])
    
    const [preguntas, setPreguntas] = useState([])
    const [respuestas, setRespuestas] = useStateWithCallback([], () => {
    })

    const { register,
        handleSubmit,
        reset,
        getValues,
        setValue,
        formState: { errors, isDirty, isValid },
      } = useForm({ mode: "onBlur" });

    const handleClient = (e) => {
        if(e.target.value != null) {
            setColab(usuarios.find(u => u.id === Number(e.target.value)))
        }
    }

    useEffect(()=> {
        axios.get(API_URL+'/users/empresa', {params:{empresa:userData.empresa}}).then(res=>{
                setUsers(res.data)
            })
        console.log(userData)
        axios.get(API_URL+'/encuesta/empresa', {params: {empresa:userData.empresa}}).then(res => {
            axios.post(API_URL + '/pregunta/active', { encuesta_id: res.data }).then((ress) => {
                let x = groupBy(ress.data.preguntas, "seccion_id")        
                setPreguntas(x)                
                setSecciones(ress.data.secciones)         
            })
        })
    },[])

    const groupBy = (array, key) => {
        return array.reduce((result, currentValue) => {
            (result[currentValue[key]] = result[currentValue[key]] || []).push(
                currentValue
            );
            return result;
        }, {});
    };

    const addValue = () => {    
        let line = getValues()
        let res = respuestas
        line.reporte_id = getValues("reporte_id")
        line.tipo_encuesta = "mystery"
        let position = res.findIndex(r => r.pregunta_id === line.pregunta_id)
        if(position === -1)
        {setRespuestas([...res, line]);}
        else {
            res[res.findIndex(r => r.pregunta === line.pregunta)] = line
            setRespuestas(res)
        }        
    }
    
 return <>
     <div className="over-container" style={{display:"flex"}}>            
        <div className="side-container hidden-sm">
            <p>Colaboradores a evaluar</p>
            <ul>                
                {usuarios
                .filter(el=>{ return (userData.subordinados.split(",").indexOf(el.usuario.toString()) > -1) || userData.id === el.id })
                .map(user => {
                    return <li>{userData.id === user.id ? <><BiMessageRoundedCheck style={{color:"green"}} />Autoevaluación</> : <><BiMessageRoundedError style={{color:"red"}} />{user.nombres}</>}</li>
                })}
            </ul>
        </div>
        <div className="main-container">            
        {colaborador ? <>
            <Button className="App-button" onClick={() => setColab()}>Atrás</Button>
            <Row className="mt-2">
                <h6 className="m-2">{userData.id === colaborador.id ? "Autoevaluación" : colaborador.nombres}</h6>        
            </Row>
            {secciones.filter(s => s.nombre_seccion.toLowerCase() === colaborador.gerencia.toLowerCase())
             .map(s => {
                return preguntas[s.id].map( p => {
                    return <Row className="mt-2">
                            <Form.Label className="m-2">
                                <h6>
                                    {p.titulo}
                                </h6>
                            </Form.Label>
                            <InputGroup onClick={(e) => {
                                for(let i = 0; i < e.target.parentNode.children.length; i++) {e.target.parentNode.children[i].classList.remove("btn-active")}
                                if(e.target.classList[0] === 'btn') {e.target.classList.add('btn-active');}}}>
                                {escala.map((number) => <Button onClick={() => {
                                    addValue();
                                }}>{number}</Button>)}
                            </InputGroup>
                         </Row>
                })
             })
            }
            <Row>
                <Button className="App-button m-3" onClick={() => setColab()}>Finalizar</Button>
            </Row>
        </>
        :<>
            <Form>
                <Form.Select onChange={handleClient}>
                    <option value={null}>Seleccione a quien Evaluar</option>
                    {usuarios
                    .filter(el=>{ return (userData.subordinados.split(",").indexOf(el.usuario.toString()) > -1) || userData.id === el.id })
                    .map(user => {
                        return <option value={user.id}>{userData.id === user.id ? "Autoevaluación" : user.nombres}</option>
                    })}
                </Form.Select>                
            </Form></>}
        </div>
        </div>
    </>
}