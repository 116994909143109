import { Form, Button, Modal, Toast, ToastContainer,InputGroup, Row, Col, Card, } from "react-bootstrap";
import ClientContext from "../../../Context/ClientContext"
import ParametroContext from "../../../Context/ParametrosContext";
import { useContext, useState, useEffect } from "react"
import { useForm } from "react-hook-form";
import axios from "axios"

export default function ReportSheet() {
    const Image_URL = 'https://api.medicionanalytic.com/medicionapi/storage/app/public'
    const API_URL = 'https://api.medicionanalytic.com/api'
    const [loading, setLoad] = useState(false)

    const { logData } = useContext(ClientContext);
    const { paramData } = useContext(ParametroContext);
    
    const [periodos, setPeriodos] = useState([])
    const [puntos, setPuntos] = useState([])
    const [secciones, setSecciones] = useState([])
    const [encuestas, setEncuestas] = useState([])
    
    const [Secciones, setS] = useState([])
    const [enc, setE] = useState({})
    
    const [mainValue, setMain] = useState(0)
    const [toast, setToast] = useState({ show: false, variant: "", message: "" });

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({ mode: "onBlur" });

    const dataExamples = [
        {x: 'data_0', y: 0},
        {x: 'data_1', y: 5},
        {x: 'data_2', y: 15},
        {x: 'data_3', y: 8},
        {x: 'data', y: 17},
      ];
      const myData = [{angle: 12*.75},{angle:12*.25}] //multiplicar por el porcentaje obtenido y por la resta del porcentaje de 100
      //set graph... "graph" and data to use x:string, y:value /y:string, x:value as needed.
      //pasar a funcion para mostrar grafico "grafico a usar", "data" y ordenar segun se requiera.
    useEffect(()=>{
        axios.get(API_URL + '/periodo', { params: { client_id:logData.id } }).then(
            function(response) {
                response.data.forEach(p => {
                    p.label = p.nombre_periodo;
                    p.value = p.id;
                })
                setPeriodos(response.data)
            })
        axios.get(API_URL + '/sucursal', { params: { cliente_id:logData.id } }).then(
            (res) => {
                res.data.forEach(p => {
                    p.label = p.nombre_sucursal;
                    p.value = p.id;
                })
                setPuntos(res.data)})
        axios.get(API_URL + '/seccion/report', { params: { client_id:logData.id } }).then(
            function (response) {
                response.data.forEach(p => {
                    p.label = p.nombre_seccion;
                    p.value = p.id;
                })
                setSecciones(response.data)})
        axios.get(API_URL + '/encuesta/all', { params: { client_id:logData.id } }).then(
            function(response) {
            let n = response.data.filter(encuesta => encuesta.punto_encuesta !== null);
            setEncuestas(n)})
    },[])

    const handleEncuesta = (e) => {
        e.preventDefault()
        let sec = secciones
        let enc = encuestas.find(el => el.id === Number(e.target.value))
        let tem = sec.filter(s => {return s.encuesta_id === enc.id})
        if(enc) {
            setE(enc)
            setS(tem)
        }
    }

    return (<>
        <div className="inicio-container">
        <Row className="client-view">
            <Col><h4>Tablero Principal</h4></Col>
            <Col><img src={Image_URL+logData.logo} className="report-logo m-2" alt="logo from Client" /></Col>
            <Col>
                <div className="data-card">
                    <div className="data-item m-1">
                        <span>Encuestas</span>
                        <span>{encuestas.length}</span>   
                    </div>
                    <div className="data-item m-1">
                        <span>Puntos</span>
                        <span>{puntos.length}</span>   
                    </div>
                </div>
            </Col>
            <Col><Button onClick={() => {console.log("")}}>Guardar</Button></Col>
        </Row>
        <Row className="client-view full-width">
            <h4>Hoja de Reporte para Clientes</h4>
            <Row>
                <Col>
                    <Form.Select className="m-2 mt-0 mb-0" onChange={(e) => console.log(e.target.value)}>
                        <option value={"velocimetro"}>velocimetro</option>
                        <option value={"radius"}>grafico radial</option>
                        <option value={"tendencia"}>grafico de tendencia</option>
                        <option value={"vertical"}>grafico barras verticales</option>
                        <option value={"horizontal"}>grafico barras horizontales</option>
                    </Form.Select>
                </Col>
                <Col>
                    <Form.Select className="m-2 mt-0 mb-0" onChange={(e) => console.log(e.target.value)}>
                        <option value={"tendencia"}>grafico de tendencia</option>
                        <option value={"velocimetro"}>velocimetro</option>
                        <option value={"radius"}>grafico radial</option>
                        <option value={"vertical"}>grafico barras verticales</option>
                        <option value={"horizontal"}>grafico barras horizontales</option>
                    </Form.Select>
                </Col>
            </Row>       
        </Row>
        <Row className="client-view full-width">
                <h4>Ranking de sucursales</h4>
                <Form.Select className="m-2 mt-0 mb-0" onChange={(e) => {console.log(e.target.value);setLoad(!loading)}}>
                    <option value={"velocimetro"}>velocimetro</option>
                    <option value={"radius"}>grafico radial</option>
                    <option value={"tendencia"}>grafico de tendencia</option>
                    <option value={"vertical"}>grafico barras verticales</option>
                    <option value={"horizontal"}>grafico barras horizontales</option>
                </Form.Select>             
            </Row>
        <Row className="client-view full-width">
                <h4>Resumen de cargos</h4>
                <Form.Select className="m-2 mt-0 mb-0" onChange={(e) => console.log(e.target.value)}>
                    <option value={"velocimetro"}>velocimetro</option>
                    <option value={"radius"}>grafico radial</option>
                    <option value={"tendencia"}>grafico de tendencia</option>
                    <option value={"vertical"}>grafico barras verticales</option>
                    <option value={"horizontal"}>grafico barras horizontales</option>
                </Form.Select>
            
            </Row>    
        </div>
    </>)
}