import { useForm } from "react-hook-form"
import { useState, useContext, useEffect } from "react";
import {Form, Button, Toast, ToastContainer} from "react-bootstrap"
import { BiEditAlt } from "react-icons/bi";
import axios from "axios"
import { MultiSelect } from "react-multi-select-component";
import ClientContext from "../../../Context/ClientContext";
import UserContext from "../../../Context/UserContext";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import parse from 'html-react-parser';


export default function Bandeja() {
    const API_URL = 'https://api.medicionanalytic.com/api'
    const {logData} = useContext(ClientContext)
    const {userData} = useContext(UserContext)
    const [objetivo, setObj] = useState("")
    const [contacts, setContacts] = useState([])
    const [listedContacts, setLC] = useState([])
    const [toast, setToast] = useState({ show: false, variant: "", message: "" });
    const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

function Toaster(variant, message) {setToast({ show: true, variant: variant, message: message });}

useEffect(() => {
    
}, [toast])

const SendMail = (data,e) => {
    //tienes que enviar a los contactos seleccionados. 
}

return(<>
<ToastContainer style={{position:"fixed",top:"80vh", right:"0vw"}} className="p-3">
        <Toast
          bg={toast.variant}
          onClose={() => setToast({ show: false, variant: "", message: "" })}
          show={toast.show}
          delay={2000}
          autohide>
          <Toast.Header>
            <strong className="me-auto">Medicion</strong>
            <small>just now</small>
          </Toast.Header>
          <Toast.Body className="text-white">{toast.message}</Toast.Body>
        </Toast>
</ToastContainer>
    <div className="content-container">
        <div className="content-body-alt p-2">
            <h5>Enviar Correo</h5>            
            <Form onSubmit={handleSubmit(SendMail)}>
                <Form.Group className="Banner mb-2 pt-2">
                    <ReactQuill className="sub-banner mt-2" theme="snow" value={objetivo} onChange={setObj} />
                </Form.Group>
            <MultiSelect className="mb-3 full-width" overrideStrings={{ "allItemsAreSelected": "Todos los Contactos.",
                    "clearSearch": "Limpiar busqueda", "clearSelected": "Limpiar Seleccion",
                    "noOptions": "Sin opciones", "search": "Buscar",
                    "selectAll": "Todos", "selectAllFiltered": "Todos (Filtrado)",
                    "selectSomeItems": "Seleccionar Contactos", "create": "Create",}} 
                    options={contacts} value={listedContacts} onChange={setLC} labelledBy="Select"/>

            <Button type="submit" className="App-button">
                Enviar
            </Button>
            </Form>
        </div>
    </div>
</>) 
}