import React, { useState, useEffect, useContext, useReducer } from "react";
import axios from "axios";
import ClientContext from "./ClientContext";
import UserContext from "./UserContext";

const DataContext = React.createContext({});

export function DataContextProvider({ children }) {
  const API_URL = 'https://api.medicionanalytic.com/api'
  const {logData} = useContext(ClientContext);
  const {userData} = useContext(UserContext);
  const [_1, forceUpdate] = useReducer(x => x + 1, 0);
  const [Data, setData] = useState({
      ciudades: [],
      periodos:[],
      encuestas:[],
      secciones:[],
      puntos:[],
      preguntas:[],
      reportes:[],
  });
  
    const [periodos, setPeriodos] = useState([])
    const [encuestas, setEncuestas] = useState([])
    const [secciones, setSecciones] = useState([])
    const [puntos, setPuntos] = useState([])
    const [preguntas, setPreg] = useState([])
    const [reportes, setReportes] = useState([])
    const [ciudades, setCities] = useState([])

  useEffect(() => {    

  },[logData])

  useEffect(() => {
    setTimeout(()=> {
      setData({        
        periodos:periodos,                
        })
    },500)
  },[_1])

  return (
    <DataContext.Provider value={{ Data, setData }}>
      {children}
    </DataContext.Provider>
  );
}
export default DataContext;
