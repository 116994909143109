import { useState, useContext, useEffect } from "react";
import { Form, Button, Table, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { MultiSelect } from "react-multi-select-component";
import { BiSearchAlt } from 'react-icons/bi'
import ParametrosContext from "../../../Context/ParametrosContext";
import ClientContext from "../../../Context/ClientContext";
import DataContext from "../../../Context/DataContext";
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'
import axios from "axios";

export default function ReportePreguntasClimaAcum() {
    const API_URL = 'https://api.medicionanalytic.com/api'
    const {logData} = useContext(ClientContext)
    const {ReportData} = useContext(DataContext);
    const {paramData} = useContext(ParametrosContext);
    const [consulta, setConsulta] = useState(false)

    const [Secciones, setS] = useState([])
    const [enc, setE] = useState()

    const [sPeriods, setSP] = useState([])
    const [sPunto, setSPto] = useState({})
    const [sSecciones, setSS] = useState([])

    const [periodos, setPeriodos] = useState([])
    const [encuestas, setEncuestas] = useState([])
    const [secciones, setSecciones] = useState([])
    const [puntos, setPuntos] = useState([])

    const [respuestas, setR] = useState([])
    const [dataFiltered, setDF] = useState([])

    const [SSec, setSSec] = useState([])
    const [FP, setFP] = useState([])
    //const [PS, setPS] = useState([]) not needed
    
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
      } = useForm({ mode: "onBlur" });

       //format for excel Download
    const fileType = 'aplication/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToExcel = async () => {
        let Document = dataFiltered.sort((a,b) => a.Punto_id - b.Punto_id)
        Document.forEach((d) => {
            delete d.Punto_id;
            delete d.Periodo_id;
            delete d.Tipo_pregunta;
            d.Value = d.Value + '%'
        })
        const ws = XLSX.utils.json_to_sheet(Document) //pass jason data
        const wb ={ Sheets: {'data': ws}, SheetNames:['data'] }
        const excelBuffer = XLSX.write(wb,{ bookType:'xlsx', type: 'array' });
        const data = new Blob([excelBuffer],{ type:fileType });
        FileSaver.saveAs(data, 'Excel Export' + fileExtension)
    }
    
    useEffect(()=>{
        axios.get(API_URL+'/respuesta', {params: { client_id:logData.id}}).then(res=>{
            setR(res.data)
        })
        axios.get(API_URL + '/periodo', { params: {client_id:logData.id} }).then(
            function(response) {
                response.data.forEach(p => {
                    p.label = p.nombre_periodo;
                    p.value = p.id;
                })
                setPeriodos(response.data)
            })
        axios.get(API_URL + '/sucursal', { params: {cliente_id:logData.id} }).then(
            (res) => {
                res.data.forEach(p => {
                    p.label = p.nombre_sucursal;
                    p.value = p.id;
                })
                setPuntos(res.data)})
        axios.get(API_URL + '/seccion/report', { params: {client_id:logData.id} }).then(
            function (response) {
                response.data.forEach(p => {
                    p.label = p.nombre_seccion;
                    p.value = p.id;
                })
                setSecciones(response.data)})
        axios.get(API_URL + '/encuesta/all', { params: {client_id:logData.id} }).then(
            function(response) {
            let n = response.data.filter(encuesta => encuesta.punto_encuesta !== null);
            setEncuestas(n)})
    },[])

    function getOccurrence(array, value) {
        return respuestas.filter((v) => (v.reporte_id === value)).length;
    }

    function Tabulada(){
        let data; 
        let sum = []
        respuestas.forEach((r)=>{
            if(r.tipo_pregunta === 'escala-tri') {
              if(r.respuesta === 'malo') {
                data = {
                Respuesta_id: r.id,
                Value :0,
                Reporte_id: r.reporte_id, 
                Punto_id: r.punto_id,
                Periodo_id: r.periodo_id,
                Encuesta_id: r.encuesta_id, 
                Seccion_id: r.seccion_id,
                Pregunta: r.pregunta,
                Tipo_pregunta: r.tipo_pregunta,
                Video_id : r.video_id
                }
                sum.push(data)
              }
              if(r.respuesta === 'regular') {
                data = {
                    Respuesta_id: r.id,
                    Value :50,
                    Reporte_id: r.reporte_id, 
                    Punto_id: r.punto_id,
                    Periodo_id: r.periodo_id,
                    Encuesta_id: r.encuesta_id, 
                    Seccion_id: r.seccion_id,
                    Pregunta: r.pregunta,
                    Tipo_pregunta: r.tipo_pregunta,
                    Video_id : r.video_id
                }
                sum.push(data)
              }
              if(r.respuesta === 'excelente') {
                data = {
                    Respuesta_id: r.id,
                    Value :100,
                    Reporte_id: r.reporte_id, 
                    Punto_id: r.punto_id,
                    Periodo_id: r.periodo_id,
                    Encuesta_id: r.encuesta_id, 
                    Seccion_id: r.seccion_id,
                    Pregunta: r.pregunta,
                    Tipo_pregunta: r.tipo_pregunta,
                    Video_id : r.video_id
                }
                sum.push(data)
              }
            }
            if(r.tipo_pregunta === "escala") {
                data = {            
                    Respuesta_id: r.id,
                    Value :(r.respuesta*100)/r.valor_max, 
                    Reporte_id: r.reporte_id, 
                    Punto_id: r.punto_id,
                    Periodo_id: r.periodo_id,
                    Encuesta_id: r.encuesta_id, 
                    Seccion_id: r.seccion_id,
                    Pregunta: r.pregunta,
                    Tipo_pregunta: r.tipo_pregunta,
                    Video_id : r.video_id
              }
                sum.push(data)
            }
            if(r.tipo_pregunta === "check") {
                if(r.respuesta === "si") {
                    data = {
                        Respuesta_id: r.id,
                        Value :100,
                        Reporte_id: r.reporte_id, 
                        Punto_id: r.punto_id,
                        Periodo_id: r.periodo_id,
                        Encuesta_id: r.encuesta_id, 
                        Seccion_id: r.seccion_id,
                        Pregunta: r.pregunta,
                        Tipo_pregunta: r.tipo_pregunta,
                        Video_id : r.video_id
                    }
                    sum.push(data)
                }
                else {
                    data = {
                        Respuesta_id: r.id,
                        Value :0,
                        Reporte_id: r.reporte_id, 
                        Punto_id: r.punto_id,
                        Periodo_id: r.periodo_id,
                        Encuesta_id: r.encuesta_id, 
                        Seccion_id: r.seccion_id,
                        Pregunta: r.pregunta,
                        Tipo_pregunta: r.tipo_pregunta,
                        Video_id : r.video_id
                  }
                    sum.push(data)
                }
            }
        })
        return sum;
      }

    function showData(filtered) {
        let helper = []
        filtered.forEach(v => {
            const bat = getOccurrence(filtered, v.Reporte_id)
            if (bat > 1) {
            const indet = helper.findIndex(o => o.Pregunta.toLowerCase() === v.Pregunta.toLowerCase() && o.Seccion_id === v.Seccion_id)
            if(indet === -1) {
                let temp = filtered.filter((o) => (o.Pregunta.toLowerCase() === v.Pregunta.toLowerCase() && o.Seccion_id === v.Seccion_id))
                var result = {
                    Reporte_id: v.Reporte_id,
                    Encuesta_id: v.Encuesta_id,
                    Punto_id : v.Punto_id,
                    Nombre_seccion: secciones.find(p => p.id === v.Seccion_id).nombre_seccion,
                    Periodo_id: v.Periodo_id,
                    Seccion_id : v.Seccion_id,
                    Pregunta : v.Pregunta,
                    Video_id: v.Video_id,
                    Value : (temp.reduce(function (acc, obj) { return acc + obj.Value; }, 0)/temp.length).toFixed(2) }
                helper.push(result)
             }
           }
           else {var result = {
               Reporte_id: v.Reporte_id,
               Encuesta_id: v.Encuesta_id,
               Punto_id : v.Punto_id,
               Nombre_seccion: secciones.find(p => p.id === v.Seccion_id).nombre_seccion,
               Periodo_id: v.Periodo_id,
               Seccion_id : v.Seccion_id,
               Pregunta : v.Pregunta,
               Video_id: v.Video_id,
               Value : v.Value,} 
            helper.push(result)}
        })

        if(helper.length === 0 ) {
            console.log("no hay datos que mostrar")
            setConsulta(false)
        }
        else {
            setDF(helper)        
            setConsulta(true)
        }
    }

       const SetFilters = (data,e) => {
        let tab = Tabulada()
        e.preventDefault();
        let temp = []
        if(!enc) {setConsulta(false);return;}

        temp =  tab.filter(el => {        
                return sSecciones.some(s => {
                   return sPeriods.some(f => {
                    return el.Punto_id === sPunto.id && 
                    s.id === el.Seccion_id && 
                    f.id === el.Periodo_id && 
                    el.Encuesta_id === enc.id
                   })
                })
            })        
        setSSec(sSecciones);
        showData(temp)
    }

    function getClass(val) {
        let x = Number(val)
        if(x < paramData.values.amarillo) {return "Fondo-R"}
        if(x < paramData.values.verde) {return "Fondo-A"}
        if(x >= paramData.values.verde) {return "Fondo-V"}
    }

    const handleEncuesta = (e) => {
        e.preventDefault()
        setSS([])
        let sec = secciones
        let enc = encuestas.find(el => el.id === Number(e.target.value))
        let tem = sec.filter(s => {return s.encuesta_id === Number(e.target.value)})
        if(enc) {
            setE(enc)
            setS(tem)
        }
    }

    const handlePunto = (e) => {
        e.preventDefault()
        let temp = puntos.find(p => p.id === Number(e.target.value))
        setSPto(temp)
    }

    return (<>
        <div className="content-container">
        <Form onSubmit={handleSubmit(SetFilters)}>
                        <Row className="mb-1">
                            <Col>
                                <MultiSelect
                                overrideStrings={{
                                    "allItemsAreSelected": "Todos los Periodos Seleccionados.",
                                    "clearSearch": "Limpiar busqueda",
                                    "clearSelected": "Limpiar Seleccion",
                                    "noOptions": "Sin opciones",
                                    "search": "Buscar",
                                    "selectAll": "Todos",
                                    "selectAllFiltered": "Todos (Filtrado)",
                                    "selectSomeItems": "Seleccionar Periodo",
                                    "create": "Create",}
                                  }
                                 options={periodos}
                                 value={sPeriods}
                                 onChange={setSP}
                                 labelledBy="Select"
                                />
                            </Col>
                            <Col>
                                <Form.Select onClick={handleEncuesta}>
                                    <option value={null}>Sin Encuesta Seleccionada</option>
                                    {encuestas.length > 0 ? encuestas.map(enc => {return <option value={enc.id}>{enc.nombre_encuesta}</option>}):null}
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <MultiSelect
                                 overrideStrings={{
                                    "allItemsAreSelected": "Todas las Secciones Seleccionadas.",
                                    "clearSearch": "Limpiar busqueda",
                                    "clearSelected": "Limpiar Seleccion",
                                    "noOptions": "Sin opciones",
                                    "search": "Buscar",
                                    "selectAll": "Todos",
                                    "selectAllFiltered": "Todos (Filtrado)",
                                    "selectSomeItems": "Seleccionar Secciones",
                                    "create": "Create",}
                                  }
                                 options={Secciones}
                                 value={sSecciones}
                                 onChange={setSS}
                                 labelledBy="Select"
                                />
                            </Col>
                             <Col>
                                <Form.Select onClick={handlePunto}>
                                    <option value={null}>Sin Empresa Seleccionada</option>
                                    {puntos.map(pts => {return <option value={pts.id}>{pts.nombre_sucursal}</option>})}
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row className="m-1 mt-3">
                            <Button type="submit" className="App-button m-2 mt-0">Aplicar filtros <BiSearchAlt/></Button>            
                        </Row>
    </Form>
        {consulta ? 
        <div className="content-body-alt mb-4">
            <Row className="row-excel-button">
                            <h3 className="p-2" style={{width:'50vw'}}>Resultados de reportes Acumulado por Preguntas</h3>
                            <Button size="sm" className="App-button" onClick={exportToExcel}>Descargar Excel</Button>    
                        </Row>
            <Table className="App-Table" striped hover>
                        <thead>
                        <tr>
                            <th className="table-th" rowSpan={2}>Empresa</th>
                            <th className="table-th extended" rowSpan={2}>Preguntas</th>     
                            {sSecciones.map(p => {
                                    return <th className="table-th">{p.nombre_seccion}</th> //nombre_seccion
                                })}              
                        </tr>
                        </thead>
                        <tbody>
                        {ReportData.preguntas.filter(p => p.tipo_pregunta !== "pregunta")
                               .map((dt, index) => {return <tr>
                                    {index === 0 ? <td>{sPunto.nombre_sucursal}</td>: <td></td>}
                                    <td>{dt.titulo}</td>
                                    {sSecciones.map( s => {
                                        return dataFiltered.filter(res => s.id === res.Seccion_id && res.Pregunta.toLowerCase() === dt.titulo.toLowerCase())
                                            .map(res => {
                                                return <td className={getClass(res.Value)}>{res.Value + '%'}</td>
                                            }) 
                                        })}        
                                </tr>
                               })                                                                   
                            }
                        </tbody>
                    </Table>
            </div>: null}
        </div>
        </>)
}