import React, { useState } from "react";

const ClientContext = React.createContext({});

export function ClientContextProvider({ children }) {
  const [logData, setLog] = useState({
    nombre_cliente:"",
    servicio_desempeno: "inactivo",
    serviservicio_encuesta: "inactivo",
    id:0
  });
  
  return (
    <ClientContext.Provider value={{ logData, setLog }}>
      {children}
    </ClientContext.Provider>
  );
}
export default ClientContext;
